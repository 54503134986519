import React from 'react';
import { locale } from '../../common/localization/localizationService';
import './templateDownloadButton.scss';

const templateFile = require('!!file-loader?name=route-template.xlsx!../../static/route-template.xlsx');

function TemplateDownloadButton() {
  return (
    <a className="template-download-button" href={templateFile}>
      {locale.routeOptimalizationWizard._downloadTemplateButton}
    </a>
  );
}

export default TemplateDownloadButton;
