import { LatLngBounds, LatLngExpression, LatLngTuple } from 'leaflet';
import React, { Component, ReactNode } from 'react';
import { Location } from 'history';
import { Pane, Polyline, Popup } from 'react-leaflet';
import {
  ITrackingLine,
  ITrackingLineSegment,
  ISelectedOrder,
} from '../../models';
import { AsyncAction } from '../../utils';
import { ClusteredMapLayer } from '../shared/customMapLayer';
import moment from 'moment';
import { isEqual } from 'lodash';
import { mapServiceSettings, mapSettings } from '../../appConfig';
import { MapUtility } from '../map/MapUtility';
import { locale } from '../../common/localization/localizationService';

export interface ITrackingLineLayerProps {
  clusteringActive: boolean;
  zoomLevel: number;
  bounds: LatLngBounds;
  location: Location;
  trackingLines: Array<ITrackingLine>;
  order: ISelectedOrder;
  loadTrackingLines: () => AsyncAction;
}

export interface ITrackingLineLayerState {
  linesToDraw: Array<ITrackingLine>;
  lineNodes: ReactNode;
}
export class TrackingLineLayer extends Component<
  ITrackingLineLayerProps,
  ITrackingLineLayerState
> {
  public readonly state: ITrackingLineLayerState = {
    linesToDraw: [],
    lineNodes: <></>,
  };

  public componentDidMount(): void {
    this.props.loadTrackingLines();
  }

  public componentDidUpdate(
    prevProps: Readonly<ITrackingLineLayerProps>,
    prevState: Readonly<ITrackingLineLayerState>
  ): void {
    if (isEqual(prevProps.bounds, this.props.bounds)) return;

    const clusteredLines: Array<ITrackingLine> =
      this.props.trackingLines.filter((line: ITrackingLine) => {
        const x =
          line.boundaryMinX <= this.props.bounds.getEast() &&
          line.boundaryMaxX >= this.props.bounds.getWest();
        const y =
          line.boundaryMinY <= this.props.bounds.getNorth() &&
          line.boundaryMaxY >= this.props.bounds.getSouth();
        return x && y;
      });

    const trackingLines = this.changeTrackingLinesNumberByZooming(
      clusteredLines,
      this.props.zoomLevel
    );

    if (!trackingLines.length) return;

    const orderCheckedLines = !this.props.order?.orderId
      ? trackingLines
      : trackingLines
          .map((line: ITrackingLine) => {
            const segments = line.lineSegments.filter(
              (segment: ITrackingLineSegment) => {
                const isSegmentInOrder =
                  segment.orderId === this.props.order?.orderId;
                return isSegmentInOrder;
              }
            );
            return { ...line, lineSegments: segments };
          })
          .filter((line) => line.lineSegments?.length);

    if (!orderCheckedLines.length) return;

    const hasVisibleSegments = orderCheckedLines.some(
      (line: ITrackingLine) => line.lineSegments.length
    );

    if (!hasVisibleSegments) return;

    if (!isEqual(orderCheckedLines, prevState.linesToDraw)) {
      this.setState({
        ...this.state,
        linesToDraw: orderCheckedLines,
        lineNodes: this.renderLines(orderCheckedLines),
      });
    }
  }

  private changeTrackingLinesNumberByZooming = (
    trackingLines: Array<ITrackingLine>,
    zoomLevel: number
  ): Array<ITrackingLine> => {
    if (!trackingLines || !zoomLevel) return [];

    if (zoomLevel < 8) {
      return [];
    } else if (zoomLevel > 13) {
      return trackingLines;
    } else {
      //Make lines sparser
      const resolution = (13 - zoomLevel) * 2;
      const projectedTrackingLines = trackingLines.map(
        (l) =>
          ({
            boundaryMaxX: l.boundaryMaxX,
            boundaryMaxY: l.boundaryMaxY,
            boundaryMinY: l.boundaryMinY,
            boundaryMinX: l.boundaryMinX,
            lineSegments: this.mapTrackingLineByResolution(
              resolution,
              l.lineSegments
            ),
          } as ITrackingLine)
      );

      return projectedTrackingLines;
    }
  };

  private renderLines(lines: Array<ITrackingLine>): ReactNode {
    const multiPolylines = lines.map((line) => {
      const segments = line.lineSegments;
      if (!segments.length) {
        return [];
      }
      const multiPolyline = this.createMultiPolylineSegments(segments);
      return multiPolyline;
    });

    const nodes = multiPolylines.map((multiPolyline) =>
      this.drawPolylinesFromMultiPolylineSegments(multiPolyline)
    );

    return nodes;
  }

  private isSegmentInBounds(
    segment: ITrackingLineSegment,
    bounds: LatLngBounds
  ): boolean {
    if (!segment || !bounds) return false;

    const start = [segment.startY, segment.startX] as LatLngExpression;
    const end = [segment.endY, segment.endX] as LatLngExpression;

    return bounds.contains(start) || bounds.contains(end);
  }

  private extendBounds(bounds: LatLngBounds, factor: number): LatLngBounds {
    if (!bounds || !factor)
      return new LatLngBounds([Infinity, Infinity], [Infinity, Infinity]);
    const width = bounds.getEast() - bounds.getWest();
    const height = bounds.getNorth() - bounds.getSouth();
    const newBounds = new LatLngBounds(
      [bounds.getSouth() - height * factor, bounds.getWest() - width * factor],
      [bounds.getNorth() + height * factor, bounds.getEast() + width * factor]
    );
    return newBounds;
  }

  private drawPolylinesFromMultiPolylineSegments = (
    multiPolylineSegments: any
  ): Polyline => {
    return multiPolylineSegments.map((polyline: any) => {
      return (
        <Polyline
          weight={4}
          key={polyline.id}
          positions={polyline.positions}
          color={this.getLineColor(polyline.lastVisited)}
        >
          {(mapServiceSettings.trackingLineDebugPopupsEnabled &&
            !mapServiceSettings.trackingLineUpdatedPopupsEnabled) ||
            (mapServiceSettings.trackingLineDebugPopupsEnabled &&
              mapServiceSettings.trackingLineUpdatedPopupsEnabled && (
                <Popup>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>lastVisited</strong>
                        </td>
                        <td>{polyline.lastVisited}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>lineId</strong>
                        </td>
                        <td>{polyline.lineId}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>id</strong>
                        </td>
                        <td>{polyline.id}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>startX</strong>
                        </td>
                        <td>{polyline.startX}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>startY</strong>
                        </td>
                        <td>{polyline.startY}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>endX</strong>
                        </td>
                        <td>{polyline.endX}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>endY</strong>
                        </td>
                        <td>{polyline.endY}</td>
                      </tr>
                    </tbody>
                  </table>
                </Popup>
              ))}
          {mapServiceSettings.trackingLineUpdatedPopupsEnabled &&
            !mapServiceSettings.trackingLineDebugPopupsEnabled && (
              <Popup>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong>{locale.map._lastUpdated}: </strong>
                      </td>
                      <td>
                        {moment(polyline.lastVisited).format(
                          'DD.MM.YYYY HH:mm:ss'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{locale.orders._vehicleId}: </strong>
                      </td>
                      <td>{polyline.vehicleId}</td>
                    </tr>
                  </tbody>
                </table>
              </Popup>
            )}
        </Polyline>
      );
    });
  };

  private createMultiPolylineSegments = (
    segments: Array<ITrackingLineSegment>
  ): Array<ITrackingLineSegment> => {
    if (!segments) return [];
    return segments.map((segment: ITrackingLineSegment) => {
      const startPos: LatLngTuple = [segment.startY, segment.startX];
      const endPos: LatLngTuple = [segment.endY, segment.endX];
      const positions: Array<LatLngExpression> = [startPos, endPos];
      return { ...segment, positions };
    });
  };

  private mapTrackingLineByResolution(
    resolutionStep: number,
    segments: Array<ITrackingLineSegment>
  ): Array<ITrackingLineSegment> {
    if (resolutionStep < 2) resolutionStep = 2;
    if (resolutionStep > segments.length) return [...segments];

    let sparseSegments: Array<ITrackingLineSegment> = [];

    let i: number = resolutionStep - 1;
    for (; i < segments.length; i += resolutionStep) {
      const currentSegment = segments[i],
        prevSegment = segments[i - resolutionStep + 1];

      const combinedSegment: ITrackingLineSegment = this.getCombinedSegment(
        prevSegment,
        currentSegment
      );

      sparseSegments.push(combinedSegment);
    }

    //Push last line if it was not added
    if (segments.length % resolutionStep !== 0) {
      i -= resolutionStep;

      const combinedSegment = this.getCombinedSegment(
        segments[i],
        segments[segments.length - 1]
      );
      sparseSegments.push(combinedSegment);
    }

    const maxSegmentLength =
      mapServiceSettings.maxSegmentLengthMeters + (resolutionStep - 1) * 35;
    sparseSegments = sparseSegments.filter(
      (segment) =>
        MapUtility.getDistanceInMeters(
          segment.endY,
          segment.endX,
          segment.startY,
          segment.startX
        ) <= maxSegmentLength
    );

    return sparseSegments;
  }

  private getCombinedSegment(
    first: ITrackingLineSegment,
    second: ITrackingLineSegment
  ): ITrackingLineSegment {
    return {
      id: second.id,
      lineId: second.lineId,
      orderId: second.orderId,
      lastVisited: second.lastVisited,
      vehicleId: second.vehicleId,
      startX: first.startX,
      startY: first.startY,
      endX: second.startX,
      endY: second.startY,
    } as ITrackingLineSegment;
  }

  private modifyLineResolution(
    zoomLevel: number,
    segments: Array<ITrackingLineSegment>
  ): Array<ITrackingLineSegment> {
    /** @todo implementation of this thing is only possible,
     * if we would have a sequence number of the segments or
     * timestamp for all of them */
    if (zoomLevel > 9) return segments;

    const maxZoomLevel = 17;
    const firstSegment = segments.shift();
    const lastSegment = segments.pop();
    const resolution = maxZoomLevel - zoomLevel;
    const newSegments = [];

    for (let i = 0; i < segments.length; i += resolution) {
      newSegments.push(segments[i]);
    }

    newSegments.push(lastSegment);
    newSegments.unshift(firstSegment);

    for (let i = 0; i < newSegments.length - 1; i++) {
      const segment = newSegments[i];
      const nextSegment = newSegments[i + 1];
      segment.endX = nextSegment.startX;
      segment.endY = nextSegment.startY;
    }

    return newSegments;
  }

  private getLineColor(date: Date): string {
    const colors = mapSettings.objectColoring;
    if (!date) return colors.default;

    const now = moment();
    const duration = moment.duration(now.diff(date));
    const diffInHours = duration.asHours();

    if (diffInHours < 6) {
      return colors.firstInterval;
    } else if (diffInHours < 12) {
      return colors.secondInterval;
    } else if (diffInHours < 24) {
      return colors.thirdInterval;
    } else {
      return colors.fourthInterval;
    }
  }

  public render(): ReactNode {
    const { clusteringActive } = this.props;
    return (
      <ClusteredMapLayer isClusteringActive={clusteringActive}>
        <Pane name="tracking-line-layer" style={{ zIndex: 301 }}>
          {this.state.lineNodes}
        </Pane>
      </ClusteredMapLayer>
    );
  }
}

export default TrackingLineLayer;
