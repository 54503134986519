import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import UserSelector from './UserSelector';
import { locale } from '../../common/localization/localizationService';
import './impersonation-manager.scss';
import Spinner from '../shared/spinner/Spinner';

class ImpersonationManager extends PureComponent {
  constructor(props) {
    super(props);

    this.handleUserChange = this.handleUserChange.bind(this);
    this.startImpersonation = this.startImpersonation.bind(this);
    this.stopImpersonation = this.stopImpersonation.bind(this);

    this.state = {
      selectedUser: props.impersonatedUser,
      waitingInProgress: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.impersonatedUser !== this.props.impersonatedUser) {
      this.props.vehicleTrackingActions.getVehicles();
      if (!this.props.impersonatedUser) {
        this.props.userActions.getUsers();
        this.props.customerActions.getCustomers();
      }
    }
  }

  handleUserChange(option, event) {
    if (event.action === 'select-option' && option.value) {
      this.setState({ selectedUser: option.value });
    } else {
      this.setState({ selectedUser: null });
    }
  }

  componentDidMount() {
    if (!this.props.impersonatedUser) {
      this.props.userActions.getUsers();
      this.props.customerActions.getCustomers();
    }
  }

  startImpersonation() {
    const { authActions, settingsActions } = this.props;

    this.setState({ waitingInProgress: true });
    authActions.setImpersonatedUser(this.state.selectedUser);
    settingsActions.getDataButtons();
    settingsActions.getVisibleFields();
    settingsActions.getCompanySettings();

    setTimeout(() => this.setState({ waitingInProgress: false }), 1000);
  }

  stopImpersonation() {
    const { authActions, settingsActions } = this.props;

    authActions.resetImpersonatedUser();
    settingsActions.getDataButtons();
    settingsActions.getVisibleFields();
    settingsActions.getCompanySettings();
  }

  render() {
    const {
      users,
      impersonatedUser,
      isUsersLoading,
      customers,
      isVehiclesLoading,
    } = this.props;
    const { selectedUser, waitingInProgress } = this.state;
    const shouldDisplaySpinner = isVehiclesLoading || waitingInProgress;

    const impersonationEnabled = !!impersonatedUser;
    const hasSelectedUser = !!selectedUser;
    return (
      <div className="impersonation-manager">
        {shouldDisplaySpinner && <Spinner />}
        <div className="form-group row">
          <label className="col col-form-label" htmlFor="user-select">
            {locale.impersonationManager._selectUser}:
          </label>
          <div className="col">
            <UserSelector
              users={users}
              handleChange={this.handleUserChange}
              selectedUser={selectedUser}
              isLoading={isUsersLoading}
              customers={customers}
              isDisabled={impersonationEnabled}
            />
          </div>
        </div>
        <div className="button-row">
          <button
            className="btn btn-outline-dark mr-2"
            disabled={impersonationEnabled || !hasSelectedUser}
            onClick={this.startImpersonation}
          >
            {locale.impersonationManager._impersonate}
          </button>
          <button
            className="btn btn-outline-dark"
            disabled={!impersonationEnabled}
            onClick={this.stopImpersonation}
          >
            {locale.impersonationManager._stopImpersonation}
          </button>
        </div>
      </div>
    );
  }
}

ImpersonationManager.propTypes = {
  userActions: PropTypes.object.isRequired,
  authActions: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  impersonatedUser: PropTypes.string,
  isUsersLoading: PropTypes.bool.isRequired,
  customers: PropTypes.array.isRequired,
  customerActions: PropTypes.object.isRequired,
  vehicleTrackingActions: PropTypes.object.isRequired,
  isVehiclesLoading: PropTypes.bool.isRequired,
  settingsActions: PropTypes.object.isRequired,
};

ImpersonationManager.defaultProps = {
  impersonatedUser: '',
};

export default ImpersonationManager;
