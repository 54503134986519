import React, { useEffect, useState } from 'react';
import { IFraction, SelectOption } from '../../models/Fraction.model';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { locale } from '../../common/localization/localizationService';

export interface IFractionsMultiselectProps {
  onFractionOptionChange: (fractions: Array<SelectOption>) => void;
  fractions: Array<IFraction>;
  selectedFractions: Array<IFraction>;
  disabled?: boolean;
}

export default function FractionsMultiselect(
  props: IFractionsMultiselectProps
): JSX.Element {
  const [fractionOptions, setFractionOptions] = useState<Array<SelectOption>>(
    []
  );
  const [selectedOptions, setSelectedOptions] = useState<Array<SelectOption>>(
    []
  );
  const [filteredOptions, setFilteredOptions] = useState<Array<SelectOption>>(
    []
  );

  const handleFractionOptionsChange = (
    selectedOptions: Array<SelectOption>
  ): void => {
    setSelectedOptions(selectedOptions);
  };

  const handleInputChange = (inputValue: string): void => {
    const filtered = fractionOptions.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  useEffect(() => {
    const fractions = props.fractions;
    if (fractions?.length) {
      const { selectedFractions } = props;
      const selected: Array<SelectOption> = selectedFractions.length
        ? selectedFractions.map((fraction) => {
            return { value: fraction.fractionId, label: fraction.name };
          })
        : [];
      setSelectedOptions(selected);
      const options = getFractionDropdownOptions(fractions, selected);
      setFractionOptions(options);
      setFilteredOptions(options);
    }
  }, [props.fractions]);

  useEffect(() => {
    const selectedFractions = selectedOptions?.map((o) => o) || [];
    props.onFractionOptionChange(selectedFractions);
    setFractionOptions(
      getFractionDropdownOptions(props.fractions, selectedOptions)
    );
    setFilteredOptions(
      getFractionDropdownOptions(props.fractions, selectedOptions)
    );
  }, [selectedOptions]);

  const getFractionDropdownOptions = (
    fractions: Array<IFraction>,
    selectedOptions: Array<SelectOption>
  ): Array<SelectOption> => {
    if(!selectedOptions) selectedOptions = [];

    const selectedValues = selectedOptions.map((option) => option.value);
    const options = fractions
      .filter((fraction) => !selectedValues.includes(fraction.fractionId))
      .map((fraction: IFraction) => {
        return {
          value: fraction.fractionId,
          label: fraction.name,
        };
      });
    return options;
  };

  return (
    <Form.Group as={Row}>
      <Col sm={2}>
        <label>{locale.orders._fractions}</label>
      </Col>
      <Col sm={10}>
        <Select
          options={filteredOptions}
          isMulti
          value={selectedOptions}
          onChange={(options) => {
            handleFractionOptionsChange(options as Array<SelectOption>);
          }}
          onInputChange={handleInputChange}
          placeholder={locale.orders._selectFractions}
          noOptionsMessage={() => locale.orders._noFractions}
          loadingMessage={() => locale.orders._fractionsLoading}
          isDisabled={props.disabled}
        />
      </Col>
    </Form.Group>
  );
}
