export const localeDe = {
  general: {
    _administration: 'Verwaltung',
    _username: 'Benutzername',
    _password: 'Passwort',
    _date: 'Datum',
    _time: 'Zeit',
    _orderOverview: 'Bestellübersicht',
    _reports: 'Berichte',
    _kg: 'kg',
    _ton: 'Tonne',
    _callOrder: 'Abrufaufträge',
    _serviceList: 'Arbeitslisten',
    _impersonationManager: 'Kunde/Benutzer festlegen',
    _userAdmin: 'Benutzerverwaltung',
    _routeAdmin: 'Routen',
    _backendError: 'Ein Fehler ist bei der Anfrage aufgetreten',
    _accessError: 'Sie haben keinen Zugriff auf diese Seite',
    _vehicles: 'Fahrzeuge',
    _vehicleSettings: 'Administrative Einstellungen',
    _zoomIn: 'Vergrößern',
    _zoomOut: 'Verkleinern',
    _warning: 'Warnung!',
    _customerRegister: 'Kundenregister durchsuchen',
    _commune: 'Gemeinde',
    _logOut: 'Abmelden',
    _selectLanguage: 'Sprache wählen',
    _unitDesrciption: 'Einheitstyp',
    _fullDateFormat: 'dd.MM.yyyy',
    _fullDateWithTime: 'dd.MM.yyyy HH:mm:ss',
    _fullMomentFormat: 'DD.MM.YYYY',
    _fullMomentWithTime: 'DD.MM.YYYY HH:mm:ss',
    _noRecords: 'Keine Aufzeichnungen verfügbar.',
  },
  login: {
    _usernameEmail: 'E-Mail',
    _usernameRequired: 'Benutzername ist erforderlich!',
    _passwordRequired: 'Passwort ist erforderlich!',
    _usernamePasswordInvalid: 'Falscher Benutzername oder Passwort',
    _invalidEmail: 'Der Benutzername ist keine gültige E-Mail-Adresse!',
    _submit: 'Anmelden',
    _welcome: 'Willkommen bei GPS Realtime WMS',
    _pressLogin: 'Drücken Sie Anmelden, um sich im Portal anzumelden',
    _ifAssistance: 'Bei Bedarf an Unterstützung,',
    _pleaseContact: 'kontaktieren Sie bitte unseren Support',
  },
  tooltip: {
    _deviceId: 'Gerätenummer',
    _altitude: 'Höhe',
    _speed: 'Geschwindigkeit',
    _temperature: 'Temperatur',
    _orderId: 'Bestellnummer',
    _address: 'Adresse',
    _routeName: 'Route',
  },
  orders: {
    _update: 'Aktualisiert',
    _start: 'Start',
    _route: 'Route',
    _vehicleId: 'Fahrzeug',
    _orderId: 'Bestellung',
    _status: 'Status',
    _notStarted: 'Nicht gestartet',
    _now: 'Jetzt',
    _clearFilter: 'Filter entfernen',
    _fraction: 'Fraktion',
    _showFilters: 'Filter anzeigen',
    _chooseContractor: 'Wählen Sie den Spediteur',
    _chooseFraction: 'Wählen Sie die Fraktion',
    _expires: 'Läuft ab',
    _date: 'Datum',
    _ordinaryDate: 'Ordentliches Datum',
    _earlierDateValidation:
      'Das Ablaufdatum kann nicht vor dem Startdatum liegen',
    _fractions: 'Fraktionen',
    _selectFractions: 'Fraktionen auswählen',
    _noFractions: 'Keine Fraktionen',
    _updateOrder: 'Bestellung aktualisieren',
    _updateSuccess: 'Bestellung wurde aktualisiert',
    _fractionsLoading: 'Fraktionen werden geladen',
    _notEditableWarning:
      'Sobald die Route gestartet ist, kann nur das Ablaufdatum geändert werden.',
    status: {
      _noDone: 'Nicht abgeschlossen',
      _okDone: 'Ok, abgeschlossen',
      _downloaded: 'Heruntergeladen',
      _started: 'Gestartet',
      _backOrder: 'Rückstand',
      _localBackOrder: 'Lokaler Rückstand',
      _partiallyStarted: 'Teilweise gestartet',
      _partiallyCompleted: 'Teilweise abgeschlossen',
      _rejected: 'Abgelehnt',
      _planned: 'Geplant',
      _deleted: 'Gelöscht',
    },
  },
  orderDetails: {
    _address: 'Adresse',
    _unit: 'Einheit',
    _executed: 'Ausgeführt',
    _vehicleId: 'Fahrzeug',
    _driver: 'Fahrer',
    _time: 'Zeit',
    _deviation: 'Abweichung',
    _orderDoesNotExist: 'Bestellung existiert nicht',
    _companyName: 'Kunde',
    _pictureTakenText: 'Bilder',
    _sequence: 'Reihenfolge',
  },
  orderStatuses: {
    _started: 'Gestartet',
    _done: 'OK, gefahren',
    _partiallyDone: 'Teilweise abgeschlossen',
  },
  orderDetailsDiagram: {
    _executionStatusHeader: 'Ausgeführt (Ja / Nein)',
    _deviationsHeader: 'Abweichungen / Anmerkungen',
    _noDeviations: 'Keine Abweichungen / Anmerkungen',
  },
  routeStopDetails: {
    _routeStopDoesNotExist: 'Der Haltepunkt existiert nicht',
    _departureTime: 'Abfahrtszeit',
    _arrivalTime: 'Ankunftszeit',
    _address: 'Adresse',
    _agreementId: 'VertragNr',
    _unit: 'Einheit',
    _agreementLineId: 'AnfrageNr',
    _executed: 'Durchgeführt',
    _externalOrderId: 'AuftragsNr',
    _vehicleId: 'Fahrzeug',
    _driver: 'Fahrer',
    _orderData: 'Auftragsdaten',
    _pictures: 'Bilder',
    _deviation: 'Abweichung / Bemerkung',
    _comment: 'Bemerkung',
  },
  routeAdminPage: {
    _selectRoute: 'Route(n) wählen',
    _refresh: 'Aktualisieren',
    _withoutPosition: 'Ohne Position',
    _sequence: 'Reihenfolge',
    _description: 'Beschreibung',
    _routeLineId: 'Haltepunkt',
    _agreementId: 'Vertrags-ID',
    _name: 'Name',
    _estate: 'Eigentum',
    _unitName: 'Produkt',
    _agreementLineId: 'Vertragszeilen-ID',
    _physicalAmount: 'Menge',
    _route: 'Route',
    _degLat: 'Breitengrad',
    _degLong: 'Längengrad',
    _invalidSequence: 'Ungültige Reihenfolgenummer',
    _changeSequence: 'Reihenfolge ändern',
    _cancelButton: 'Abbrechen',
    _submitButton: 'Speichern',
    _validateButton: 'Validieren',
    _restoreButton: 'Wiederherstellen',
    _locationChangeTitle: 'Sind Sie sicher, dass Sie speichern möchten?',
    _locationChangeContent: 'Ihre Änderungen werden jetzt gespeichert',
    _splitStoppoint: 'Haltepunkt aufteilen',
    _splitModalPlaceholder:
      'Ziehen Sie einen Kunden hierher, um einen neuen Haltepunkt zu erstellen',
    _splitModalErrorMessage: 'Sie müssen mindestens 2 Haltepunkte erstellen',
    _mergeModalTitle: 'Haltepunkte zusammenführen',
    _mergeModalContent: 'Ausgewählte Haltepunkte',
    _selectOnlyOne: 'Bitte wählen Sie nur 1 Haltepunkt',
    _selectAtLeastOne: 'Mindestens 1 Haltepunkt muss ausgewählt werden',
    _selectAtLeastTwo: 'Mindestens 2 Haltepunkte müssen ausgewählt werden',
    _mergeStoppoints: 'Haltepunkte zusammenführen',
    _sequenceEdit: 'Reihenfolge bearbeiten',
    _savePosition: 'Position speichern',
    _changePosition: 'Position ändern',
    _changePositionTooltip:
      'Ändern Sie die Position des ausgewählten Haltepunkts',
    _agreementGid: 'GnrBnrFnrSnr',
    _placeId: 'Haltestellen-ID',
    _address: 'Adresse',
    _customerName: 'Kundenname',
    _propertyLinesWarning:
      'Einige Punkte können außerhalb der Karte angezeigt werden.',
    _propertyLinesCustom: 'Nur Punkte mit Koordinaten anzeigen.',
    _propertyLinesAll: 'Alle Punkte anzeigen.',
    _changesHeader: 'Die folgenden Reihenfolgenummern sind betroffen',
    _changedFrom: 'geändert von',
    _changedTo: 'zu',
    _notInNorwayErrorMessage:
      'Die von Ihnen eingestellte Position liegt außerhalb Norwegens',
    _alreadyMerged: 'Markierte Verträge wurden bereits zusammengeführt',
    _mergeModalTargetText: 'Wählen Sie die Anzeige/Adresse des Haltepunkts',
    _mergeModalTargetDropdownText: 'Wählen Sie die Anzeige/Adresse',
    _setPosition: 'Position festlegen',
    _setPositionTooltip: 'Position für den ausgewählten Haltepunkt festlegen',
    _noRecords: 'Keine Aufzeichnungen verfügbar.',
    _isLoadingMessage: 'Datensätze werden geladen.',
    _sequenceSuccessfullyChanged: 'Reihenfolge erfolgreich aktualisiert',
    _remove: 'Entfernen',
    _selection: 'Auswahl',
    _hideWithoutAgreement: 'Verstecken ohne Zustimmung',
  },
  reports: {
    _exportTitle: 'Nach Excel-Datei exportieren',
    _nothingToExportTitle: 'Keine Daten zum Exportieren',
    _showReport: 'Bericht anzeigen',
    _fromDate: 'Ab Datum',
    _toDate: 'Bis Datum',
    _selectReport: 'Bericht auswählen',
    _reportEmpty: 'Keine verfügbaren Datensätze',
    _header: 'Meine Berichte',
    _summary:
      'Hier haben wir alle Ihre Berichte gesammelt. Berichte können bei unseren Beratern bestellt werden. Kontaktieren Sie uns per E-Mail {mailto}.',
    _export: 'Exportieren',
  },
  serviceLists: {
    _noOrders: 'Keine geplanten Bestellungen.',
    _delete: 'Löschen',
    _add: 'Hinzufügen',
    _deleteConfirm:
      'Sind Sie sicher, dass Sie diese Arbeitsliste löschen möchten: ',
  },
  routeOptimalizationWizard: {
    _title: 'Fahrtenlisten erstellen',
    _uploadFileStep: 'Datei hochladen',
    _uploadFileButton: 'Datei auswählen',
    _analyseStep: 'Analysieren',
    _analyseButton: 'Analyse starten',
    _saveStep: 'Fahrtenlisten',
    _saveButton: 'Fahrtenlisten erstellen',
    _downloadTemplateButton: 'Importvorlage herunterladen',
    _importedRouteTabTitle: 'Auftragsgrundlage',
    _worklistsTabTitle: 'Fahrtenlisten',
    _unassignedStopsTabTitle: 'Aufträge nicht enthalten',
    _noRoutesImported: 'Keine Aufträge, Datei hochladen',
    _worklistEmpty: 'Die Fahrtenliste ist leer',
    _worklistOptionText: 'Fahrtroute',
    _addCustomer: 'Kunde hinzufügen',
    _duplicateRoutesWarning: 'Doppelte Aufträge in der Auftragsgrundlage',
    _noFileSelectedTitle: 'Keine Datei ausgewählt!',
    _noFileSelectedDescription:
      'Laden Sie die Auftragsgrundlage mit der folgenden Importvorlage hoch:',
    importManager: {
      _unknownParseError: 'Unbekannter Fehler',
      _fileLoadResultsModalTitle: 'Datei-Ladeergebnisse',
      _fileLoadSuccess: 'Datei erfolgreich hochgeladen!',
      _fileLoadModalCloseButton: 'Schließen',
      _agreementNotFound: 'Kunde für Kundennummer nicht gefunden',
      _wrongFileFormat:
        'Falsches Dateiformat. Laden Sie die Importvorlage herunter',
      _fileEmpty: 'Die Datei enthält keine Daten',
    },
    addCustomerModal: {
      _title: 'Kunde hinzufügen',
      _closeButton: 'Schließen',
      _addButton: 'Hinzufügen',
      _dateLabel: 'Datum',
      _agreementLabel: 'Kunde',
      _descriptionLabel: 'Beschreibung',
      _serialLabel: 'Seriennummer',
      _noOptionsMessage: 'Keine Kunden gefunden',
      _loadingMessage: 'Kunden werden geladen',
      _agreementSelector: 'Kunde auswählen',
      _agreementSelectorPlaceholder: 'Kunde auswählen...',
    },
    routeAnalysisModal: {
      _title: 'Auftragsgrundlage analysieren',
      _closeButton: 'Schließen',
      _cancelButton: 'Abbrechen',
      _analyseButton: 'Analysieren',
      _selectStartAndStop: 'Start- und Endpunkt auswählen',
      _workTime: 'Effektive Arbeitszeit pro Tag',
      _timePerStop: 'Zeit pro Stopp (Minuten)',
      _maximumCars: 'Maximale Anzahl an Autos in der Analyse',
      _startStopLabel: 'Start- und Endpunkt',
      _workHoursLabel: 'Stunden',
      _workMinutesLabel: 'Minuten',
      _timePerStopLabel: 'Zeit pro Stopp',
      _maximumCarsLabel: 'Maximale Anzahl an Autos',
      _noDepotOptionsMessage: 'Keine Depots gefunden',
      _depotsLoadingMessage: 'Depots werden geladen',
      _depotSelectorPlaceholder: 'Depot auswählen...',
      _analysationError: 'Fehler bei der Analyse der Auftragsgrundlage',
    },
    routesGrid: {
      _date: 'Datum',
      _gln: 'GLN',
      _customerName: 'Kundenname',
      _description: 'Beschreibung',
      _serial: 'Seriennummer',
    },
    worklistResults: {
      _sequence: 'Reihenfolge',
      _arrivalTime: 'Ankunftszeit',
      _gln: 'GLN',
      _customerName: 'Kundenname',
      _description: 'Beschreibung',
      _serial: 'Seriennummer',
      _unassignedWarning: 'Aufträge nicht enthalten!',
      worklistSummary: {
        _totalTime: 'Gesamtzeit',
      },
      moveWorklistItemModal: {
        _title: 'Verschieben nach',
        _targetListLabel: 'Fahrtenliste',
        _closeButton: 'Schließen',
        _moveButton: 'Verschieben nach',
      },
    },
    createWorklistsModal: {
      _title: 'Fahrtenlisten erstellen',
      _closeButton: 'Schließen',
      _saveButton: 'Erstellen',
      _itemCount: 'Anzahl',
      _listName: 'Listenname',
      _saveError: 'Fehler beim Erstellen der Fahrtenlisten',
    },
  },
  dateRangePicker: {
    _thisWeek: 'Diese Woche',
    _lastWeek: 'Letzte Woche',
    _thisMonth: 'Diesen Monat',
  },
  serviceListsGrid: {
    _listName: 'Listenname',
    _vehicleId: 'Fahrzeug',
    _updated: 'Aktualisiert',
    _started: 'Gestartet',
    _listDate: 'Datum',
  },
  serviceOrders: {
    _noOrders: 'Keine geplanten Bestellungen.',
    _excelExportButton: 'Excel',
    _pdfExportButton: 'PDF',
    _deleteConfirm:
      'Sind Sie sicher, dass Sie diese Bestellung löschen möchten: ',
  },
  serviceOrdersGrid: {
    _updated: 'Zeit',
    _address: 'Adresse',
    _executed: 'Ausgeführt',
    _unit: 'Einheit',
    _orderItemType: 'Typ',
    _deviation: 'Abweichung',
    _externalOrderId: 'GLN',
    _sequence: 'Sequenz',
    _agreementCompanyName: 'Kunde',
    _estimatedArrivalTime: 'Geplant',
  },
  serviceOrderDetails: {
    _serviceOrders: 'Arbeitsaufträge',
  },
  serviceOrderDetailsGrid: {
    _agreementCompanyName: 'Kunde',
    _address: 'Adresse',
    _externalOrderId: 'GLN',
    _sequence: 'Sequenz',
    _estimatedArrivalTime: 'Geplant',
    _description: 'Beschreibung',
    _listDescription: 'Listenbeschreibung',
    _executed: 'Ausgeführt',
    _vehicleId: 'Fahrzeug',
    _updated: 'Ausführungszeit',
    _unitName: 'Einheit',
    _orderItemType: 'Typ',
    _time: 'Zeit',
  },
  containerOrders: {
    _noOrders: 'Keine Bestellungen geplant.',
    _addVehicle: 'Fahrzeug hinzufügen',
    _cancel: 'Abbrechen',
    _save: 'Fahrzeug zuweisen',
    _addVehicleSuccess: 'Fahrzeug hinzugefügt',
    _addVehicleFailure: 'Fahrzeug wurde nicht gesetzt',
  },
  containerOrdersGrid: {
    _orderId: 'Auftrags-ID',
    _expires: 'Läuft ab',
    _date: 'Datum',
    _routeName: 'Route',
    _address: 'Adresse',
    _unitName: 'Einheit',
    _orderTypeName: 'Typ',
    _fraction: 'Fraktion',
    _vehicleId: 'Fahrzeug',
  },
  customerSearchGrid: {
    _id: 'Id',
    _system: 'System',
    _municipality: 'Gemeinde',
    _name: 'Name',
    _address: 'Adresse',
    _zipCode: 'Postleitzahl',
    _type: 'Profiltyp',
    _unitDesrciption: 'Einheitstyp',
    _sendMessage: 'Direktnachricht senden',
  },
  containerOrderDetails: {
    _unitName: 'Einheit',
    DetailData_Name: 'Name',
    DetailData_Address1: 'Adresse 1',
    DetailData_Address2: 'Adresse 2',
    DetailData_PostalCode: 'Postleitzahl',
    DetailData_Other: 'Sonstiges',
    DetailData_Message: 'Kommentar',
    DetailData_OrderId: 'Bestell-ID',
    DetailData_Expires: 'Läuft ab',
    DetailData_LastDate: 'Letztes Datum',
    DetailData_LastVolume: 'Volumen',
    DetailData_MessageSeptic: 'Bemerkung',
    DetailData_Material: 'Materialtyp',
    DetailData_NumberOfChambers: 'Anzahl der Kammern',
    DetailData_TubeLength: 'Schlauchlänge',
    DetailData_ExtOrderId: 'Bestell-ID',
    DetailData_ExtOrderNr: 'Externe BestellNr',
    DetailData_Route: 'Route',
    DetailData_RouteName: 'Routenname',
    DetailData_OrderDate: 'Bestelldatum',
    DetailData_OrderType: 'Bestelltyp',
    DetailData_OrderTypeText: 'Bestelltyp',
    DetailData_PASystem: 'PASystem',
    DetailData_AgreementLines: 'Vereinbarungszeilen',
    DetailData_RoutelineId: 'Routenlinie ID',
    AgreementLineDetailData_PASystem: 'PASystem',
    AgreementLineDetailData_AgreementId: 'Vereinbarungs-ID',
    AgreementLineDetailData_AgreementLineId: 'Vereinbarungszeilen-ID',
    AgreementLineDetailData_UnitId: 'Einheit-ID',
    AgreementLineDetailData_Amount: 'Menge',
    AgreementLineDetailData_PhysicalAmount: 'Physische Menge',
    AgreementLineDetailData_Message: 'Standort',
    AgreementLineDetailData_ExtOrderId: 'Bestell-ID',
    AgreementLineDetailData_LastChanged: 'Zuletzt geändert',
    AgreementLineDetailData_DegLat: 'Breitengrad',
    AgreementLineDetailData_DegLon: 'Längengrad',
    AgreementLineDetailData_ContainerId: 'Behälter-ID',
    AgreementLineDetailData_Fraction: 'Fraktion',
    AgreementDetailData_PASystem: 'PASystem',
    AgreementDetailData_AgreementId: 'Vereinbarungs-ID',
    AgreementDetailData_Route: 'Route',
    AgreementDetailData_CustomerId: 'Kunden-ID',
    AgreementDetailData_Name: 'Name',
    AgreementDetailData_ContactName: 'Kontaktname',
    AgreementDetailData_Address1: 'Adresse 1',
    AgreementDetailData_Address2: 'Adresse 2',
    AgreementDetailData_PostalCode: 'Postleitzahl',
    AgreementDetailData_City: 'Stadt',
    AgreementDetailData_Other: 'Sonstiges',
    AgreementDetailData_Message: 'Nachricht',
    AgreementDetailData_Type: 'Typ',
    AgreementDetailData_LastChanged: 'Zuletzt geändert',
  },
  impersonationManager: {
    _selectUser: 'Wählen Sie Kunde/Benutzer',
    _noOptionsMessage: 'Keine Benutzer gefunden',
    _impersonate: 'Verwenden',
    _stopImpersonation: 'Verwendung stoppen',
    _selectUserPlaceholder: 'Wählen Sie Kunde/Benutzer...',
  },
  userAdmin: {
    _noUsers: 'Keine Benutzer verfügbar',
    _userName: 'E-Mail',
    _system: 'System',
    _contractor: 'Auftragnehmer',
    _contractorId: 'Transporteur',
    _localAdmin: 'Lokaler Admin',
    _admin: 'Admin',
    _modules: 'Module',
    _add: 'Hinzufügen',
    _update: 'Aktualisieren',
    _cancel: 'Abbrechen',
    _newUser: 'Neuen Benutzer hinzufügen',
    _userEditDialogTitle: 'Benutzer bearbeiten',
    _userAddDialogTitle: 'Neuen Benutzer hinzufügen',
    _password: 'Passwort',
    _passwordConfirmation: 'Passwort bestätigen',
    _confirmPasswordError: 'Die Passwörter müssen übereinstimmen',
    _noCustomerOptionsMessage: 'Keine Kunden gefunden',
    _noContractorOptionsMessage: 'Keine Auftragnehmer gefunden',
    _contractorsLoadingMessage: 'Auftragnehmer werden geladen',
    _noModuleOptionsMessage: 'Keine Module gefunden',
    _modulesLoadingMessage: 'Module werden geladen',
    _customerSelectorPlaceholder: 'Wählen Sie Kunde...',
    _impersonationWarningPart1: 'Sie sind angemeldet als ',
    _impersonationWarningPart2:
      ', und haben keine Berechtigung, diese Seite zu verwenden. Klicken Sie auf die Schaltfläche unten, um zur Administratorfunktion zurückzukehren.',
  },
  vehicles: {
    _noVehicles: 'Keine Fahrzeuge verfügbar',
    _vehicles: 'Fahrzeuge',
    _system: 'System',
    _DTAShort: 'DTA',
    _DTALong: 'Digital Truck Assistant',
    _ajourShort: 'Ajour',
    _ajourLong: 'GPS Ajour',
    _vehicleId: 'Fahrzeug',
    _externalId: 'Externe Id',
    _description: 'Beschreibung',
    _phoneNr: 'SIM Nr',
    _driver: 'Fahrer',
    _password: 'Passwort',
    _repeatPassword: 'Passwort wiederholen',
    _edit: 'Bearbeiten',
    _cancel: 'Abbrechen',
    _update: 'Aktualisieren',
    _submit: 'Speichern',
    _addNewVehicle: 'Neues Fahrzeug hinzufügen',
    _editMessageSuccessful: 'Fahrzeug aktualisiert',
    _addMessageSuccessful: 'Fahrzeug hinzugefügt',
    _editMessageFailed: 'Fehler beim Aktualisieren des Fahrzeugs',
    _addMessageFailed: 'Fehler beim Hinzufügen des Fahrzeugs',
    _vehicleAlreadyExistingError:
      'Ein Fahrzeug mit demselben Namen existiert bereits',
    _validationError: 'Nur Zahlen und Buchstaben sind erlaubt',
    _contractorSelectorPlaceholder: 'Wählen Sie Auftragnehmer...',
    _noContractorFound: 'Keine Auftragnehmer verfügbar',
    _vehicleLoadingMessage: 'Fahrzeuge werden geladen',
  },
  vehicleSettings: {
    _copyFromVehicleButton: 'Kopiere von...',
    _copyFromVehicleDialogTitle: 'Einstellungen von Benutzer kopieren',
    _copyButton: 'Kopieren',
    _copyFromVehicleError: 'Fehler beim Kopieren der Benutzereinstellungen',
    _copyFromVehicleSuccess:
      'Einstellungen wurden von ausgewähltem Benutzer kopiert',
    _noSettings: 'Keine Einstellungen verfügbar',
    _parameter: 'Parameter',
    _value: 'Wert',
    _addToMultiple: 'Weitere Fahrzeuge hinzufügen?',
    _description: 'Beschreibung',
    _vehicle: 'Fahrzeug',
    _edit: 'Bearbeiten',
    _cancel: 'Abbrechen',
    _multiselectError: 'Wählen Sie mindestens ein Fahrzeug aus',
    _update: 'Aktualisieren',
    _submit: 'Speichern',
    _addNewSetting: 'Neue Einstellung hinzufügen',
    _editMessageSuccessful: 'Benutzereinstellung aktualisiert',
    _addMessageSuccessful: 'Benutzereinstellung hinzugefügt',
    _deleteMessageSuccessful: 'Benutzereinstellung gelöscht',
    _editMessageFailed: 'Fehler beim Ändern der Benutzereinstellung',
    _addMessageFailed: 'Fehler beim Hinzufügen der Benutzereinstellung',
    _deleteMessageFailed: 'Fehler beim Löschen der Benutzereinstellung',
    _deleteConfirm:
      'Sind Sie sicher, dass Sie diese Benutzereinstellung löschen möchten',
    _vehicleSelectorPlaceholder: 'Fahrzeug auswählen...',
    _selectVehicle: 'Fahrzeug',
    _selectCustomer: 'Kunde',
    _selectContractor: 'Auftragnehmer',
    _settingTemplateSelectorPlaceholder: 'Einstellungsart wählen...',
    _noSettingTemplateOptionsMessage: 'Keine Einstellungen gefunden',
    _cannotAddNewSettingMessage:
      'Kann keine neuen Einstellungen hinzufügen. Alle Einstellungen sind bereits vorhanden.',
    _failedToLoadSettingTempletes:
      'Kann Einstellungen nicht ändern oder hinzufügen. Standard-Einstellungen fehlen.',
  },
  map: {
    _trackingLineLayer: 'Tracking-Linie',
    _trackInfo: 'GPS-Informationen',
    _trackInfoLine: 'GPS-Trackprotokoll',
    _trackInfoPointsAndLines: 'Trackprotokoll',
    _streetLayer: 'Basis-Karte',
    _satelliteLayer: 'Luftbild',
    _propertyLayer: 'Katasteransicht',
    _wmsLayer: 'WMS',
    _nWMSLayersSelected: '{0} Gebiet(e) ausgewählt',
    _lastUpdated: 'Aktualisiert',
    _trackingLineLegend: {
      _belowSix: '< 6 Stunden',
      _sixToTwelve: '6-12 Stunden',
      _twelveToTwentyFour: '12-24 Stunden',
      _overTwentyFour: '> 24 Stunden',
      _gray: 'Unbekannt',
    },
  },
  contractors: {
    _contractors: 'Auftragnehmer',
    _name: 'Name des Auftragnehmers',
    _externalId: 'Externe Auftragnehmer-ID',
    _addContractor: 'Neuen Auftragnehmer hinzufügen',
    _deleteConfirm:
      'Sind Sie sicher, dass Sie diesen Auftragnehmer löschen möchten: ',
    _deleteSuccess: 'Auftragnehmer gelöscht',
    _addSuccess: 'Auftragnehmer hinzugefügt',
    _editSuccess: 'Auftragnehmer aktualisiert',
  },
  deviations: {
    _deviations: 'Abweichungen',
    _deviation: 'Abweichung',
    _deviatonExtId: 'Abweichung externe ID',
    _category: 'Kategorie',
    _deleteConfirm:
      'Sind Sie sicher, dass Sie diese Abweichung löschen möchten: ',
    _addNew: 'Abweichung hinzufügen',
    _addSuccess: 'Abweichung hinzugefügt',
    _editSuccess: 'Abweichung aktualisiert',
    _deleteSuccess: 'Abweichung gelöscht',
    _selectCategory: 'Abweichungskategorie auswählen',
    _noCategory: 'Keine Abweichungskategorie gefunden',
    _deleteCategoryConfirm:
      'Sind Sie sicher, dass Sie diese Abweichungskategorie löschen möchten: ',
    _categoryDeleteSuccess: 'Abweichungskategorie gelöscht',
    _deviationCategory: 'Abweichungskategorien',
    _categoryName: 'Name der Abweichungskategorie',
    _addCategorySuccess: 'Abweichungskategorie hinzugefügt',
    _editCategorySuccess: 'Abweichungskategorie aktualisiert',
    _addNewCategory: 'Neue Abweichungskategorie hinzufügen',
  },
  customerRegister: {
    message: {
      _directMessageToCustomer: 'Direktnachricht an Kunden',
      _address: 'Adresse',
      _sender: 'Absender',
      _expires: 'Läuft ab',
      _vehicle: 'Fahrzeug',
      _selectVehicle: 'Fahrzeug wählen',
      _title: 'Titel',
      _message: 'Nachricht',
      _send: 'Senden',
      _messageSent: 'Nachricht gesendet',
      _noVehicle: 'Kein Fahrzeug',
      _send: 'Nachricht senden',
    },
    grid: {
      _infoText:
        'Hier können Sie den Kunden suchen und eine Direktnachricht über den Kunden an das Fahrzeug senden.',
    },
  },
  directMessages: {
    _messages: 'Nachrichten',
    _address: 'Adresse',
    _sender: 'Absender',
    _expires: 'Läuft ab',
    _vehicle: 'Fahrzeug',
    _title: 'Titel',
    _message: 'Nachricht',
    _date: 'Datum',
    _closeButton: 'Schließen',
    _sent: 'Gesendet',
    _received: 'Verarbeitet',
    _read: 'Empfangen',
    _status: 'Status',
    _newMessage: 'Neue Nachricht',
  },
};
