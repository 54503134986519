import React, { useState } from 'react';
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
  GridColumnMenuFilter,
} from '@progress/kendo-react-grid';
import { locale } from '../../common/localization/localizationService';
import ColumnMenu from '../shared/ColumnMenu';
import { process } from '@progress/kendo-data-query';
import PropTypes from 'prop-types';
import ActionButtonCell from '../shared/ActionButtonCell';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function getColumnProps(fieldName, dataState, columnMenu) {
  return {
    field: fieldName,
    title: locale.routeOptimalizationWizard.routesGrid['_' + fieldName],
    columnMenu: columnMenu || ColumnMenu,
    headerClassName: GridColumnMenuFilter.active(fieldName, dataState.filter)
      ? 'active'
      : '',
  };
}

function processRoutes(routes, dataState) {
  const extendedRoutes = routes.map((route) => ({
    ...route,
    date: new Date(route.date),
  }));

  return process(extendedRoutes, dataState);
}

export default function RouteGrid({ routes, removeItem }) {
  const [dataState, setDataState] = useState({});

  const processedRoutes = processRoutes(routes, dataState);
  const BoundRemoveRouteButton = (props) => (
    <ActionButtonCell {...props} icon={faTrash} action={removeItem} />
  );

  return (
    <Grid
      {...dataState}
      data={processedRoutes}
      sortable
      onDataStateChange={(e) => setDataState(e.data)}
      resizable
    >
      <GridNoRecords>
        {locale.routeOptimalizationWizard._noRoutesImported}
      </GridNoRecords>
      <Column
        {...getColumnProps('date', dataState)}
        width={75}
        filter="date"
        format="{0:dd.MM.yyyy}"
      />
      <Column {...getColumnProps('gln', dataState)} width={120} />
      <Column {...getColumnProps('customerName', dataState)} />
      <Column {...getColumnProps('description', dataState)} width={120} />
      <Column {...getColumnProps('serial', dataState)} width={120} />
      {removeItem && <Column width="40px" cell={BoundRemoveRouteButton} />}
    </Grid>
  );
}

RouteGrid.propTypes = {
  routes: PropTypes.array,
  removeItem: PropTypes.func,
};

RouteGrid.defaultProps = {
  routes: [],
};
