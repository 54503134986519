import { Formik, FormikValues } from 'formik';
import React, { Component, ReactNode } from 'react';
import { Form, Modal, Button, Row, Col } from 'react-bootstrap';
import TextField from '../shared/formikFields/TextField';
import { locale } from '../../common/localization/localizationService';
import { Toastr } from '../../utils/Toastr';
import {
  IAddVehicleSetting,
  IContractor,
  ICustomer,
  IUser,
  IVehicle,
  IVehicleSetting,
  IVehicleSettingTemplate,
  VehicleSettingType,
} from '../../models';
import { debounceEventHandler } from '../shared/submitFormUtility';
import VehicleSettingService from '../../services/vehicleSettingService';
import VehicleSettingTemplateSelector from './VehicleSettingTemplateSelector';
import yup from '../../common/validation';
import { convertStringToBoolean } from '../../utils/GeneralUtils';
import ToggleField from '../shared/formikFields/ToggleField';
import ToggleButton from '../shared/customInputTypes/ToggleButton';
import VehicleMultiSelect from './VehicleMultiSelect';
import {
  mapAddVehicleSettingToBulkArray,
  filterVehicles,
} from '../vehicles/vehicleUtils';
import { connect } from 'react-redux';
import { IRootState } from '../../redux/state';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchVehicles } from '../../redux/modules/vehicles/vehiclesCreators';
import contractorActions from '../../redux/modules/contractor/contractorActions';
import customerActions from '../../redux/modules/customer/customerActions';
import { isNil } from 'lodash';
import CustomerSelector from '../userAdmin/CustomerSelector';
import ContractorSelector from '../userAdmin/ContractorSelector';

export interface IVehicleSettingEditDialogProps {
  authentication: IUser;
  vehicleSetting: IVehicleSetting;
  targetVehicle: IVehicle;
  vehicleSettingIdsExisting: string[];
  vehiclesList: Array<IVehicle>;
  show: boolean;
  customers: Array<ICustomer>;
  onHide: (rerender: boolean) => void;
  fetchVehicles: (ignoreCache?: boolean) => Promise<void>;
  getContractors: (
    customerId: number,
    ignoreCache?: boolean
  ) => Promise<Record<string, any>>;
  getCustomers: () => void;
}
export interface IVehicleSettingEditDialogState {
  isNew: boolean;
  isBulkVehicleSetting: boolean;
  vehicles: Array<IVehicle>;
  selectedVehicleIds: Array<string>;
  isSelectedVehicleIdsEmpty: boolean;
  modifiedVehicleSetting: IAddVehicleSetting;
  vehicleSettingTemplates: Array<IVehicleSettingTemplate>;
  selectedSettingTemplate: IVehicleSettingTemplate;
  selectedCustomerId: number;
  selectedContractorId: number;
  contractorsList: Array<IContractor>;
}

class VehicleSettingEditDialog extends Component<
  IVehicleSettingEditDialogProps,
  IVehicleSettingEditDialogState
> {
  private _isMounted: boolean = false;

  public async componentDidMount(): Promise<void> {
    this._isMounted = true;
    this.loadVehicleSettingTemplates();

    /** @todo refactor - fetch when the user first toggle isBulkVehicleSetting */
    await this.props.fetchVehicles(false);
    const { customerId, contractorId, isAdmin } = this.props.authentication;
    const { targetVehicle } = this.props;
    this.loadCustomers();
    if (isNil(contractorId)) {
      this.loadContractors(customerId);
    }
    if (isAdmin) {
      this.loadVehicles(targetVehicle.customerId, targetVehicle.contractorId);
    } else {
      this.loadVehicles(customerId, targetVehicle.contractorId);
    }
  }

  public componentWillUnmount(): void {
    this._isMounted = false;
  }

  public readonly state: IVehicleSettingEditDialogState = {
    vehicles: [],
    isBulkVehicleSetting: false,
    selectedVehicleIds: [],
    isSelectedVehicleIdsEmpty: true,
    isNew: !this.props.vehicleSetting,
    modifiedVehicleSetting: this.props.vehicleSetting
      ? {
          key: this.props.vehicleSetting.key,
          value: this.props.vehicleSetting.value,
        }
      : null,
    selectedSettingTemplate: null,
    vehicleSettingTemplates: [],
    selectedCustomerId: this.props.targetVehicle.customerId ?? -1,
    selectedContractorId: this.props.targetVehicle.contractorId ?? -1,
    contractorsList: [],
  } as IVehicleSettingEditDialogState;

  private loadVehicles = (customerId: number, contractorId: number): void => {
    const vehicles = contractorId
      ? filterVehicles(this.props.vehiclesList, {
          customerId,
          contractorId,
        })
      : filterVehicles(this.props.vehiclesList, { customerId });

    this.setState({
      ...this.state,
      vehicles,
    });
  };

  private loadCustomers = (): void => {
    if (this.props.authentication.isAdmin) {
      this.props.getCustomers();
    }
  };

  private handleContractorChanged = (
    selectedContractorId: Record<string, any>
  ) => {
    console.log('selectedContractorId', selectedContractorId.value);
    this.setState(
      {
        ...this.state,
        selectedContractorId: selectedContractorId.value,
      },
      () => {
        this.loadVehicles(
          this.state.selectedCustomerId,
          selectedContractorId?.value
        );
      }
    );
  };

  private handleCustomerChanged = async (
    selectedCustomerId: Record<string, any>
  ) => {
    this.setState({
      ...this.state,
      selectedCustomerId: selectedCustomerId.value,
    });
    await this.loadContractors(selectedCustomerId.value, true);
    this.loadVehicles(
      selectedCustomerId?.value,
      this.state.selectedContractorId
    );
  };

  private loadContractors = async (
    customerId: number,
    isAfterCustomerChanged = false,
    ignoreCache = false
  ): Promise<void> => {
    if (!isNil(this.props.authentication.contractorId))
      return new Promise((resolve) => resolve(null));
    const contractorsReq = await this.props.getContractors(
      customerId,
      ignoreCache
    );
    const contractors: Array<IContractor> = contractorsReq.contractors
      ? contractorsReq.contractors.sort((a: IContractor, b: IContractor) =>
          a.contractorName.localeCompare(b.contractorName)
        )
      : [];

    if (isAfterCustomerChanged) {
      await new Promise((resolve) => {
        const selectedContractorId =
          (Array.isArray(contractors) && contractors[0]?.contractorId) || -1;
        this.setState(
          {
            ...this.state,
            contractorsList: contractors,
            selectedContractorId,
          },
          () => resolve(null)
        );
      });
    } else {
      await new Promise((resolve) => {
        this.setState(
          {
            ...this.state,
            contractorsList: contractors,
          },
          () => {
            resolve(null);
          }
        );
      });
    }
  };

  private loadVehicleSettingTemplates = async (): Promise<void> => {
    const vehicleSettingTemplates = await this.getVehicleSettingTemplates();
    if (!vehicleSettingTemplates?.length) {
      Toastr.error(locale.vehicleSettings._failedToLoadSettingTempletes);
      this.props.onHide(false);
    }
    let selectedSettingTemplate;
    if (this.state.isNew) {
      const unusedTemplateIds = this.getUnusedTemplateIds(
        vehicleSettingTemplates,
        this.props.vehicleSettingIdsExisting
      );
      const isAllSettingsAlreadyExist = !unusedTemplateIds.length
        ? true
        : false;
      if (isAllSettingsAlreadyExist) {
        Toastr.error(locale.vehicleSettings._cannotAddNewSettingMessage);
        this.props.onHide(false);
      }
      selectedSettingTemplate = this.getSelectedSettingTemplateById(
        vehicleSettingTemplates,
        unusedTemplateIds[0]
      );
    } else {
      selectedSettingTemplate = this.getSelectedSettingTemplateById(
        vehicleSettingTemplates,
        this.props.vehicleSetting.key
      );
    }

    const modifiedVehicleSettingInit: IAddVehicleSetting =
      VehicleSettingService.getInitVehicleSetting(
        selectedSettingTemplate?.parameterType,
        selectedSettingTemplate?.parameterKey
      );
    if (this._isMounted) {
      if (this.state.isNew) {
        this.setState({
          ...this.state,
          vehicleSettingTemplates,
          selectedSettingTemplate,
          modifiedVehicleSetting: modifiedVehicleSettingInit,
        });
      } else {
        this.setState({
          ...this.state,
          vehicleSettingTemplates,
          selectedSettingTemplate,
          modifiedVehicleSetting: {
            ...this.state.modifiedVehicleSetting,
            value:
              selectedSettingTemplate.parameterType ===
              VehicleSettingType.Boolean
                ? convertStringToBoolean(
                    this.state.modifiedVehicleSetting.value as string
                  )
                : this.state.modifiedVehicleSetting.value,
          },
        });
      }
    }
  };

  private getUnusedTemplateIds = (
    possibleSettingTemplates: IVehicleSettingTemplate[],
    vehicleSettingIdsExisting: string[]
  ): string[] => {
    const possibleSettingTemplateIds = possibleSettingTemplates.map((s) =>
      s.parameterKey.toLocaleLowerCase()
    );
    const templatesNotInUse = possibleSettingTemplateIds.filter(
      (id) => !vehicleSettingIdsExisting.includes(id)
    );
    return templatesNotInUse;
  };

  private handleVehicleSettingTemplateChanged = (
    selectedTemplateId: Record<string, any>,
    resetForm?: (options: FormikValues) => void
  ) => {
    const selectedSettingTemplate = this.state.vehicleSettingTemplates.find(
      (template) => template.parameterKey === selectedTemplateId.value
    );
    const vehicleSettingWithNewValue: IAddVehicleSetting =
      VehicleSettingService.getInitVehicleSetting(
        selectedSettingTemplate?.parameterType,
        selectedSettingTemplate.parameterKey
      );
    this.setState(
      {
        ...this.state,
        selectedSettingTemplate,
        modifiedVehicleSetting: vehicleSettingWithNewValue,
      },
      () => {
        resetForm && resetForm({ values: vehicleSettingWithNewValue });
      }
    );
  };

  private getSelectedSettingTemplateById = (
    vehicleSettingTemplates: Array<IVehicleSettingTemplate>,
    parameterKey: string
  ): IVehicleSettingTemplate => {
    return vehicleSettingTemplates.find(
      (template) =>
        template.parameterKey.toLocaleLowerCase() ===
        parameterKey.toLocaleLowerCase()
    );
  };

  private getVehicleSettingTemplates = async (ignoreCache = false) => {
    try {
      const response = await VehicleSettingService.getVehicleSettingTemplates(
        ignoreCache
      );
      return response;
    } catch (error: any) {
      if (error?.message) {
        console.error(error?.message);
        Toastr.error(error?.message);
      }
    }
  };

  private getVehicleSettingValueRules = (type: VehicleSettingType): any => {
    switch (type) {
      case VehicleSettingType.Boolean:
        return yup.boolean().required().label(locale.vehicleSettings._value);
      case VehicleSettingType.String:
        return yup
          .string()
          .required()
          .max(255)
          .label(locale.vehicleSettings._value);
      case VehicleSettingType.Int:
        return yup
          .number()
          .integer()
          .required()
          .label(locale.vehicleSettings._value);
      case VehicleSettingType.Double:
        return yup.number().required().label(locale.vehicleSettings._value);
    }
  };

  private getSchema(type: VehicleSettingType) {
    const valueRules = this.getVehicleSettingValueRules(type);
    return yup.object({
      value: valueRules,
    });
  }

  private async onSubmit(
    vehicleSetting: IAddVehicleSetting,
    vehicleId: string,
    isNew: boolean
  ): Promise<void> {
    if (isNew) {
      try {
        if (this.state.isBulkVehicleSetting) {
          if (!this.state.selectedVehicleIds.length) {
            throw new Error(locale.vehicleSettings._addMessageFailed);
          }
          const vehicleSettingsList = mapAddVehicleSettingToBulkArray(
            vehicleSetting,
            this.state.selectedVehicleIds
          );
          await VehicleSettingService.addBulkVehicleSetting(
            vehicleSettingsList
          );
        } else {
          await VehicleSettingService.addVehicleSetting(
            vehicleSetting,
            vehicleId
          );
        }
        Toastr.success(locale.vehicleSettings._addMessageSuccessful);
      } catch (error) {
        console.log(error);
        Toastr.error(locale.vehicleSettings._addMessageFailed, true);
      } finally {
        this.props.onHide(true);
      }
    } else {
      try {
        await VehicleSettingService.updateVehicleSetting(
          vehicleSetting,
          vehicleId
        );
        Toastr.success(locale.vehicleSettings._editMessageSuccessful);
      } catch (error) {
        console.log(error);
        Toastr.error(locale.vehicleSettings._editMessageFailed, true);
      } finally {
        this.props.onHide(true);
      }
    }
  }

  private handleIsBulkVehicleSettingChange(
    e: React.FormEvent<HTMLInputElement>
  ) {
    this.setState({
      ...this.state,
      isBulkVehicleSetting: e.currentTarget.checked,
      isSelectedVehicleIdsEmpty: e.currentTarget.checked ? false : true,
    });
  }

  private handleVehicleOptionsChange(selectedVehicleIds: Array<string>) {
    this.setState({
      ...this.state,
      selectedVehicleIds,
      isSelectedVehicleIdsEmpty: !selectedVehicleIds.length,
    });
  }

  public render(): ReactNode {
    const vehicleId = this.props.targetVehicle.vehicleId;
    const show = this.props.show;
    const onHide = this.props.onHide;
    const { customers } = this.props;
    const { isAdmin, contractorId } = this.props.authentication;

    return (
      this.state.selectedSettingTemplate && (
        <Formik
          onSubmit={(vehicleSetting: IAddVehicleSetting) =>
            this.onSubmit(vehicleSetting, vehicleId, this.state.isNew)
          }
          initialValues={this.state.modifiedVehicleSetting}
          validationSchema={this.getSchema(
            this.state.selectedSettingTemplate?.parameterType
          )}
        >
          {({ handleSubmit, resetForm }) => (
            <Modal
              show={show}
              onHide={() => onHide(false)}
              className="vehicle-setting-edit-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {!this.state.isNew
                    ? locale.vehicleSettings._edit + ' ' + vehicleId + ': '
                    : locale.vehicleSettings._addNewSetting}
                  {!this.state.isNew && (
                    <span className="subtitle">
                      {this.state.modifiedVehicleSetting.key}
                    </span>
                  )}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form noValidate onSubmit={handleSubmit}>
                  {this.state.isNew && (
                    <div>
                      <VehicleSettingTemplateSelector
                        templates={this.state.vehicleSettingTemplates}
                        handleChange={(selectedTemplateId) => {
                          this.handleVehicleSettingTemplateChanged(
                            selectedTemplateId,
                            resetForm
                          );
                        }}
                        vehicleSettingIdsExisting={
                          this.props.vehicleSettingIdsExisting
                        }
                        selectedTemplateId={
                          this.state.selectedSettingTemplate?.parameterKey ?? ''
                        }
                        className="mb-3"
                      />
                    </div>
                  )}
                  {this.state.selectedSettingTemplate.parameterType ===
                    VehicleSettingType.String && (
                    <TextField
                      name="value"
                      label={locale.vehicleSettings._value}
                      placeholder={locale.vehicleSettings._value}
                    />
                  )}
                  {this.state.selectedSettingTemplate.parameterType ===
                    VehicleSettingType.Boolean && (
                    <ToggleField
                      name="value"
                      label={locale.vehicleSettings._value}
                    />
                  )}
                  {(this.state.selectedSettingTemplate.parameterType ===
                    VehicleSettingType.Int ||
                    this.state.selectedSettingTemplate.parameterType ===
                      VehicleSettingType.Double) && (
                    <TextField
                      name="value"
                      label={locale.vehicleSettings._value}
                      placeholder={locale.vehicleSettings._value}
                      type="number"
                    />
                  )}
                  {this.state.isNew && (
                    <Form.Group as={Row} className="checkbox-row mb-3">
                      <Form.Label column sm={6}>
                        {locale.vehicleSettings._addToMultiple}
                      </Form.Label>
                      <Col sm={6} className="checkbox-wrapper">
                        <ToggleButton
                          onToggle={(e: React.FormEvent<HTMLInputElement>) => {
                            this.handleIsBulkVehicleSettingChange(e);
                          }}
                          value={this.state.isBulkVehicleSetting}
                        />
                      </Col>
                    </Form.Group>
                  )}
                  {this.state.isBulkVehicleSetting && (
                    <>
                      {isAdmin && isNil(contractorId) && (
                        <Form.Group as={Row}>
                          <Col sm={12}>
                            <Form.Label>
                              {locale.vehicleSettings._selectCustomer}:
                            </Form.Label>
                          </Col>
                          <Col sm={12}>
                            <CustomerSelector
                              customers={customers}
                              handleChange={this.handleCustomerChanged}
                              selectedCustomerId={this.state.selectedCustomerId}
                              className="mb-3"
                            />
                          </Col>
                        </Form.Group>
                      )}
                      {isNil(contractorId) && (
                        <Form.Group as={Row}>
                          <Col sm={12}>
                            <Form.Label>
                              {locale.vehicleSettings._selectContractor}:
                            </Form.Label>
                          </Col>
                          <Col sm={12}>
                            <ContractorSelector
                              contractors={this.state.contractorsList}
                              handleChange={this.handleContractorChanged}
                              selectedContractorId={
                                this.state.selectedContractorId
                              }
                              className="mb-3"
                            />
                          </Col>
                        </Form.Group>
                      )}
                      <Form.Group as={Row}>
                        <Col sm={12}>
                          <Form.Label>
                            {locale.vehicleSettings._selectVehicle}:
                          </Form.Label>
                        </Col>
                        <Col sm={12}>
                          <VehicleMultiSelect
                            onVehicleOptionsChange={(
                              selectedVehicleIds: Array<string>
                            ) => {
                              this.handleVehicleOptionsChange(
                                selectedVehicleIds
                              );
                            }}
                            preselectedVehicleId={vehicleId}
                            authentication={this.props.authentication}
                            vehicles={this.state.vehicles}
                          />
                        </Col>
                        <Col sm={12}>
                          {this.state.isSelectedVehicleIdsEmpty && (
                            <p className="multiselect-error font-weight-normal">
                              {locale.vehicleSettings._multiselectError}
                            </p>
                          )}
                        </Col>
                      </Form.Group>
                    </>
                  )}
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => onHide(false)}>
                  {locale.vehicleSettings._cancel}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  onClick={debounceEventHandler(handleSubmit, 250)}
                  disabled={
                    this.state.isBulkVehicleSetting &&
                    this.state.isSelectedVehicleIdsEmpty
                  }
                >
                  {this.state.isNew
                    ? locale.vehicleSettings._submit
                    : locale.vehicleSettings._update}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      )
    );
  }
}

function mapStateToProps(state: IRootState) {
  return {
    vehiclesList: state.vehicles.vehiclesList,
    customers: state.customer.customers,
    authentication: state.authentication,
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getContractors: contractorActions.getContractors,
      getCustomers: customerActions.getCustomers,
      fetchVehicles: fetchVehicles,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
)(VehicleSettingEditDialog);
