import Papa from 'papaparse';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { locale } from '../../../common/localization/localizationService';

const importManagerLocale = locale.routeOptimalizationWizard.importManager;

const headerMap = {
  dato: 'date',
  gln: 'gln',
  kundenavn: 'customerName',
  beskrivelse: 'description',
  løpenummer: 'serial',
};

export async function parseRouteFromFile(file) {
  const fileName = file.name.toLowerCase();

  if (fileName.endsWith('.xlsx')) {
    return parseRouteFromXLSXFile(file);
  }

  return parseRouteFromCSVFile(file);
}

export function validateAndExtendRoute(importedRoutes, agreements) {
  const notFound = [];
  const agreementsMap = getAgreementsMap(agreements);

  const extendedRoutes = importedRoutes.map((route) => {
    const agreement = agreementsMap[route.gln];
    if (agreement == null) {
      notFound.push(route.gln);
      return route;
    }

    return {
      ...route,
      latitude: agreement.degLat,
      longitude: agreement.degLon,
    };
  });

  if (notFound.length > 0) {
    throw `${importManagerLocale._agreementNotFound}: ${notFound.join(', ')}`;
  }

  return extendedRoutes;
}

function parseRouteFromCSVFile(file) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      transformHeader: transformHeader,
      transform: transformDate,
      dynamicTyping: true,
      skipEmptyLines: 'greedy',
      complete: handleFileParsed.bind(undefined, resolve, reject),
      error: (errors) => {
        reject(formatErrors(errors));
      },
    });
  });
}

function parseRouteFromXLSXFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const options = { header: 1, blankrows: false, raw: false };
        const arrayData = XLSX.utils.sheet_to_json(worksheet, options);
        const headers = arrayData[0];
        const transformedHeaders = headers.map((x) => transformHeader(x));

        const result = arrayData
          .slice(1)
          .map((array) =>
            Object.fromEntries(
              array.map((value, index) => [
                transformedHeaders[index],
                index === transformedHeaders.indexOf('date')
                  ? transformDate(value, transformedHeaders[index])
                  : value,
              ])
            )
          );

        resolve(result);
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsArrayBuffer(file);
  });
}

function getAgreementsMap(agreements) {
  if (!agreements || !Array.isArray(agreements)) {
    return {};
  }

  return agreements.reduce(
    (map, agreement) => ({
      ...map,
      [agreement.agreementId]: agreement,
    }),
    {}
  );
}

function handleFileParsed(resolve, reject, results) {
  const errors = formatErrors(results.errors);
  if (!containsRequiredColumns(results.data)) {
    errors.unshift(importManagerLocale._wrongFileFormat);
  }

  if (results.data.length === 0) {
    errors.unshift(importManagerLocale._fileEmpty);
  }

  if (errors.length !== 0) {
    return reject(errors);
  }

  resolve(results.data);
}

function containsRequiredColumns(data) {
  if (data.length === 0) return true;

  const expectedHeaders = Object.values(headerMap);
  const actualHeaders = Object.keys(data[0]);

  return expectedHeaders.every((header) => actualHeaders.includes(header));
}

function transformHeader(header) {
  const normalized = header.toLowerCase();
  if (Object.prototype.hasOwnProperty.call(headerMap, normalized)) {
    return headerMap[normalized];
  }

  return header;
}

function transformDate(value, header) {
  if (header === 'date') {
    return moment(value, 'DD.MM.YYYY').toDate();
  }

  return value;
}

function formatErrors(errors) {
  return errors.map((error) => {
    if (error.row != null) {
      return `${error.row}: ${error.message}`;
    }
    return error.message;
  });
}
