import React from 'react';
import { locale } from '../../common/localization/localizationService';
import { reportsContactMailto } from '../../appConfig';

const mailtoPlaceholder = '{mailto}';

function AboutAndContact() {
  const segments = locale.reports._summary.split(mailtoPlaceholder);

  return (
    <div className="reports-about-contact">
      <h4>{locale.reports._header}</h4>
      <p>
        {segments[0]}
        <a href={`mailto:${reportsContactMailto}`}>{reportsContactMailto}</a>
        {segments[1]}
      </p>
    </div>
  );
}

export default AboutAndContact;
