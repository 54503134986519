import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faImage,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { locale } from '../../common/localization/localizationService';

const iconList = {
  exclamationCircle: faExclamationCircle,
  pictures: faImage,
};

function getDeviationContent(dataItem) {
  if (!dataItem || !dataItem.unitDeviations) {
    return '';
  }

  return dataItem.unitDeviations.map((deviation, i) => (
    <p key={i}>{deviation}</p>
  ));
}

function getImagesContent(dataItem) {
  if (!dataItem || !dataItem.pictures || !dataItem.pictures.length) {
    return '';
  }

  return (
    <p>
      {dataItem.pictures.length} {locale.orderDetails._pictureTakenText}
    </p>
  );
}

function renderTooltip(props, tooltipContent) {
  return (
    <Tooltip id="deviation-tooltip" {...props}>
      {tooltipContent}
    </Tooltip>
  );
}

function DeviationCell(props) {
  const { dataItem, field, onClick } = props;

  const icons = {
    deviation: {
      visible: dataItem[field],
      icon: iconList.exclamationCircle,
      tooltip: getDeviationContent(dataItem),
    },
    pictures: {
      visible: dataItem.pictures && dataItem.pictures.length,
      icon: iconList.pictures,
      tooltip: getImagesContent(dataItem),
    },
  };

  return (
    <td className="icon-cell deviation-cell" onClick={() => onClick(props)}>
      {icons.pictures.visible && (
        <OverlayTrigger
          overlay={(props) => renderTooltip(props, icons.pictures.tooltip)}
        >
          <FontAwesomeIcon icon={icons.pictures.icon} />
        </OverlayTrigger>
      )}
      {icons.deviation.visible && (
        <OverlayTrigger
          overlay={(props) => renderTooltip(props, icons.deviation.tooltip)}
        >
          <FontAwesomeIcon icon={icons.deviation.icon} />
        </OverlayTrigger>
      )}
    </td>
  );
}

DeviationCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default DeviationCell;
