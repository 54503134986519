import React, { Component, ReactElement, ReactNode } from 'react';
import {
  ListItemProps,
  MultiSelect,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
} from '@progress/kendo-react-dropdowns';
import { IRoute } from '../../models';
import { locale } from '../../common/localization/localizationService';
import { empty } from '../../utils';
import { filterBy } from '@progress/kendo-data-query';
import { FilterDescriptor } from '@progress/kendo-react-dropdowns/dist/npm/common/filterDescriptor';
import RefreshButton from '../shared/refreshButton/RefreshButton';

export interface IRouteAdminSelectProps {
  disabled: boolean;
  buttonClass: string;
  hideButtonClass: string;
  data: Array<IRoute>;
  defaultValue: Array<IRoute>;
  refreshItems: () => void;
  hideRouteStopsWithoutAgreement: () => void;
  changeRegionFilteringState: () => void;
  onChange: (routes: Array<IRoute>) => void;
}
export interface IRouteAdminSelectState {
  data: Array<IRoute>;
  options: Array<IRoute>;
  filter?: FilterDescriptor;
}
export class RouteAdminSelect extends Component<
  IRouteAdminSelectProps,
  IRouteAdminSelectState
> {
  public readonly state: IRouteAdminSelectState = { data: [], options: [] };

  public componentDidUpdate(): void {
    const prev = this.state.data.map((data) => data.routeNumbder);
    const next = this.props.data.map((data) => data.routeNumbder);

    if (prev.join(',') === next.join(',')) return;
    this.setState({
      ...this.state,
      data: this.props.data.slice(),
      options: filterBy(this.props.data.slice(), this.state.filter),
    });
  }

  public onChange(event: MultiSelectChangeEvent): void {
    this.props.onChange(event.target.value);
  }

  public refreshItems(): void {
    this.props.refreshItems();
  }

  public hideRouteStopsWithourAgreement(): void {
    this.props.hideRouteStopsWithoutAgreement();
  }

  public changeRegionFilteringState(): void {
    this.props.changeRegionFilteringState();
  }

  public onFilterChange(event: MultiSelectFilterChangeEvent): void {
    this.setState({
      ...this.state,
      options: filterBy(this.props.data.slice(), event.filter),
    });
  }

  public renderItem(li: ReactElement, props: ListItemProps): ReactNode {
    const children = (
      <span>
        <input type="checkbox" checked={props.selected} onChange={empty} />
        &nbsp;{li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, children);
  }

  public render(): ReactNode {
    const { disabled, defaultValue, buttonClass, hideButtonClass } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-6">
            <h6>{locale.routeAdminPage._selectRoute}</h6>
          </div>
          <div className="col-6 text-right mb-3">
            <button
              className={`btn btn-outline-dark mr-1 ${hideButtonClass}`}
              onClick={() => this.hideRouteStopsWithourAgreement()}
            >
              {locale.routeAdminPage._hideWithoutAgreement}
            </button>
            <button
              className={`btn btn-outline-dark mr-1 ${buttonClass}`}
              onClick={() => this.changeRegionFilteringState()}
            >
              {locale.routeAdminPage._withoutPosition}
            </button>
            <RefreshButton onClick={() => this.refreshItems()}>
              <span className={'pl-1'}>{locale.routeAdminPage._refresh}</span>
            </RefreshButton>
          </div>
        </div>

        <MultiSelect
          disabled={disabled}
          filterable={true}
          style={{ width: '100%' }}
          data={this.state.options}
          itemRender={(li, props) => this.renderItem(li, props)}
          autoClose={false}
          textField="name"
          dataItemKey="routeNumbder"
          defaultValue={defaultValue}
          onChange={(e) => this.onChange(e)}
          onFilterChange={(e) => this.onFilterChange(e)}
        />
      </>
    );
  }
}
