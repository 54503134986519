import {
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, Component } from 'react';
import { locale } from '../../common/localization/localizationService';

import './WarningBanner.scss';

export interface IWarningBannerProps {
  hidden: boolean;
  isCompact?: boolean;
  text: string;
  type?: 'info' | 'warning';
  onClick?: () => void;
  onAnchor?: () => void;
  anchor?: string;
}
export class WarningBanner extends Component<IWarningBannerProps> {
  public onClick(): void {
    if (this.props.onClick) this.props.onClick();
  }

  public onAnchor(): void {
    if (this.props.onAnchor) this.props.onAnchor();
  }

  public render(): ReactNode {
    const { hidden, text, type, anchor, isCompact } = this.props;
    const isInfo = type === 'info';
    const icon = isInfo ? faInfoCircle : faExclamationTriangle;
    if (hidden) return null;
    return (
      <div
        className={`warning-banner ${isCompact ? 'compact' : ''} ${
          isInfo ? 'info' : 'warn'
        }`}
      >
        <div
          className="banner"
          onClick={() => this.onClick()}
          style={{ cursor: this.props.onClick ? 'pointer' : 'auto' }}
        >
          <FontAwesomeIcon icon={icon} />
          <p>
            <strong>{locale.general._warning}</strong>
            <span>{` ${text}`}</span>
            <span
              className="anchor"
              onClick={() => this.onAnchor()}
            >{` ${anchor}`}</span>
          </p>
        </div>
      </div>
    );
  }
}
