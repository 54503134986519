import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuFilter,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import ExecutedCell from './ExecutedCell';
import DeviationCell from './DeviationCell';
import './orderDetails.scss';
import { locale } from '../../common/localization/localizationService';
import { process } from '@progress/kendo-data-query';
import ColumnMenu from '../shared/ColumnMenu';
import { routeStopEquals } from '../../redux/modules/orderDetails/orderDetailsUtility';
import InfoButtonCell from '../shared/infoButtonCell/InfoButtonCell';
import RouteStopDetails from '../routeStopDetails';
import { extractRouteStopDetails } from '../shared/routeStopUtility';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import orderDetailsActions from '../../redux/modules/orderDetails/orderDetailsActions';
import { orderDetailsGridPageSize } from '../../appConfig';
import memoizeOne from 'memoize-one';
import routes from '../../common/routes';
import ReactDOM from 'react-dom';

class OrderDetailsGrid extends Component {
  constructor(props) {
    super(props);

    this.dataStateChange = this.dataStateChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.ExecutedCell = this.ExecutedCell.bind(this);
    this.DeviationCell = this.DeviationCell.bind(this);
    this.InfoButtonCell = this.InfoButtonCell.bind(this);
    this.initialFilter = {
      sort: [{ field: 'time', dir: 'desc' }],
      take: orderDetailsGridPageSize,
      skip: 0,
      orderDetailUrl: props.currentUrl,
    };
    this.gridRef = React.createRef();
    if (!props.filterData) {
      props.orderDetailsActions.setOrderDetailsGridFilter(this.initialFilter);
    }
    if (!props.filterDatas || props.filterDatas.length === 0) {
      props.orderDetailsActions.setOrderDetailsGridFilters([
        this.initialFilter,
      ]);
    } else if (
      !props.filterDatas.some(
        (data) => data.orderDetailUrl === props.currentUrl
      )
    ) {
      const filters = props.filterDatas;
      filters.push(this.initialFilter);
      props.orderDetailsActions.setOrderDetailsGridFilters(filters);
    }
  }

  static propTypes = {
    currentUrl: PropTypes.string.isRequired,
    selectedRouteStop: PropTypes.object,
    dataButtons: PropTypes.array.isRequired,
    order: PropTypes.object,
    filterData: PropTypes.object,
    filterDatas: PropTypes.array,
    className: PropTypes.string,
  };

  componentDidMount() {
    this.calculateUnitColumnWidth();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedRouteStop !== this.props.selectedRouteStop) {
      this.jumpToSelectedRowPage();
    }
  }

  extendRouteStops(routeStops, dataButtons) {
    return routeStops.map((routeStop) =>
      extractRouteStopDetails(routeStop, dataButtons)
    );
  }

  memoizedExtendRouteStops = memoizeOne(this.extendRouteStops);

  processRouteStops(routeStops, selectedRouteStop, dataButtons, dataState) {
    const extendedRouteStops = this.memoizedExtendRouteStops(
      routeStops,
      dataButtons
    ).map((routeStopInfo) => ({
      ...routeStopInfo,
      selected: routeStopEquals(routeStopInfo.routeStop, selectedRouteStop),
    }));
    return process(extendedRouteStops, dataState);
  }

  getColumnProps(fieldName, columnMenu) {
    let filterData = this.props.filterDatas.find(
      (data) => data.orderDetailUrl === this.props.currentUrl
    );
    if (!filterData) {
      filterData = this.props.filterData;
    }
    return {
      field: fieldName,
      title: locale.orderDetails['_' + fieldName],
      columnMenu: columnMenu || ColumnMenu,
      headerClassName: GridColumnMenuFilter.active(fieldName, filterData.filter)
        ? 'active'
        : '',
    };
  }

  dataStateChange(event) {
    const eventData = event.data;
    eventData.orderDetailUrl = this.props.currentUrl;
    const filters = this.props.filterDatas.map((obj) => {
      if (obj.orderDetailUrl === eventData.orderDetailUrl) {
        return { ...obj, ...eventData };
      } else {
        return obj;
      }
    });
    this.props.orderDetailsActions.setOrderDetailsGridFilters(filters);
  }

  onRowClick(row) {
    this.props.orderDetailsActions.setSelectedRouteStop(row.dataItem.routeStop);
  }

  ExecutedCell(props) {
    return <ExecutedCell onClick={this.onRowClick} {...props} />;
  }

  DeviationCell(props) {
    return <DeviationCell onClick={this.onRowClick} {...props} />;
  }

  InfoButtonCell(gridProps) {
    const { currentUrl } = this.props;
    const routeStop = gridProps.dataItem.routeStop;

    const query = new URLSearchParams();
    query.append(RouteStopDetails.paramKeyRouteLineId, routeStop.routeLineId);
    query.append(RouteStopDetails.paramKeyPASystem, routeStop.paSystem);
    query.append(
      RouteStopDetails.paramKeyAgreementLineId,
      routeStop.agreementLineId
    );

    return (
      <InfoButtonCell
        onClick={this.onRowClick}
        linkToUrl={`${currentUrl}/${routes.routeStopDetails.base}?${query}`}
        {...gridProps}
      />
    );
  }

  calculateUnitColumnWidth = () => {
    const { selectedRouteStop } = this.props;
    if (!selectedRouteStop || !selectedRouteStop.data) return;

    const measureTextWidth = (text) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = '11px DejaVu Sans';
      return context.measureText(text).width;
    };

    let maxWidth = 100;

    routeStops.data.forEach((row) => {
      const cellContent = String(row.unit);
      const cellWidth = measureTextWidth(cellContent);
      if (cellWidth > maxWidth) {
        maxWidth = cellWidth;
      }
    });

    this.setState({ unitColumnWidth: maxWidth + 20 });
  };

  jumpToSelectedRowPage() {
    const { order, selectedRouteStop } = this.props;
    if (!order || !selectedRouteStop) return;

    const routeStops = order.generatedRoutes;

    const selectedIndex = routeStops.findIndex(
      (stop) => stop.routeLineId === selectedRouteStop.routeLineId
    );

    if (selectedIndex === -1) return;

    const page = Math.floor(selectedIndex / orderDetailsGridPageSize);
    const pageData = {};
    pageData.data = {
      skip: page * orderDetailsGridPageSize,
      take: orderDetailsGridPageSize,
    };
    this.dataStateChange(pageData);
    this.calculateUnitColumnWidth();
    setTimeout(() => {
      this.scrollToSelectedRow();
    }, 200);
  }

  scrollToSelectedRow() {
    const gridElement = ReactDOM.findDOMNode(this.gridRef.current);
    if (gridElement instanceof HTMLElement) {
      const gridContentElement = gridElement.querySelector('.k-grid-content');
      if (gridContentElement) {
        const rowElements = gridContentElement.getElementsByTagName('tr');

        const rowArray = Array.from(rowElements);

        const selectedIndex = rowArray.findIndex((row) =>
          row.classList.contains('k-state-selected')
        );
        if (selectedIndex !== -1 && rowElements[selectedIndex]) {
          const rowTop = rowElements[selectedIndex].offsetTop;
          const rowHeight = rowElements[selectedIndex].clientHeight;
          const gridContentHeight = gridContentElement.clientHeight;

          gridContentElement.scrollTop =
            rowTop - gridContentHeight / 2 + rowHeight / 2;
        }
      }
    }
  }

  memoizedProcessRouteStops = memoizeOne(this.processRouteStops);

  render() {
    const { order, selectedRouteStop, dataButtons, filterDatas, className } =
      this.props;
    let filterData = filterDatas.find(
      (data) => data.orderDetailUrl === this.props.currentUrl
    );
    if (!filterData) {
      filterData = this.initialFilter;
    }
    const routeStops =
      order &&
      this.memoizedProcessRouteStops(
        order.generatedRoutes,
        selectedRouteStop,
        dataButtons,
        filterData
      );

    const isColumnsHidden =
      !order || new Date() < new Date(order.date) ? true : false;
    return (
      <Grid
        {...filterData}
        data={routeStops}
        className={'order-details-grid ' + className}
        sortable
        onDataStateChange={this.dataStateChange}
        selectedField="selected"
        onRowClick={this.onRowClick}
        pageable
        resizable
        pageSize={orderDetailsGridPageSize}
        ref={this.gridRef}
      >
        <GridNoRecords>{locale.general._noRecords}</GridNoRecords>

        {isColumnsHidden && (
          <Column {...this.getColumnProps('sequence')} width="70px" />
        )}
        {!isColumnsHidden && (
          <Column
            {...this.getColumnProps('time')}
            width="70px"
            filter="date"
            format="{0:dd.MM HH:mm}"
            columnMenu={false}
          />
        )}
        <Column {...this.getColumnProps('companyName')} width="auto" />
        <Column {...this.getColumnProps('address')} width="auto" />
        <Column {...this.getColumnProps('unit')} width="auto" />
        {!isColumnsHidden && (
          <Column
            {...this.getColumnProps('executed')}
            width="70px"
            cell={this.ExecutedCell}
            columnMenu={false}
          />
        )}
        {!isColumnsHidden && (
          <Column
            {...this.getColumnProps('deviation')}
            width="70px"
            cell={this.DeviationCell}
            filter="boolean"
          />
        )}
        {!isColumnsHidden && (
          <Column {...this.getColumnProps('vehicleId')} width="80px" />
        )}
        {!isColumnsHidden && (
          <Column {...this.getColumnProps('driver')} width="80px" />
        )}
        <Column cell={this.InfoButtonCell} width="40px" />
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedRouteStop: state.orderDetails.selectedRouteStop,
    filterData: state.orderDetails.filterData,
    filterDatas: state.orderDetails.filterDatas,
    dataButtons: state.settings.dataButtons,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orderDetailsActions: bindActionCreators(orderDetailsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsGrid);
