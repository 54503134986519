import React, { Component, ReactNode } from 'react';
import { IUser } from '../../models';
import PaneHeadline from '../shared/paneHeadline';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { locale } from '../../common/localization/localizationService';
import { ICustomerMessage } from '../../models/CustomerMessage.model';
import { DirectMessageGrid } from './DirectMessagesGrid';
import './messages.scss';
import { Spinner2 } from '../shared/spinner2';
import routes from '../../common/routes';
import { Link } from 'react-router-dom';
import InfoMessageModal from './InfoMessageModal';
import EventBus from '../../common/EventBus';

export interface IMessagesProps {
  authentication: IUser;
  getMessages: (ignoreCache?: boolean) => Promise<Record<string, any>>;
  messageList: Array<ICustomerMessage>;
  isLoading: boolean;
}

export interface IMessagesState {
  messageDialog: {
    show: boolean;
    message: any;
  };
}

export class Messages extends Component<IMessagesProps, IMessagesState> {
  public readonly state: IMessagesState = {
    messageDialog: {
      show: false,
      message: null,
    },
  };

  public componentDidMount(): void {
    this.props.getMessages(true);
    EventBus.emit('fullScreenChange', true);
  }

  public componentWillUnmount(): void {
    EventBus.emit('fullScreenChange', false);
  }

  private openMessageDialog(message: any): void {
    this.setState({
      ...this.state,
      messageDialog: { message, show: true },
    });
  }

  private hideMessageDialog(): void {
    this.setState({
      ...this.state,
      messageDialog: { message: null, show: false },
    });
  }

  public render(): ReactNode {
    const { messageList, isLoading } = this.props;
    return (
      <div>
      <div className="row">
        <PaneHeadline
            titleText={locale.directMessages._messages}
            titleIcon={faEnvelope}
            className='col-sm-6'/>
        <div className="col-sm-6 text-right mb-3">
            <Link
              className="btn btn-outline-dark"
              to={routes.customerSearch}
            >
              {locale.directMessages._newMessage}
            </Link>
        </div>
        </div>       
        <div className="row">
          <div className="col-md-12 customer-search-grid-wrapper">
            <Spinner2 show={isLoading} />
            {messageList && (
              <DirectMessageGrid
                data={messageList}
                openMessageDialog={this.openMessageDialog.bind(this)}
              />
            )}
          </div>
        </div>
        {this.state.messageDialog.show && (
          <InfoMessageModal
            onHide={this.hideMessageDialog.bind(this)}
            show={this.state.messageDialog.show}
            selectedMessage={this.state.messageDialog.message}
          />
        )}
      </div>
    );
  }
}

export default Messages;
