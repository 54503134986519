
import { locale } from '../../common/localization/localizationService';
export interface IOrderSatus {
  orderStatusId: number;
  status: string;
}

export const orderStatuses: Array<IOrderSatus> = [
  { orderStatusId: 1, status: locale.orderStatuses._done },
  { orderStatusId: 3, status: locale.orderStatuses._started },
  { orderStatusId: 7, status: locale.orderStatuses._partiallyDone },
];
