export const localeNl = {
  general: {
    _administration: 'Administratie',
    _username: 'Gebruikersnaam',
    _password: 'Wachtwoord',
    _date: 'Datum',
    _time: 'Tijd',
    _orderOverview: 'Bestellingsoverzicht',
    _reports: 'Rapporten',
    _kg: 'kg',
    _ton: 'ton',
    _callOrder: 'Oproeporders',
    _serviceList: 'Werklijst',
    _impersonationManager: 'Stel klant/gebruiker in',
    _userAdmin: 'Gebruikersbeheer',
    _routeAdmin: 'Routes',
    _backendError: 'Er is een fout opgetreden bij het verzoek',
    _accessError: 'Je hebt geen toegang tot deze pagina',
    _vehicles: 'Voertuigen',
    _vehicleSettings: 'Administratieve Instellingen',
    _zoomIn: 'Inzoomen',
    _zoomOut: 'Uitzoomen',
    _warning: 'Waarschuwing!',
    _customerRegister: 'Zoek in klantenregister',
    _commune: 'Gemeente',
    _logOut: 'Uitloggen',
    _selectLanguage: 'Selecteer taal',
    _unitDesrciption: 'Eenheidstype',
    _fullDateFormat: 'dd.MM.yyyy',
    _fullDateWithTime: 'dd.MM.yyyy HH:mm:ss',
    _fullMomentFormat: 'DD.MM.YYYY',
    _fullMomentWithTime: 'DD.MM.YYYY HH:mm:ss',
    _noRecords: 'Geen records beschikbaar.',
  },
  login: {
    _usernameEmail: 'E-mail',
    _usernameRequired: 'Gebruikersnaam is verplicht!',
    _passwordRequired: 'Wachtwoord is verplicht!',
    _usernamePasswordInvalid: 'Ongeldige gebruikersnaam of wachtwoord',
    _invalidEmail: 'Gebruikersnaam is geen geldig e-mailadres!',
    _submit: 'Inloggen',
    _welcome: 'Welkom bij GPS Realtime WMS',
    _pressLogin: 'Klik op Inloggen om in te loggen op het portaal',
    _ifAssistance: 'Indien hulp nodig,',
    _pleaseContact: 'neem gerust contact op met onze support',
  },
  tooltip: {
    _deviceId: 'Apparaatnummer',
    _altitude: 'Hoogte',
    _speed: 'Snelheid',
    _temperature: 'Temperatuur',
    _orderId: 'Ordernummer',
    _address: 'Adres',
    _routeName: 'Route',
  },
  orders: {
    _update: 'Bijgewerkt',
    _start: 'Start',
    _route: 'Route',
    _vehicleId: 'Voertuig',
    _orderId: 'Bestelling',
    _status: 'Status',
    _notStarted: 'Niet gestart',
    _now: 'Nu',
    _clearFilter: 'Filter wissen',
    _fraction: 'Fractie',
    _showFilters: 'Filters weergeven',
    _chooseContractor: 'Kies vervoerder',
    _chooseFraction: 'Kies fractie',
    _expires: 'Verloopt',
    _date: 'Datum',
    _ordinaryDate: 'Gewone datum',
    _earlierDateValidation:
      'Vervaldatum kan niet eerder zijn dan de startdatum',
    _fractions: 'Fracties',
    _selectFractions: 'Selecteer fracties',
    _noFractions: 'Geen fracties',
    _updateOrder: 'Update bestelling',
    _updateSuccess: 'Bestelling is bijgewerkt',
    _fractionsLoading: 'Fracties worden geladen',
    _notEditableWarning:
      'Zodra de route is gestart, kan alleen de vervaldatum worden gewijzigd.',
    status: {
      _noDone: 'Niet voltooid',
      _okDone: 'Ok, voltooid',
      _downloaded: 'Gedownload',
      _started: 'Gestart',
      _backOrder: 'Achterstallige bestelling',
      _localBackOrder: 'Lokale achterstallige bestelling',
      _partiallyStarted: 'Gedeeltelijk gestart',
      _partiallyCompleted: 'Gedeeltelijk voltooid',
      _rejected: 'Afgewezen',
      _planned: 'Gepland',
      _deleted: 'Verwijderd',
    },
  },
  orderDetails: {
    _address: 'Adres',
    _unit: 'Eenheid',
    _executed: 'Uitgevoerd',
    _vehicleId: 'Voertuig',
    _driver: 'Chauffeur',
    _time: 'Tijd',
    _deviation: 'Afwijking',
    _orderDoesNotExist: 'De bestelling bestaat niet',
    _companyName: 'Klant',
    _pictureTakenText: "foto's",
    _sequence: 'Volgorde',
  },
  orderStatuses: {
    _started: 'Gestart',
    _done: 'OK, gereden',
    _partiallyDone: 'Gedeeltelijk voltooid',
  },
  orderDetailsDiagram: {
    _executionStatusHeader: 'Uitgevoerd (Ja / Nee)',
    _deviationsHeader: 'Afwijkingen / Opmerkingen',
    _noDeviations: 'Geen afwijkingen / opmerkingen',
  },
  routeStopDetails: {
    _routeStopDoesNotExist: 'De halte bestaat niet',
    _departureTime: 'Vertrektijd',
    _arrivalTime: 'Aankomsttijd',
    _address: 'Adres',
    _agreementId: 'OvereenkomstNr',
    _unit: 'Eenheid',
    _agreementLineId: 'BehNr',
    _executed: 'Uitgevoerd',
    _externalOrderId: 'BestellingNr',
    _vehicleId: 'Voertuig',
    _driver: 'Chauffeur',
    _orderData: 'Bestelgegevens',
    _pictures: "Foto's",
    _deviation: 'Afwijking / Opmerking',
    _comment: 'Opmerking',
  },
  routeAdminPage: {
    _selectRoute: 'Selecteer route(s)',
    _refresh: 'Verversen',
    _withoutPosition: 'Zonder positie',
    _sequence: 'Volgorde',
    _description: 'Beschrijving',
    _routeLineId: 'Halte',
    _agreementId: 'Overeenkomst-id',
    _name: 'Naam',
    _estate: 'Eigendom',
    _unitName: 'Product',
    _agreementLineId: 'Overeenkomstregel-id',
    _physicalAmount: 'Aantal',
    _route: 'Route',
    _degLat: 'Breedtegraad',
    _degLong: 'Lengtegraad',
    _invalidSequence: 'Ongeldig volgnummer',
    _changeSequence: 'Verander volgorde',
    _cancelButton: 'Annuleren',
    _submitButton: 'Opslaan',
    _validateButton: 'Valideren',
    _restoreButton: 'Annuleren',
    _locationChangeTitle: 'Weet je zeker dat je wilt opslaan?',
    _locationChangeContent: 'Je wijzigingen worden nu opgeslagen',
    _splitStoppoint: 'Halte splitsen',
    _splitModalPlaceholder:
      'Sleep een klant hierheen om een nieuwe halte te maken',
    _splitModalErrorMessage: 'Je moet minstens 2 haltes maken',
    _mergeModalTitle: 'Haltes samenvoegen',
    _mergeModalContent: 'Geselecteerde haltes',
    _selectOnlyOne: 'Selecteer alstublieft slechts 1 halte',
    _selectAtLeastOne: 'Minstens 1 halte moet geselecteerd worden',
    _selectAtLeastTwo: 'Minstens 2 haltes moeten geselecteerd worden',
    _mergeStoppoints: 'Halten samenvoegen',
    _sequenceEdit: 'Volgorde wijzigen',
    _savePosition: 'Positie opslaan',
    _changePosition: 'Positie wijzigen',
    _changePositionTooltip: 'Wijzig de positie van de geselecteerde halte',
    _agreementGid: 'GnrBnrFnrSnr',
    _placeId: 'Halte-ID',
    _address: 'Adres',
    _customerName: 'Klantnaam',
    _propertyLinesWarning: 'Sommige punten kunnen buiten de kaart verschijnen.',
    _propertyLinesCustom: 'Toon alleen punten met coördinaten.',
    _propertyLinesAll: 'Toon alle punten.',
    _changesHeader: 'De volgende volgordenummers worden beïnvloed',
    _changedFrom: 'veranderd van',
    _changedTo: 'naar',
    _notInNorwayErrorMessage: 'De ingestelde positie ligt buiten Noorwegen',
    _alreadyMerged: 'Geselecteerde afspraken zijn al samengevoegd',
    _mergeModalTargetText: 'Selecteer weergave/adres van de halte',
    _mergeModalTargetDropdownText: 'Selecteer welke weergave/adres',
    _setPosition: 'Positie instellen',
    _setPositionTooltip: 'Positie instellen voor geselecteerde halte',
    _noRecords: 'Geen records beschikbaar.',
    _isLoadingMessage: 'Records worden geladen.',
    _sequenceSuccessfullyChanged: 'Volgorde is bijgewerkt',
    _remove: 'Verwijderen',
    _selection: 'keuze',
    _hideWithoutAgreement: 'Verbergen zonder overeenkomst',
  },
  reports: {
    _exportTitle: 'Exporteer naar Excel-bestand',
    _nothingToExportTitle: 'Geen gegevens om te exporteren',
    _showReport: 'Toon rapport',
    _fromDate: 'Van datum',
    _toDate: 'Tot datum',
    _selectReport: 'Selecteer rapport',
    _reportEmpty: 'Geen records beschikbaar',
    _header: 'Mijn rapporten',
    _summary:
      'Hier hebben we al uw rapporten verzameld. Rapporten kunnen worden besteld bij onze consultants. Neem contact met ons op via e-mail {mailto}.',
    _export: 'Exporteren',
  },
  serviceLists: {
    _noOrders: 'Geen bestellingen gepland.',
    _delete: 'Verwijderen',
    _add: 'Toevoegen',
    _deleteConfirm: 'Weet je zeker dat je deze werklijst wilt verwijderen: ',
  },
  routeOptimalizationWizard: {
    _title: 'Maak ritlijsten',
    _uploadFileStep: 'Bestand uploaden',
    _uploadFileButton: 'Selecteer bestand',
    _analyseStep: 'Analyseren',
    _analyseButton: 'Voer analyse uit',
    _saveStep: 'Ritlijsten',
    _saveButton: 'Maak ritlijsten',
    _downloadTemplateButton: 'Download importtemplate',
    _importedRouteTabTitle: 'Bestelbasis',
    _worklistsTabTitle: 'Ritlijsten',
    _unassignedStopsTabTitle: 'Bestellingen niet inbegrepen',
    _noRoutesImported: 'Geen bestellingen, upload bestand',
    _worklistEmpty: 'De ritlijst is leeg',
    _worklistOptionText: 'Ritroute',
    _addCustomer: 'Klant toevoegen',
    _duplicateRoutesWarning: 'Er zijn dubbele bestellingen in de bestelbasis',
    _noFileSelectedTitle: 'Geen bestand geselecteerd!',
    _noFileSelectedDescription:
      'Upload de bestelbasislijst met de volgende importtemplate:',
    importManager: {
      _unknownParseError: 'Onbekende fout opgetreden',
      _fileLoadResultsModalTitle: 'Bestand laadresultaten',
      _fileLoadSuccess: 'Bestand succesvol geladen!',
      _fileLoadModalCloseButton: 'Sluiten',
      _agreementNotFound: 'Klanten niet gevonden voor klantnr',
      _wrongFileFormat: 'Verkeerd bestandformaat. Download importtemplate',
      _fileEmpty: 'Het bestand bevat geen gegevens',
    },
    addCustomerModal: {
      _title: 'Klant toevoegen',
      _closeButton: 'Sluiten',
      _addButton: 'Toevoegen',
      _dateLabel: 'Datum',
      _agreementLabel: 'Klant',
      _descriptionLabel: 'Beschrijving',
      _serialLabel: 'Serienummer',
      _noOptionsMessage: 'Geen klanten gevonden',
      _loadingMessage: 'Klanten worden geladen',
      _agreementSelector: 'Selecteer klant',
      _agreementSelectorPlaceholder: 'Selecteer klant...',
    },
    routeAnalysisModal: {
      _title: 'Analyseer bestelbasis',
      _closeButton: 'Sluiten',
      _cancelButton: 'Annuleren',
      _analyseButton: 'Analyseren',
      _selectStartAndStop: 'Selecteer start- en eindpunt',
      _workTime: 'Effectieve werktijd',
      _timePerStop: 'Tijd per stop (minuten)',
      _maximumCars: "Maximaal aantal auto's in analyse",
      _startStopLabel: 'Start- en eindpunt',
      _workHoursLabel: 'Uren',
      _workMinutesLabel: 'Minuten',
      _timePerStopLabel: 'Tijd per stop',
      _maximumCarsLabel: "Maximaal aantal auto's",
      _noDepotOptionsMessage: 'Geen depots gevonden',
      _depotsLoadingMessage: 'Depots worden geladen',
      _depotSelectorPlaceholder: 'Selecteer depot...',
      _analysationError: 'Fout bij het analyseren van de bestelbasis',
    },
    routesGrid: {
      _date: 'Datum',
      _gln: 'GLN',
      _customerName: 'Klantnaam',
      _description: 'Beschrijving',
      _serial: 'Serienummer',
    },
    worklistResults: {
      _sequence: 'Volgorde',
      _arrivalTime: 'Tijd',
      _gln: 'GLN',
      _customerName: 'Klantnaam',
      _description: 'Beschrijving',
      _serial: 'Serienummer',
      _unassignedWarning: 'bestellingen niet inbegrepen!',
      worklistSummary: {
        _totalTime: 'Totale tijd',
      },
      moveWorklistItemModal: {
        _title: 'Verplaats naar',
        _targetListLabel: 'Ritlijst',
        _closeButton: 'Sluiten',
        _moveButton: 'Verplaats naar',
      },
    },
    createWorklistsModal: {
      _title: 'Maak ritlijsten',
      _closeButton: 'Sluiten',
      _saveButton: 'Maak aan',
      _itemCount: 'Aantal',
      _listName: 'Lijstnaam',
      _saveError: 'Fout bij het maken van ritlijsten',
    },
  },
  dateRangePicker: {
    _thisWeek: 'Deze week',
    _lastWeek: 'Vorige week',
    _thisMonth: 'Deze maand',
  },
  serviceListsGrid: {
    _listName: 'Lijstnaam',
    _vehicleId: 'Voertuig',
    _updated: 'Bijgewerkt',
    _started: 'Start',
    _listDate: 'Datum',
  },
  serviceOrders: {
    _noOrders: 'Geen bestellingen gepland.',
    _excelExportButton: 'Excel',
    _pdfExportButton: 'PDF',
    _deleteConfirm: 'Weet je zeker dat je deze bestelling wilt verwijderen: ',
  },
  serviceOrdersGrid: {
    _updated: 'Tijd',
    _address: 'Adres',
    _executed: 'Uitgevoerd',
    _unit: 'Eenheid',
    _orderItemType: 'Type',
    _deviation: 'Afwijking',
    _externalOrderId: 'GLN',
    _sequence: 'Volgorde',
    _agreementCompanyName: 'Klant',
    _estimatedArrivalTime: 'Verwacht',
  },
  serviceOrderDetails: {
    _serviceOrders: 'Werkorders',
  },
  serviceOrderDetailsGrid: {
    _agreementCompanyName: 'Klant',
    _address: 'Adres',
    _externalOrderId: 'GLN',
    _sequence: 'Volgorde',
    _estimatedArrivalTime: 'Verwacht',
    _description: 'Beschrijving',
    _listDescription: 'Lijstbeschrijving',
    _executed: 'Uitgevoerd',
    _vehicleId: 'Voertuig',
    _updated: 'Uitvoertijd',
    _unitName: 'Eenheid',
    _orderItemType: 'Type',
    _time: 'Tijd',
  },
  containerOrders: {
    _noOrders: 'Geen bestellingen gepland.',
    _addVehicle: 'Voeg voertuig toe',
    _cancel: 'Annuleren',
    _save: 'Voertuig toewijzen',
    _addVehicleSuccess: 'Voertuig toegevoegd',
    _addVehicleFailure: 'Voertuig is niet ingesteld',
  },
  containerOrdersGrid: {
    _orderId: 'Bestel ID',
    _expires: 'Verloopt',
    _date: 'Datum',
    _routeName: 'Route',
    _address: 'Adres',
    _unitName: 'Eenheid',
    _orderTypeName: 'Type',
    _fraction: 'Fractie',
    _vehicleId: 'Voertuig',
  },
  customerSearchGrid: {
    _id: 'Id',
    _system: 'Systeem',
    _municipality: 'Gemeente',
    _name: 'Naam',
    _address: 'Adres',
    _zipCode: 'Postcode',
    _type: 'Profieltype',
    _unitDesrciption: 'Eenheidstype',
    _sendMessage: 'Stuur directe bericht',
  },
  containerOrderDetails: {
    _unitName: 'Eenheid',
    DetailData_Name: 'Naam',
    DetailData_Address1: 'Adres 1',
    DetailData_Address2: 'Adres 2',
    DetailData_PostalCode: 'Postcode',
    DetailData_Other: 'Overig',
    DetailData_Message: 'Opmerking',
    DetailData_OrderId: 'Bestel id',
    DetailData_Expires: 'Verloopt',
    DetailData_LastDate: 'Laatste datum',
    DetailData_LastVolume: 'Volume',
    DetailData_MessageSeptic: 'Opmerking',
    DetailData_Material: 'Materiaaltype',
    DetailData_NumberOfChambers: 'Aantal kamers',
    DetailData_TubeLength: 'Slanglengte',
    DetailData_ExtOrderId: 'Bestel id',
    DetailData_ExtOrderNr: 'Extern bestelnummer',
    DetailData_Route: 'Route',
    DetailData_RouteName: 'Routenaam',
    DetailData_OrderDate: 'Besteldatum',
    DetailData_OrderType: 'Besteltype',
    DetailData_OrderTypeText: 'Besteltype',
    DetailData_PASystem: 'PASysteem',
    DetailData_AgreementLines: 'Overeenkomstregels',
    DetailData_RoutelineId: 'Routelijn-id',
    AgreementLineDetailData_PASystem: 'PASysteem',
    AgreementLineDetailData_AgreementId: 'Overeenkomst-id',
    AgreementLineDetailData_AgreementLineId: 'Overeenkomstregel-id',
    AgreementLineDetailData_UnitId: 'Eenheid-id',
    AgreementLineDetailData_Amount: 'Bedrag',
    AgreementLineDetailData_PhysicalAmount: 'Fysiek bedrag',
    AgreementLineDetailData_Message: 'Plaatsing',
    AgreementLineDetailData_ExtOrderId: 'Bestel id',
    AgreementLineDetailData_LastChanged: 'Laatst gewijzigd',
    AgreementLineDetailData_DegLat: 'Breedtegraad',
    AgreementLineDetailData_DegLon: 'Lengtegraad',
    AgreementLineDetailData_ContainerId: 'Container-id',
    AgreementLineDetailData_Fraction: 'Fractie',
    AgreementDetailData_PASystem: 'PASysteem',
    AgreementDetailData_AgreementId: 'Overeenkomst-id',
    AgreementDetailData_Route: 'Route',
    AgreementDetailData_CustomerId: 'Klant-id',
    AgreementDetailData_Name: 'Naam',
    AgreementDetailData_ContactName: 'Contactnaam',
    AgreementDetailData_Address1: 'Adres 1',
    AgreementDetailData_Address2: 'Adres 2',
    AgreementDetailData_PostalCode: 'Postcode',
    AgreementDetailData_City: 'Stad',
    AgreementDetailData_Other: 'Overig',
    AgreementDetailData_Message: 'Opmerking',
    AgreementDetailData_Type: 'Type',
    AgreementDetailData_LastChanged: 'Laatst gewijzigd',
  },
  impersonationManager: {
    _selectUser: 'Kies klant/gebruiker',
    _noOptionsMessage: 'Geen gebruikers gevonden',
    _impersonate: 'Gebruik',
    _stopImpersonation: 'Stop met gebruiken',
    _selectUserPlaceholder: 'Kies klant/gebruiker...',
  },
  userAdmin: {
    _noUsers: 'Geen gebruikers beschikbaar',
    _userName: 'E-mail',
    _system: 'Systeem',
    _contractor: 'Aannemer',
    _contractorId: 'Transporteur',
    _localAdmin: 'Lokaal beheerder',
    _admin: 'Beheerder',
    _modules: 'Modulen',
    _add: 'Toevoegen',
    _update: 'Bijwerken',
    _cancel: 'Annuleren',
    _newUser: 'Nieuwe gebruiker toevoegen',
    _userEditDialogTitle: 'Bewerk gebruiker',
    _userAddDialogTitle: 'Nieuwe gebruiker toevoegen',
    _password: 'Wachtwoord',
    _passwordConfirmation: 'Bevestig wachtwoord',
    _confirmPasswordError: 'Wachtwoorden moeten overeenkomen',
    _noCustomerOptionsMessage: 'Geen klanten gevonden',
    _noContractorOptionsMessage: 'Geen aannemers gevonden',
    _contractorsLoadingMessage: 'Aannemers worden geladen',
    _noModuleOptionsMessage: 'Geen modules gevonden',
    _modulesLoadingMessage: 'Modules worden geladen',
    _customerSelectorPlaceholder: 'Kies klant...',
    _impersonationWarningPart1: 'Je bent ingelogd als ',
    _impersonationWarningPart2:
      ', en hebt geen rechten om deze pagina te gebruiken. Klik op de knop hieronder om terug te gaan naar de beheerdersfunctie.',
  },
  vehicles: {
    _noVehicles: 'Geen voertuigen beschikbaar',
    _vehicles: 'Voertuigen',
    _system: 'Systeem',
    _DTAShort: 'DTA',
    _DTALong: 'Digitale Truck Assistant',
    _ajourShort: 'Ajour',
    _ajourLong: 'GPS Ajour',
    _vehicleId: 'Voertuig',
    _externalId: 'Extern Id',
    _description: 'Beschrijving',
    _phoneNr: 'SIM Nr',
    _driver: 'Chauffeur',
    _password: 'Wachtwoord',
    _repeatPassword: 'Herhaal wachtwoord',
    _edit: 'Bewerken',
    _cancel: 'Annuleren',
    _update: 'Bijwerken',
    _submit: 'Opslaan',
    _addNewVehicle: 'Voeg nieuw voertuig toe',
    _editMessageSuccessful: 'Voertuig bijgewerkt',
    _addMessageSuccessful: 'Voertuig toegevoegd',
    _editMessageFailed: 'Fout bij het bijwerken van voertuig',
    _addMessageFailed: 'Fout bij het toevoegen van voertuig',
    _vehicleAlreadyExistingError: 'Een voertuig met dezelfde naam bestaat al',
    _validationError: 'Alleen cijfers en letters zijn toegestaan',
    _contractorSelectorPlaceholder: 'Kies aannemer...',
    _noContractorFound: 'Geen aannemers beschikbaar',
    _vehicleLoadingMessage: 'Voertuigen worden geladen',
  },
  vehicleSettings: {
    _copyFromVehicleButton: 'Kopieer van...',
    _copyFromVehicleDialogTitle: 'Kopieer instellingen van gebruiker',
    _copyButton: 'Kopieer',
    _copyFromVehicleError: 'Fout bij het kopiëren van gebruikersinstellingen',
    _copyFromVehicleSuccess:
      'Instellingen zijn gekopieerd van de geselecteerde gebruiker',
    _noSettings: 'Geen instellingen beschikbaar',
    _parameter: 'Parameter',
    _value: 'Waarde',
    _addToMultiple: 'Aan meerdere voertuigen toevoegen?',
    _description: 'Beschrijving',
    _vehicle: 'Voertuig',
    _edit: 'Bewerken',
    _cancel: 'Annuleren',
    _multiselectError: 'Selecteer minimaal één voertuig',
    _update: 'Bijwerken',
    _submit: 'Opslaan',
    _addNewSetting: 'Voeg nieuwe instelling toe',
    _editMessageSuccessful: 'Gebruikersinstelling bijgewerkt',
    _addMessageSuccessful: 'Gebruikersinstelling toegevoegd',
    _deleteMessageSuccessful: 'Gebruikersinstelling verwijderd',
    _editMessageFailed: 'Fout bij het wijzigen van gebruikersinstelling',
    _addMessageFailed: 'Fout bij het toevoegen van gebruikersinstelling',
    _deleteMessageFailed: 'Fout bij het verwijderen van gebruikersinstelling',
    _deleteConfirm:
      'Weet je zeker dat je deze gebruikersinstelling wilt verwijderen',
    _vehicleSelectorPlaceholder: 'Kies voertuig...',
    _selectVehicle: 'Voertuig',
    _selectCustomer: 'Klant',
    _selectContractor: 'Aannemer',
    _settingTemplateSelectorPlaceholder: 'Kies instellingstype...',
    _noSettingTemplateOptionsMessage: 'Geen instellingen gevonden',
    _cannotAddNewSettingMessage:
      'Kan geen instellingen toevoegen. Alle instellingen zijn al aanwezig.',
    _failedToLoadSettingTempletes:
      'Kan instellingen niet wijzigen of toevoegen. Standaardinstellingen ontbreken.',
  },
  map: {
    _trackingLineLayer: 'Trackinglijn',
    _trackInfo: 'GPS-informatie',
    _trackInfoLine: 'GPS Sporlogboek',
    _trackInfoPointsAndLines: 'Sporlogboek',
    _streetLayer: 'Basiskaart',
    _satelliteLayer: 'Luchtfoto',
    _propertyLayer: 'Kadastrale weergave',
    _wmsLayer: 'WMS',
    _nWMSLayersSelected: '{0} Gebied(en) geselecteerd',
    _lastUpdated: 'Bijgewerkt',
    _trackingLineLegend: {
      _belowSix: '< 6 uur',
      _sixToTwelve: '6-12 uur',
      _twelveToTwentyFour: '12-24 uur',
      _overTwentyFour: '> 24 uur',
      _gray: 'Onbekend',
    },
  },
  contractors: {
    _contractors: 'Aannemers',
    _name: 'Naam van de aannemer',
    _externalId: 'Externe aannemer-id',
    _addContractor: 'Voeg nieuwe aannemer toe',
    _deleteConfirm: 'Weet je zeker dat je deze aannemer wilt verwijderen: ',
    _deleteSuccess: 'Aannemer verwijderd',
    _addSuccess: 'Aannemer toegevoegd',
    _editSuccess: 'Aannemer bijgewerkt',
  },
  deviations: {
    _deviations: 'Afwijkingen',
    _deviation: 'Afwijking',
    _deviatonExtId: 'Afwijking externe Id',
    _category: 'Categorie',
    _deleteConfirm: 'Weet je zeker dat je deze afwijking wilt verwijderen: ',
    _addNew: 'Voeg afwijking toe',
    _addSuccess: 'Afwijking toegevoegd',
    _editSuccess: 'Afwijking bijgewerkt',
    _deleteSuccess: 'Afwijking verwijderd',
    _selectCategory: 'Selecteer afwijkingscategorie',
    _noCategory: 'Geen afwijkingscategorie gevonden',
    _deleteCategoryConfirm:
      'Weet je zeker dat je deze afwijkingscategorie wilt verwijderen: ',
    _categoryDeleteSuccess: 'Afwijkingscategorie verwijderd',
    _deviationCategory: 'Afwijkingscategorieën',
    _categoryName: 'Naam van afwijkingscategorie',
    _addCategorySuccess: 'Afwijkingscategorie toegevoegd',
    _editCategorySuccess: 'Afwijkingscategorie bijgewerkt',
    _addNewCategory: 'Voeg nieuwe afwijkingscategorie toe',
  },
  customerRegister: {
    message: {
      _directMessageToCustomer: 'Directe boodschap naar klant',
      _address: 'Adres',
      _sender: 'Afzender',
      _expires: 'Verloopt',
      _vehicle: 'Voertuig',
      _selectVehicle: 'Kies voertuig',
      _title: 'Titel',
      _message: 'Boodschap',
      _send: 'Verzenden',
      _messageSent: 'Boodschap verzonden',
      _noVehicle: 'Geen voertuig',
    },
    grid: {
      _infoText:
        'Hier kunt u de klant zoeken en een directe bericht over de klant naar het voertuig sturen.',
    },
  },
  directMessages: {
    _messages: 'Berichten',
    _address: 'Adres',
    _sender: 'Afzender',
    _expires: 'Verloopt',
    _vehicle: 'Voertuig',
    _title: 'Titel',
    _message: 'Boodschap',
    _date: 'Datum',
    _closeButton: 'Sluiten',
    _sent: 'Verzonden',
    _received: 'Verwerkt',
    _read: 'Ontvangen',
    _status: 'Status',
    _newMessage: 'Nieuw bericht',
  },
};
