import React, { Component, ReactNode } from 'react';
import { IUser, IVehicle } from '../../models';
import { Toastr } from '../../utils/Toastr';
import { Button, Modal } from 'react-bootstrap';
import { debounceEventHandler } from '../shared/submitFormUtility';
import VehicleSelector from '../userAdmin/VehicleSelector';
import ContainerOrderService from './ContainerOrderService';
import { locale } from '../../common/localization/localizationService';
import { filterVehicles } from '../vehicles/vehicleUtils';

export interface IAddVehicleDialogProps {
  vehicles: Array<IVehicle>;
  containerOrderId: number;
  show: boolean;
  onHide: (rerender: boolean) => void;
  user: IUser;
  selectedVehicleId: string;
}

export interface IAddVehicleDialogState {
  selectedVehicleId: string;
  selectedVehicleContractorId: number;
}

export interface IAddVehicle {
  contractorOrderId: number;
  vehicleId: string;
}

export default class AddVehicleDialog extends Component<
  IAddVehicleDialogProps,
  IAddVehicleDialogState
> {
  public readonly state: IAddVehicleDialogState = {
    selectedVehicleId: this.props.selectedVehicleId,
    selectedVehicleContractorId: null,
  };
  private onSubmit = async (): Promise<void> => {
    try {
      const success = await ContainerOrderService.addVehicleToContainerOrder(
        this.props.user.customerId,
        this.props.containerOrderId,
        this.state.selectedVehicleId,
        this.state.selectedVehicleContractorId
      );

      if (success) {
        Toastr.success(locale.containerOrders._addVehicleSuccess);
      } else {
        Toastr.error(locale.containerOrders._addVehicleFailure);
      }
    } catch (error) {
      Toastr.error(error);
    } finally {
      this.props.onHide(true);
    }
  };

  private handleVehicleChanged = (
    selectedVehicle: Record<string, IVehicle>
  ) => {
    this.setState({
      ...this.state,
      selectedVehicleId: selectedVehicle.value.vehicleId,
      selectedVehicleContractorId: selectedVehicle.value.contractorId,
    });
  };

  private filterVehiclesIfNeeded(vehicles: Array<IVehicle>, user: IUser): Array<IVehicle> 
  {
    let filteredVehicles: Array<IVehicle> = [];
    if (vehicles?.length) {
      if(user.impersonatedUser){
        return vehicles;
      }

      const customerId = user.customerId;
      if (user.isAdmin) {
        filteredVehicles = filterVehicles(vehicles, { customerId });
      } else {
        const contractorId = user.contractorId;
        filteredVehicles = filterVehicles(vehicles, {
          customerId,
          contractorId,
        });
      }
      return filteredVehicles;
    } else {
      return filteredVehicles;
    }
  }

  public render(): ReactNode {
    const { show, onHide, vehicles, user} = this.props;
    return (
      <>
        <Modal
          show={show}
          onHide={() => onHide(false)}
          className="user-edit-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{locale.containerOrders._addVehicle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <VehicleSelector
              handleChange={this.handleVehicleChanged}
              vehicles={this.filterVehiclesIfNeeded(vehicles, user)}
              selectedVehicleId={this.state.selectedVehicleId}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => onHide(false)}>
              {locale.containerOrders._cancel}
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={debounceEventHandler(this.onSubmit, 250)}
              disabled={!this.state.selectedVehicleId}
            >
              {locale.containerOrders._save}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
