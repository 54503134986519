export const localeNo = {
  general: {
    _administration: 'Administrasjon',
    _username: 'Brukernavn',
    _password: 'Passord',
    _date: 'Dato',
    _time: 'Tid',
    _orderOverview: 'Ordreoversikt',
    _reports: 'Rapporter',
    _kg: 'kg',
    _ton: 'tonn',
    _callOrder: 'Anropsordrer',
    _serviceList: 'Arbeidslister',
    _impersonationManager: 'Sett kunde/bruker',
    _userAdmin: 'Brukeradministrering',
    _routeAdmin: 'Ruter',
    _backendError: 'Det oppstod en feil under forespørselen',
    _accessError: 'Du har ikke tilgang til denne siden',
    _vehicles: 'Biler',
    _vehicleSettings: 'Administrative Innstillinger',
    _zoomIn: 'Zoom inn',
    _zoomOut: 'Zoom ut',
    _warning: 'Advarsel!',
    _customerRegister: 'Søk i kunderegister',
    _commune: 'Kommune',
    _logOut: 'Logg ut',
    _selectLanguage: 'Velg språk',
    _unitDesrciption: 'Enhetstype',
    _fullDateFormat: 'dd.MM.yyyy',
    _fullDateWithTime: 'dd.MM.yyyy HH:mm:ss',
    _fullMomentFormat: 'DD.MM.YYYY',
    _fullMomentWithTime: 'DD.MM.YYYY HH:mm:ss',
    _noRecords: 'Ingen rekord tilgjengelig.',
  },
  login: {
    _usernameEmail: 'Epost',
    _usernameRequired: 'Brukernavn er påkrevd!',
    _passwordRequired: 'Passord er påkrevd!',
    _usernamePasswordInvalid: 'Feil brukernavn eller passord',
    _invalidEmail: 'Brukernavnet er ikke en gyldig e-postadresse!',
    _submit: 'Logg Inn',
    _welcome: 'Velkommen til GPS Realtime WMS',
    _pressLogin: 'Trykk Logg inn for å logge inn till portaie',
    _ifAssistance: 'Ved behov for bistand,',
    _pleaseContact: 'ta gjerne kontakt med var support',
  },
  tooltip: {
    _deviceId: 'Enhetsnummer',
    _altitude: 'Høyde',
    _speed: 'Hastighet',
    _temperature: 'Temperatur',
    _orderId: 'Ordrenr',
    _address: 'Adresse',
    _routeName: 'Rute',
  },
  orders: {
    _update: 'Oppdatert',
    _start: 'Start',
    _route: 'Rute',
    _vehicleId: 'Bil',
    _orderId: 'Ordre',
    _status: 'Status',
    _notStarted: 'Ikke kjort',
    _now: 'Nå',
    _clearFilter: 'Fjern filter',
    _fraction: 'Fraksjon',
    _showFilters: 'Vis filtre',
    _chooseContractor: 'Velg transportør',
    _chooseFraction: 'Velg fraksjon',
    _expires: 'Utgår',
    _date: 'Dato',
    _ordinaryDate: 'Ordinær dato',
    _earlierDateValidation:
      'Utløpsdatoen kan ikke være tidligere enn startdatoen',
    _fractions: 'Fraksjoner',
    _selectFractions: 'Velg fraksjoner',
    _noFractions: 'Ingen fraksjoner',
    _updateOrder: 'Oppdater rekkefølgen',
    _updateSuccess: 'Ordre er oppdatert',
    _fractionsLoading: 'Fraksjoner lastes inn',
    _notEditableWarning: 'Når ruten har startet, kan bare utløpsdatoen endres.',
    status: {
      _noDone: 'Ikke, kjørt',
      _okDone: 'Ok, kjørt',
      _downloaded: 'Nedlastet',
      _started: 'Startet',
      _backOrder: 'Restordre',
      _localBackOrder: 'Lokal restordre',
      _partiallyStarted: 'Delvis startet',
      _partiallyCompleted: 'Delvis fullført',
      _rejected: 'Avvist',
      _planned: 'Planlagt',
      _deleted: 'Slettet',
    },
  },
  orderDetails: {
    _address: 'Adresse',
    _unit: 'Enhet',
    _executed: 'Utført',
    _vehicleId: 'Bil',
    _driver: 'Sjåfør',
    _time: 'Tid',
    _deviation: 'Avvik',
    _orderDoesNotExist: 'Ordren eksisterer ikke',
    _companyName: 'Kunde',
    _pictureTakenText: 'bilder',
    _sequence: 'Sekvens',
  },
  orderStatuses: {
    _started: 'Startet',
    _done: 'OK, kjørt',
    _partiallyDone: 'Delvis fullført',
  },
  orderDetailsDiagram: {
    _executionStatusHeader: 'Utført (Ja / Nei)',
    _deviationsHeader: 'Avvik / Merknad',
    _noDeviations: 'Ingen avvik / merknader',
  },
  routeStopDetails: {
    _routeStopDoesNotExist: 'Rutestoppet eksisterer ikke',
    _departureTime: 'Tid avgang',
    _arrivalTime: 'Tid ankomst',
    _address: 'Adresse',
    _agreementId: 'AvtNr',
    _unit: 'Enhet',
    _agreementLineId: 'BehNr',
    _executed: 'Utført',
    _externalOrderId: 'OrdreNr',
    _vehicleId: 'Bil',
    _driver: 'Sjåfør',
    _orderData: 'Ordredata',
    _pictures: 'Bilder',
    _deviation: 'Avvik / Merknad',
    _comment: 'Kommentar',
  },
  routeAdminPage: {
    _selectRoute: 'Velg rute(r)',
    _refresh: 'Oppdater',
    _withoutPosition: 'Uten posisjon',
    _sequence: 'Sekvens',
    _description: 'Beskrivelse',
    _routeLineId: 'Stoppepunkt',
    _agreementId: 'Avtale id',
    _name: 'Navn',
    _estate: 'Eiendom',
    _unitName: 'Vare',
    _agreementLineId: 'Avtalelinje id',
    _physicalAmount: 'Antall',
    _route: 'Rute',
    _degLat: 'Breddegrad',
    _degLong: 'Lengdegrad',
    _invalidSequence: 'Ugyldig sekvensnummer',
    _changeSequence: 'Endre sekvens',
    _cancelButton: 'Avbryt',
    _submitButton: 'Lagre',
    _validateButton: 'Validere',
    _restoreButton: 'Avbryt',
    _locationChangeTitle: 'Er du sikker på at du ønsker å lagre?',
    _locationChangeContent: 'Dine endringer vil nå bli lagret',
    _splitStoppoint: 'Splitt stoppepunkt',
    _splitModalPlaceholder:
      'Dra en kunde hit for å opprette et nytt stoppepunkt',
    _splitModalErrorMessage: 'Du må minst opprette 2 stoppepunkt',
    _mergeModalTitle: 'Slå sammen stoppepunktene',
    _mergeModalContent: 'Valgte stoppepunktene',
    _selectOnlyOne: 'Vennligst velg kun 1 stoppepunkt',
    _selectAtLeastOne: 'Minst 1 stoppepunkt må velges',
    _selectAtLeastTwo: 'Minst 2 stoppepunkt må velges',
    _mergeStoppoints: 'Slå sammen hentested',
    _sequenceEdit: 'Endre sekvens',
    _savePosition: 'Lagre posisjon',
    _changePosition: 'Endre posisjon',
    _changePositionTooltip: 'Endre posisjon for valgt stoppepunkt',
    _agreementGid: 'GnrBnrFnrSnr',
    _placeId: 'HentestedsID',
    _address: 'Adresse',
    _customerName: 'Kundenavn',
    _propertyLinesWarning: 'Noen av punktene kan vises utenfor kartet.',
    _propertyLinesCustom: 'Vis kun punkter med koordinat.',
    _propertyLinesAll: 'Vis alle punkter.',
    _changesHeader: 'Følgende sekvensnumre blir berørt',
    _changedFrom: 'endret fra',
    _changedTo: 'til',
    _notInNorwayErrorMessage: 'Posisjonen du satte er utenfor Norge',
    _alreadyMerged: 'Markerte avtaler er allerede slått sammen',
    _mergeModalTargetText: 'Velg stoppepunktets visning/adresse',
    _mergeModalTargetDropdownText: 'Velg hvilken visning/adresse',
    _setPosition: 'Angi posisjon',
    _setPositionTooltip: 'Angi posisjon for valgt stoppepunkt',
    _noRecords: 'Ingen rekord tilgjengelig.',
    _isLoadingMessage: 'Poster lastes inn.',
    _sequenceSuccessfullyChanged: 'Sekvensen er oppdatert',
    _remove: 'Fjern',
    _selection: 'valg',
    _hideWithoutAgreement: 'Skjule uten avtale',
  },
  reports: {
    _exportTitle: 'Eksporter til Excel-fil',
    _nothingToExportTitle: 'Ingen data å eksportere',
    _showReport: 'Vis rapport',
    _fromDate: 'Fra dato',
    _toDate: 'Til dato',
    _selectReport: 'Velg rapport',
    _reportEmpty: 'Ingen rekord tilgjengelig',
    _header: 'Mine rapporter',
    _summary:
      'Her har vi samlet alle deres rapporter. Rapporter kan bestilles hos våre konsulenter. Ta kontakt med oss på epost {mailto}.',
    _export: 'Eksport',
  },
  serviceLists: {
    _noOrders: 'Ingen ordrer planlagt.',
    _delete: 'Slett',
    _add: 'Legg til',
    _deleteConfirm: 'Er du sikker på at du vil slette denne arbeidsliste: ',
  },
  routeOptimalizationWizard: {
    _title: 'Lag kjørelister',
    _uploadFileStep: 'Last inn fil',
    _uploadFileButton: 'Velg fil',
    _analyseStep: 'Analyser',
    _analyseButton: 'Kjør analyse',
    _saveStep: 'Kjørelister',
    _saveButton: 'Opprett kjørelister',
    _downloadTemplateButton: 'Last ned importmal',
    _importedRouteTabTitle: 'Ordregrunnlag',
    _worklistsTabTitle: 'Kjørelister',
    _unassignedStopsTabTitle: 'Ordrer ikke inkludert',
    _noRoutesImported: 'Ingen ordre, last inn fil',
    _worklistEmpty: 'Kjørelisten er tom',
    _worklistOptionText: 'Kjørerute',
    _addCustomer: 'Legg til kunde',
    _duplicateRoutesWarning: 'Det finnes dupliate ordrer i ordregrunnlaget',
    _noFileSelectedTitle: 'Ingen fil valgt!',
    _noFileSelectedDescription:
      'Last opp ordregrunnlag liste filen med følgende importmal:',
    importManager: {
      _unknownParseError: 'Ukjent feil oppstod',
      _fileLoadResultsModalTitle: 'Filinnlasting resultater',
      _fileLoadSuccess: 'Filinnlasting vellykket!',
      _fileLoadModalCloseButton: 'Lukk',
      _agreementNotFound: 'Kunder ikke funnet for kundenr',
      _wrongFileFormat: 'Feil filformat. Last ned importmal',
      _fileEmpty: 'Filen inneholder ingen data',
    },
    addCustomerModal: {
      _title: 'Legg til kunde',
      _closeButton: 'Lukk',
      _addButton: 'Legg til',
      _dateLabel: 'Dato',
      _agreementLabel: 'Kunde',
      _descriptionLabel: 'Beskrivelse',
      _serialLabel: 'Løpenummer',
      _noOptionsMessage: 'Ingen kunder funnet',
      _loadingMessage: 'Kunder lastes inn',
      _agreementSelector: 'Velg kunde',
      _agreementSelectorPlaceholder: 'Velg kunde...',
    },
    routeAnalysisModal: {
      _title: 'Analyser ordregrunnlag',
      _closeButton: 'Lukk',
      _cancelButton: 'Avbryt',
      _analyseButton: 'Analyser',
      _selectStartAndStop: 'Velg start og stoppested',
      _workTime: 'Tid effektiv arbeidsdag',
      _timePerStop: 'Tid brukt pr. stopp (minutter)',
      _maximumCars: 'Maks antall biler i analyse',
      _startStopLabel: 'Start og stoppested',
      _workHoursLabel: 'Timer',
      _workMinutesLabel: 'Minutter',
      _timePerStopLabel: 'Tid brukt',
      _maximumCarsLabel: 'Maks antall biler',
      _noDepotOptionsMessage: 'Ingen depoter funnet',
      _depotsLoadingMessage: 'Depoter lastes inn',
      _depotSelectorPlaceholder: 'Velg depot...',
      _analysationError: 'Feil ved analyse av ordregrunnlaget',
    },
    routesGrid: {
      _date: 'Dato',
      _gln: 'GLN',
      _customerName: 'Kundenavn',
      _description: 'Beskrivelse',
      _serial: 'Løpenummer',
    },
    worklistResults: {
      _sequence: 'Sekvens',
      _arrivalTime: 'Tid',
      _gln: 'GLN',
      _customerName: 'Kundenavn',
      _description: 'Beskrivelse',
      _serial: 'Løpenummer',
      _unassignedWarning: 'ordrer ikke inkludert!',
      worklistSummary: {
        _totalTime: 'Total tid',
      },
      moveWorklistItemModal: {
        _title: 'Flytt til',
        _targetListLabel: 'Kjøreliste',
        _closeButton: 'Lukk',
        _moveButton: 'Flytt til',
      },
    },
    createWorklistsModal: {
      _title: 'Opprett kjørelister',
      _closeButton: 'Lukk',
      _saveButton: 'Opprett',
      _itemCount: 'Antall',
      _listName: 'List navn',
      _saveError: 'Feil ved opprett kjørelister',
    },
  },
  dateRangePicker: {
    _thisWeek: 'Denne uke',
    _lastWeek: 'Forrige uke',
    _thisMonth: 'Denne måneden',
  },
  serviceListsGrid: {
    _listName: 'Liste navn',
    _vehicleId: 'Bil',
    _updated: 'Oppdatert',
    _started: 'Start',
    _listDate: 'Dato',
  },
  serviceOrders: {
    _noOrders: 'Ingen ordrer planlagt.',
    _excelExportButton: 'Excel',
    _pdfExportButton: 'PDF',
    _deleteConfirm: 'Er du sikker på at du vil slette denne ordre: ',
  },
  serviceOrdersGrid: {
    _updated: 'Tid',
    _address: 'Adresse',
    _executed: 'Utført',
    _unit: 'Enhet',
    _orderItemType: 'Type',
    _deviation: 'Avvik',
    _externalOrderId: 'GLN',
    _sequence: 'Sekvens',
    _agreementCompanyName: 'Kunde',
    _estimatedArrivalTime: 'Planlagt',
  },
  serviceOrderDetails: {
    _serviceOrders: 'Arbeidsordrer',
  },
  serviceOrderDetailsGrid: {
    _agreementCompanyName: 'Kunde',
    _address: 'Adresse',
    _externalOrderId: 'GLN',
    _sequence: 'Sekvens',
    _estimatedArrivalTime: 'Planlagt',
    _description: 'Beskrivelse',
    _listDescription: 'Listebeskrivelse',
    _executed: 'Utført',
    _vehicleId: 'Bil',
    _updated: 'Utført tidstpunkt',
    _unitName: 'Enhet',
    _orderItemType: 'Type',
    _time: 'Tid',
  },
  containerOrders: {
    _noOrders: 'Ingen ordrer planlagt.',
    _addVehicle: 'Tilordne bil til ordre',
    _cancel: 'Avbryt',
    _save: 'Tilordne bil',
    _addVehicleSuccess: 'Bilen ble tilordnet ordren.',
    _addVehicleFailure: 'Kunne ikke tilordne bil til ordren',
  },
  containerOrdersGrid: {
    _orderId: 'Ordre ID',
    _expires: 'Utgår',
    _date: 'Dato',
    _routeName: 'Rute',
    _address: 'Adresse',
    _unitName: 'Enhet',
    _orderTypeName: 'Type',
    _fraction: 'Fraksjon',
    _vehicleId: 'Bil',
  },
  customerSearchGrid: {
    _id: 'Id',
    _system: 'System',
    _municipality: 'Kommune',
    _name: 'Navn',
    _address: 'Adresse',
    _zipCode: 'PostNr',
    _type: 'Profiltype',
    _unitDesrciption: 'Enhetstype',
    _sendMessage: 'Send direktemelding',
  },
  containerOrderDetails: {
    _unitName: 'Enhet',
    DetailData_Name: 'Navn',
    DetailData_Address1: 'Adresse 1',
    DetailData_Address2: 'Adresse 2',
    DetailData_PostalCode: 'Postnummer',
    DetailData_Other: 'Annet',
    DetailData_Message: 'Kommentar',
    DetailData_OrderId: 'Ordre id',
    DetailData_Expires: 'Utgår',
    DetailData_LastDate: 'Siste dato',
    DetailData_LastVolume: 'Volum',
    DetailData_MessageSeptic: 'Merknad',
    DetailData_Material: 'Materialtype',
    DetailData_NumberOfChambers: 'Ant.kammer',
    DetailData_TubeLength: 'Slangemeter',
    DetailData_ExtOrderId: 'Order id',
    DetailData_ExtOrderNr: 'External OrderNr',
    DetailData_Route: 'Route',
    DetailData_RouteName: 'RouteName',
    DetailData_OrderDate: 'Orderdate',
    DetailData_OrderType: 'OrderType',
    DetailData_OrderTypeText: 'Ordertype',
    DetailData_PASystem: 'PASystem',
    DetailData_AgreementLines: 'AgreementLines',
    DetailData_RoutelineId: 'RoutelineId',
    AgreementLineDetailData_PASystem: 'PASystem',
    AgreementLineDetailData_AgreementId: 'AgreementId',
    AgreementLineDetailData_AgreementLineId: 'AgreementLineId',
    AgreementLineDetailData_UnitId: 'UnitId',
    AgreementLineDetailData_Amount: 'Amount',
    AgreementLineDetailData_PhysicalAmount: 'PhysicalAmount',
    AgreementLineDetailData_Message: 'Plassering',
    AgreementLineDetailData_ExtOrderId: 'Order id',
    AgreementLineDetailData_LastChanged: 'Last changed',
    AgreementLineDetailData_DegLat: 'Latitude',
    AgreementLineDetailData_DegLon: 'Longitude',
    AgreementLineDetailData_ContainerId: 'ContainerId',
    AgreementLineDetailData_Fraction: 'Fraction',
    AgreementDetailData_PASystem: 'PASystem',
    AgreementDetailData_AgreementId: 'AgreementId',
    AgreementDetailData_Route: 'Route',
    AgreementDetailData_CustomerId: 'CustomerId',
    AgreementDetailData_Name: 'Name',
    AgreementDetailData_ContactName: 'ContactName',
    AgreementDetailData_Address1: 'Addresse1',
    AgreementDetailData_Address2: 'Addresse2',
    AgreementDetailData_PostalCode: 'PostalCode',
    AgreementDetailData_City: 'City',
    AgreementDetailData_Other: 'Other',
    AgreementDetailData_Message: 'Message',
    AgreementDetailData_Type: 'Type',
    AgreementDetailData_LastChanged: 'Last changed',
  },
  impersonationManager: {
    _selectUser: 'Velg kunde/bruker',
    _noOptionsMessage: 'Ingen brukere funnet',
    _impersonate: 'Bruk',
    _stopImpersonation: 'Stopp bruk',
    _selectUserPlaceholder: 'Velg kunde/bruker...',
  },
  userAdmin: {
    _noUsers: 'Ingen brukere tilgjengelig',
    _userName: 'Epost',
    _system: 'System',
    _contractor: 'Entreprenør',
    _contractorId: 'Transportør',
    _localAdmin: 'Lokal admin',
    _admin: 'Admin',
    _modules: 'Moduler',
    _add: 'Legg til',
    _update: 'Oppdater',
    _cancel: 'Avbryt',
    _newUser: 'Legg til ny bruker',
    _userEditDialogTitle: 'Rediger bruker',
    _userAddDialogTitle: 'Legg til ny bruker',
    _password: 'Passord',
    _passwordConfirmation: 'Bekreft passord',
    _confirmPasswordError: 'Passordene må være like',
    _noCustomerOptionsMessage: 'Ingen kunder funnet',
    _noContractorOptionsMessage: 'Ingen entreprenører funnet',
    _contractorsLoadingMessage: 'Entreprenører lastes inn',
    _noModuleOptionsMessage: 'Ingen moduler funnet',
    _modulesLoadingMessage: 'Moduler lastes inn',
    _customerSelectorPlaceholder: 'Velg kunde...',
    _impersonationWarningPart1: 'Du er innlogget som ',
    _impersonationWarningPart2:
      ', og mangler rettigheter til å bruke denne siden. Trykk på knappen under for å gå tilbake til administratorfunksjon.',
  },
  vehicles: {
    _noVehicles: 'Ingen biler tilgjengelig',
    _vehicles: 'Biler',
    _system: 'System',
    _DTAShort: 'DTA',
    _DTALong: 'Digital Truck Assistant',
    _ajourShort: 'Ajour',
    _ajourLong: 'GPS Ajour',
    _vehicleId: 'Bil',
    _externalId: 'Ekstern Id',
    _description: 'Beskrivelse',
    _phoneNr: 'SIM Nr',
    _driver: 'Sjåfør',
    _password: 'Passord',
    _repeatPassword: 'Gjenta passord',
    _edit: 'Endre',
    _cancel: 'Avbryt',
    _update: 'Oppdater',
    _submit: 'Lagre',
    _addNewVehicle: 'Legg til ny bil',
    _editMessageSuccessful: 'Bil oppdatert',
    _addMessageSuccessful: 'Bil lagt til',
    _editMessageFailed: 'Feilet ved endring av bil',
    _addMessageFailed: 'Feil ved opprettelse av bil',
    _vehicleAlreadyExistingError: 'En bil med samme navn finnes fra før',
    _validationError: 'Bare tall og bokstaver er tillatt',
    _contractorSelectorPlaceholder: 'Velg entreprenør...',
    _noContractorFound: 'Ingen entreprenører tilgjengelig',
    _vehicleLoadingMessage: 'Biler lastes inn',
  },
  vehicleSettings: {
    _copyFromVehicleButton: 'Kopier fra...',
    _copyFromVehicleDialogTitle: 'Kopier innstillinger fra bruker',
    _copyButton: 'Kopier',
    _copyFromVehicleError: 'Feil under kopiering av brukerinnstillinger',
    _copyFromVehicleSuccess: 'Innstillingene er kopiert fra valgt bruker',
    _noSettings: 'Ingen innstillinger tilgjengelig',
    _parameter: 'Parameter',
    _value: 'Verdi',
    _addToMultiple: 'Legg til flere biler?',
    _description: 'Beskrivelse',
    _vehicle: 'Bil',
    _edit: 'Endre',
    _cancel: 'Avbryt',
    _multiselectError: 'Velg minst en bil',
    _update: 'Oppdater',
    _submit: 'Lagre',
    _addNewSetting: 'Legg til ny innstilling',
    _editMessageSuccessful: 'Brukerinnstilling oppdatert',
    _addMessageSuccessful: 'Brukerinnstilling lagt til',
    _deleteMessageSuccessful: 'Brukerinnstilling slettet',
    _editMessageFailed: 'Feil ved endring av brukerinnstilling',
    _addMessageFailed: 'Feil ved opprettelse av brukerinnstilling',
    _deleteMessageFailed: 'Feil ved sletting av brukerinnstilling',
    _deleteConfirm:
      'Er du sikker på at du vil slette denne brukerinnstillingen',
    _vehicleSelectorPlaceholder: 'Velg bil...',
    _selectVehicle: 'Bil',
    _selectCustomer: 'Kunde',
    _selectContractor: 'Entreprenør',
    _settingTemplateSelectorPlaceholder: 'Velg innstilling type...',
    _noSettingTemplateOptionsMessage: 'Ingen innstillinger funnet',
    _cannotAddNewSettingMessage:
      'Kan ikke legge til innstillinger. Alle innstillinger finnes fra før.',
    _failedToLoadSettingTempletes:
      'Kan ikke endre eller legge til innstillinger. Mangler standardinnstillinger.',
  },
  map: {
    _trackingLineLayer: 'Tracking Line',
    _trackInfo: 'GPS info',
    _trackInfoLine: 'GPS Sporlogg',
    _trackInfoPointsAndLines: 'Sporlogg',
    _streetLayer: 'Basiskart',
    _satelliteLayer: 'Flyfoto',
    _propertyLayer: 'Matrikkelvisning',
    _wmsLayer: 'WMS',
    _nWMSLayersSelected: '{0} Område(r) er valgt',
    _lastUpdated: 'Oppdatert',
    _trackingLineLegend: {
      _belowSix: '< 6 timer',
      _sixToTwelve: '6-12 timer',
      _twelveToTwentyFour: '12-24 timer',
      _overTwentyFour: '> 24 timer',
      _gray: 'Ukjent',
    },
  },
  contractors: {
    _contractors: 'Entreprenører',
    _name: 'Entreprenørens navn',
    _externalId: 'Ekstern entreprenør-id',
    _addContractor: 'Legg til ny entreprenør',
    _deleteConfirm: 'Er du sikker på at du vil slette denne entreprenøren: ',
    _deleteSuccess: 'Entreprenør slettet',
    _addSuccess: 'Entreprenør lagt til',
    _editSuccess: 'Entreprenør oppdatert',
  },
  deviations: {
    _deviations: 'Avvik',
    _deviation: 'Avvik',
    _deviatonExtId: 'Avvik ekstern Id',
    _category: 'Kategori',
    _deleteConfirm: 'Er du sikker på at du vil slette dette avviket: ',
    _addNew: 'Legg til avvik',
    _addSuccess: 'Avvik lagt til',
    _editSuccess: 'Avvik oppdatert',
    _deleteSuccess: 'Avvik slettet',
    _selectCategory: 'Velg avvikskategori',
    _noCategory: 'Fant ingen avvikskategori',
    _deleteCategoryConfirm:
      'Er du sikker på at du vil slette denne avvikskategorien: ',
    _categoryDeleteSuccess: 'Avvikskategorien slettet',
    _deviationCategory: 'Avvikskategorier',
    _categoryName: 'Navn på avvikskategori',
    _addCategorySuccess: 'Avvikskategori lagt til',
    _editCategorySuccess: 'Avvikskategori oppdatert',
    _addNewCategory: 'Legg til ny avvikskategori',
  },
  customerRegister: {
    message: {
      _directMessageToCustomer: 'Send melding til bil',
      _address: 'Adresse',
      _sender: 'Avsender',
      _expires: 'Utgår',
      _vehicle: 'Bil',
      _selectVehicle: 'Velg bil',
      _title: 'Tittel',
      _message: 'Melding',
      _send: 'Send melding',
      _messageSent: 'Melding sendt',
      _noVehicle: 'Ingen bil',
    },
    grid: {
      _infoText:
        'Her kan du søke opp kunde og sende direktemelding om kunden til bil',
    },
  },
  directMessages: {
    _messages: 'Meldinger',
    _address: 'Adresse',
    _sender: 'Avsender',
    _expires: 'Meldingsdato',
    _vehicle: 'Bil',
    _title: 'Tittel',
    _message: 'Melding',
    _date: 'Opprettet',
    _closeButton: 'Lukk',
    _sent: 'Sendt',
    _received: 'Mottatt',
    _read: 'Lest',
    _status: 'Status',
    _newMessage: 'Ny melding',
  },
};
