import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import { locale } from '../../common/localization/localizationService';
import { useFormikContext } from 'formik';
import Select from 'react-select';
import { connect } from 'react-redux';
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';
import moduleConstants from '../../redux/modules/module/moduleConstants';

function UserModuleSelect({ moduleOptions, isLoading }) {
  const { values, setFieldValue, handleBlur } = useFormikContext();

  return (
    <Form.Group as={Row}>
      <Col sm={12}>
        <Select
          options={moduleOptions}
          isMulti
          value={values.selectedModules}
          onChange={(options) => setFieldValue('selectedModules', options)}
          name="selectedModules"
          onBlur={handleBlur}
          placeholder={locale.userAdmin._modules}
          isLoading={isLoading}
          noOptionsMessage={() => locale.userAdmin._noModuleOptionsMessage}
          loadingMessage={() => locale.userAdmin._modulesLoadingMessage}
        />
      </Col>
    </Form.Group>
  );
}

UserModuleSelect.propTypes = {
  moduleOptions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const isContractorsLoading = createLoadingSelector(moduleConstants.GET_MODULES);

function mapStateToProps(state) {
  return {
    isLoading: isContractorsLoading(state),
  };
}

export default connect(mapStateToProps)(UserModuleSelect);
