import wasteManagementClient from './wasteManagementClient';
import { wasteManagementUrls } from '../appConfig';
import { Toastr } from '../utils/Toastr';

export default class DirectMessageService {
  static getDirectMessages(ignoreCache = false) {
    return wasteManagementClient
      .get(wasteManagementUrls.message, {
        cache: { ignoreCache },
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          return response.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        Toastr.error('Error on getting direct messages');
        throw error;
      });
  }
}
