import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import { locale } from '../../common/localization/localizationService';
import { useFormikContext } from 'formik';
import Select from 'react-select';
import { connect } from 'react-redux';
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';
import contractorConstants from '../../redux/modules/contractor/contractorConstants';

function UserContractorSelect({ contractorOptions, isLoading }) {
  const { values, setFieldValue, errors, handleBlur, touched } =
    useFormikContext();

  return (
    <Form.Group as={Row}>
      <Col sm={12}>
        <Select
          isDisabled={!values.contractor}
          options={contractorOptions}
          value={values.selectedContractor}
          onChange={(option) => setFieldValue('selectedContractor', option)}
          name="selectedContractor"
          placeholder={locale.userAdmin._contractor}
          onBlur={handleBlur}
          className={
            touched.selectedContractor && errors.selectedContractor
              ? 'is-invalid'
              : null
          }
          isLoading={isLoading}
          noOptionsMessage={() => locale.userAdmin._noContractorOptionsMessage}
          loadingMessage={() => locale.userAdmin._contractorsLoadingMessage}
        />
        <Form.Control.Feedback type="invalid">
          {errors.selectedContractor}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
}

UserContractorSelect.propTypes = {
  contractorOptions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const isContractorsLoading = createLoadingSelector(
  contractorConstants.GET_CONTRACTORS
);

function mapStateToProps(state) {
  return {
    isLoading: isContractorsLoading(state),
  };
}

export default connect(mapStateToProps)(UserContractorSelect);
