import React, { Component, ReactNode } from 'react';
import { RouteStopTooltip } from './RouteStopTooltip';
import { CircleMarker } from '../shared/customMapLayer';
import { extractRouteStopDetails } from '../shared/routeStopUtility';
import {
  faCheckCircle,
  faTimesCircle,
  faCircle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { IRouteStop, ReportDetailsMessage } from '../../models';
import { WasteSystems, WasteTypes } from '../../redux/modules/map/wasteTypes';

export interface IRouteStopMarkerProps {
  routeStop: IRouteStop | Array<IRouteStop>;
  zoomLevel: number;
}

export class RouteStopMarker extends Component<IRouteStopMarkerProps> {
  public getIconAndColor(routeStop: IRouteStop): {
    icon: IconDefinition;
    color?: string;
  } {
    if (routeStop.reportDetailsView?.message === ReportDetailsMessage.YES) {
      return { color: 'green', icon: faCheckCircle };
    }
    if (routeStop.reportDetailsView?.message === ReportDetailsMessage.NO) {
      return { color: 'red', icon: faTimesCircle };
    }

    const routeStopWasteType =
      routeStop.agreementLine?.agreement?.type?.toLocaleLowerCase() ||
      routeStop.agreementLine?.type?.toLocaleLowerCase();
    const routeStopSystem =
      routeStop.agreementLine?.paSystem?.toLocaleLowerCase() ||
      routeStop.agreementLine?.paSystem?.toLocaleLowerCase();

    switch (routeStopWasteType) {
      case WasteTypes.HOUSEHOLD:
        return { color: '#eb0000', icon: faCircle };
      case WasteTypes.COMMERCIAL:
        switch (routeStopSystem) {
          case WasteSystems.H.toLocaleLowerCase():
            return { color: '#BE0BEA', icon: faCircle };
          default:
            return { color: '#0000eb', icon: faCircle };
        }
      case WasteTypes.SLUDGE:
        return { color: '#914e82', icon: faCircle };
      case WasteTypes.CABIN:
        return { color: '#20B2AA', icon: faCircle };
      default:
        return { color: '#eb0000', icon: faCircle };
    }
  }

  public getRouteStopPosition(routeStop: IRouteStop): [number, number] {
    return [routeStop.routeLine.degLat, routeStop.routeLine.degLong];
  }

  public render(): ReactNode {
    const { zoomLevel } = this.props;
    let details;
    let position;
    let color;
    let icon;
    let hasDeviation;
    if (Array.isArray(this.props.routeStop)) {
      this.props.routeStop.forEach((routeStop) => {
        details = extractRouteStopDetails(routeStop);
        position = this.getRouteStopPosition(routeStop);
        if (details.deviation) {
          hasDeviation = details.deviation;
        }
        color = this.getIconAndColor(routeStop).color;
        icon = this.getIconAndColor(routeStop).icon;
      });
      if (
        this.props.routeStop.every(
          (routeStop) =>
            routeStop.reportDetailsView?.message === ReportDetailsMessage.YES
        )
      ) {
        color = 'green';
        icon = faCheckCircle;
      } else if (
        this.props.routeStop.every(
          (routeStop) =>
            routeStop.reportDetailsView?.message === ReportDetailsMessage.NO
        )
      ) {
        color = 'red';
        icon = faTimesCircle;
      }
    } else {
      details = extractRouteStopDetails(this.props.routeStop);
      position = this.getRouteStopPosition(this.props.routeStop);
      color = this.getIconAndColor(this.props.routeStop).color;
      icon = this.getIconAndColor(this.props.routeStop).icon;
      hasDeviation = details.deviation;
    }

    return (
      <>
        <CircleMarker
          position={position}
          isHighlighted={hasDeviation}
          icon={icon}
          color={color}
          zoomLevel={zoomLevel}
        >
          <RouteStopTooltip data={this.props.routeStop} />
        </CircleMarker>
      </>
    );
  }
}
