import { Action } from 'redux';
import { Location } from 'history';
import React, { Component, ReactNode } from 'react';
import { LatLngLiteral } from 'leaflet';
import { ClusteredMapLayer } from '../shared/customMapLayer';
import routes from '../../common/routes';
import { locale } from '../../common/localization/localizationService';
import { ICoords, IRoute, IRoutePoi, RouteEditMode } from '../../models';
import { WarningBanner } from './WarningBanner';
import { PoiMarker } from './PoiMarker';
import { Pane } from 'react-leaflet';

export interface IRouteAdminLayerProps {
  isPropertyLayerVisible: boolean;
  clusteringActive: boolean;
  zoomLevel: number;
  location: Location;
  routes: Array<IRoute>;
  pois: Array<IRoutePoi>;
  selectedRoutes: Array<IRoute>;
  markedRouteSequenceIds: Array<string>;
  markedRouteSequences: Array<IRoutePoi>;
  mode: RouteEditMode;
  markRouteSequences: (ids: Array<string>) => Action;
  moveRouteSequences: (ids: Array<{ id: string; coords: ICoords }>) => Action;
}

export interface IRouteAdminLayerState {
  defaultLinesVisible: boolean;
}

export class RouteAdminLayer extends Component<
  IRouteAdminLayerProps,
  IRouteAdminLayerState
> {
  public readonly state: IRouteAdminLayerState = {
    defaultLinesVisible: false,
  };

  public componentDidUpdate(prevProps: IRouteAdminLayerProps): void {
    if (prevProps.isPropertyLayerVisible && !this.props.isPropertyLayerVisible)
      this.setState({ ...this.state, defaultLinesVisible: false });
  }

  /** @todo: this check should not be here */
  public isRouteAdminViewActive(location: Location): boolean {
    return location.pathname.startsWith(routes.routeAdminPagePath);
  }

  public getMarkers(pois: Array<IRoutePoi>): Array<ReactNode> {
    const { zoomLevel, mode, isPropertyLayerVisible } = this.props;

    return pois.map((poi) => {
      const isSelected = this.isSelected(poi.id);

      return (
        <PoiMarker
          key={poi.id}
          poi={poi}
          mode={mode}
          zoomLevel={zoomLevel}
          isPropertyLayerVisible={isPropertyLayerVisible}
          isSelected={isSelected}
          isDraggable={
            (isSelected || !this.props.markedRouteSequences.length) &&
            mode === RouteEditMode.Move
          }
          defaultLinesVisible={this.state.defaultLinesVisible}
          onClick={() => this.onMarkerSelect(poi)}
          onDrag={(coords: LatLngLiteral) => {
            if (!isSelected) {
              this.onMarkerSelect(poi);
            }
            return this.onMarkerMove(poi.id, [coords.lat, coords.lng]);
          }}
        />
      );
    });
  }

  public onWarningClick(): void {
    this.setState({
      ...this.state,
      defaultLinesVisible: !this.state.defaultLinesVisible,
    });
  }

  public onMarkerSelect(poi: IRoutePoi): void {
    if (
      this.props.mode === RouteEditMode.Move &&
      this.props.markedRouteSequenceIds?.length
    )
      return;
    this.props.markRouteSequences([poi.id]);
  }

  public isSelected(id: string): boolean {
    return this.props.markedRouteSequenceIds.includes(id);
  }

  public getIdsFromTuple(tuple: ICoords): Array<string> {
    const { pois } = this.props;
    return pois
      .filter((poi) => ICoords.equals(IRoutePoi.toCoords(poi), tuple))
      .map((poi) => poi.id);
  }

  public onMarkerMove(id: string, coords: ICoords): void {
    this.props.moveRouteSequences([{ id, coords }]);
  }

  public render(): ReactNode {
    const { clusteringActive, isPropertyLayerVisible, location } = this.props;
    if (!this.isRouteAdminViewActive(location)) return null;
    const marked: Array<ReactNode> = this.getMarkers(
      this.props.markedRouteSequences
    );
    const unmarked: Array<ReactNode> = this.getMarkers(
      this.props.pois.filter(
        (poi) => !this.props.markedRouteSequenceIds.includes(poi.id)
      )
    );
    return (
      <>
        <WarningBanner
          hidden={!isPropertyLayerVisible || !this.props.pois.length}
          onAnchor={() => this.onWarningClick()}
          text={locale.routeAdminPage._propertyLinesWarning}
          anchor={
            this.state.defaultLinesVisible
              ? locale.routeAdminPage._propertyLinesCustom
              : locale.routeAdminPage._propertyLinesAll
          }
        />
        <ClusteredMapLayer isClusteringActive={clusteringActive}>
          <Pane name="route-admin-marked" style={{ zIndex: 500 }}>
            {marked}
          </Pane>
          <Pane name="route-admin-unmarked" style={{ zIndex: 400 }}>
            {unmarked}
          </Pane>
        </ClusteredMapLayer>
      </>
    );
  }
}
