import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FileResultsModal from './FileResultsModal';
import { parseRouteFromFile, validateAndExtendRoute } from './RouteProcessing';
import { locale } from '../../../common/localization/localizationService';

export default class ImportManager extends Component {
  static propTypes = {
    agreementActions: PropTypes.object.isRequired,
    onRouteImport: PropTypes.func,
    onImportError: PropTypes.func,
  };

  static defaultProps = {
    agreementActions: {},
    onRouteImport: () => {},
    onImportError: () => {},
  };

  constructor(props) {
    super(props);

    this.handleFileSelected = this.handleFileSelected.bind(this);
    this.hideFileResultModal = this.hideFileResultModal.bind(this);
  }

  state = {
    fileParseErrors: null,
  };

  handleFileSelected(event) {
    event.stopPropagation();
    event.preventDefault();

    const file = event.target.files[0];
    if (!file) return;

    event.target.value = null;

    return this.parseFile(file);
  }

  parseFile(file) {
    const { onRouteImport, onImportError, agreementActions } = this.props;

    return parseRouteFromFile(file)
    .then((importedRoutes) => {
      const agreementIds = importedRoutes.map((x) => x.gln);
      
      return agreementActions.getAgreements(agreementIds)
        .then((agreements) => {
          return validateAndExtendRoute(importedRoutes, agreements);
        });
    })
    .then(onRouteImport)
    .catch((errors) => {
      onImportError(errors);
      const fileParseErrors = this.processParseErrors(errors);
      this.setState({ fileParseErrors });     
       
      return Promise.reject(errors);
    });
  }

  processParseErrors(errors) {
    if (Array.isArray(errors)) {
      return errors;
    }
    if (typeof errors === 'object' && errors.message != null) {
      return [errors.message];
    }
    if (typeof errors === 'string') {
      return [errors];
    }

    console.error(errors);
    return [locale.routeOptimalizationWizard.importManager._unknownParseError];
  }

  openFileInput() {
    this.fileInput.click();
  }

  hideFileResultModal() {
    this.setState({ fileParseErrors: null });
  }

  render() {
    const { fileParseErrors } = this.state;

    return (
      <div>
        <input
          type="file"
          ref={(ref) => (this.fileInput = ref)}
          onChange={this.handleFileSelected}
          style={{ display: 'none' }}
        />
        <FileResultsModal
          show={!!fileParseErrors && fileParseErrors.length > 0}
          errors={fileParseErrors}
          onClose={this.hideFileResultModal}
        />
      </div>
    );
  }
}
