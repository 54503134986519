import directMessagesConstants from './directMessagesConstants';

const initialState = {
  messages: [],
  isLoading: false,
};

export default function directMessagesReducer(state = initialState, action) {
  switch (action.type) {
    case directMessagesConstants.GET_MESSAGES:
      return {
        ...state,
        isLoading: true,
      };
    case directMessagesConstants.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.messages,
        isLoading: false,
      };
    case directMessagesConstants.GET_MESSAGES_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
}
