import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PaneHeadline from '../shared/paneHeadline';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { locale } from '../../common/localization/localizationService';
import routes from '../../common/routes';
import StepsHeader from './stepsHeader';
import TemplateDownloadButton from './TemplateDownloadButton';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import ImportedRouteGrid from './importedRouteGrid';
import WorklistResults from './worklistResults';
import UploadFileWarning from './UploadFileWarning';
import ImportManager from './importManager';
import RouteAnalysisModal from './routeAnalysisModal';
import CreateWorklistsModal from './createWorklistsModal';
import './routeOptimalizationWizard.scss';
import RouteGrid from './RouteGrid';
import Spinner from '../shared/spinner/Spinner';

class RouteOptimalizationWizard extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    agreementActions: PropTypes.object.isRequired,
    importedRoutes: PropTypes.array.isRequired,
    routeOptimalizationActions: PropTypes.object.isRequired,
    worklists: PropTypes.array.isRequired,
    activeTab: PropTypes.number.isRequired,
    unassignedStops: PropTypes.array.isRequired,
    isAgreementsLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    history: {},
    agreementActions: {},
    importedRoutes: [],
    unassignedStops: [],
    routeOptimalizationActions: {},
    worklists: [],
    activeTab: 0,
    isAgreementsLoading: false,
  };

  constructor(props) {
    super(props);

    this.handleSelectTab = this.handleSelectTab.bind(this);
    this.openSelectFile = this.openSelectFile.bind(this);
    this.showRouteAnalysisModal = this.showRouteAnalysisModal.bind(this);
    this.hideRouteAnalysisModal = this.hideRouteAnalysisModal.bind(this);
    this.showCreateWorklistsModal = this.showCreateWorklistsModal.bind(this);
    this.hideCreateWorklistsModal = this.hideCreateWorklistsModal.bind(this);
    this.handleWorklistsCreated = this.handleWorklistsCreated.bind(this);
    this.handleAnalysisResult = this.handleAnalysisResult.bind(this);
    this.handleRouteImported = this.handleRouteImported.bind(this);
    this.handleRouteImportError = this.handleRouteImportError.bind(this);
  }

  state = {
    fileParseResults: null,
    routeAnalysisModalVisible: false,
    createWorklistsModalVisible: false,
    saveEnabled: false,
  };

  componentWillUnmount() {
    this.props.routeOptimalizationActions.clear();
  }

  handleSelectTab(e) {
    this.props.routeOptimalizationActions.setActiveTab(e.selected);
  }

  openSelectFile() {
    this.importManager.getWrappedInstance().openFileInput();
  }

  showRouteAnalysisModal() {
    this.setState({ routeAnalysisModalVisible: true });
  }

  hideRouteAnalysisModal() {
    this.setState({ routeAnalysisModalVisible: false });
  }

  showCreateWorklistsModal() {
    this.setState({ createWorklistsModalVisible: true });
  }

  hideCreateWorklistsModal() {
    this.setState({ createWorklistsModalVisible: false });
  }

  handleWorklistsCreated() {
    this.props.history.push(routes.serviceListsPath);
  }

  handleRouteImported(importedRoutes) {
    this.props.routeOptimalizationActions.setImportedRoutes(importedRoutes);
  }

  handleRouteImportError() {
    this.props.routeOptimalizationActions.setImportedRoutes([]);
  }

  handleAnalysisResult({ worklists, unassignedStops, depotStops }) {
    const { routeOptimalizationActions } = this.props;

    routeOptimalizationActions.setWorklists(worklists);
    routeOptimalizationActions.setActiveTab(1);
    routeOptimalizationActions.setUnassignedStops(unassignedStops);
    routeOptimalizationActions.setDepotStopLists(depotStops);

    this.setState({
      routeAnalysisModalVisible: false,
      saveEnabled: true,
    });
  }

  render() {
    const {
      importedRoutes,
      worklists,
      activeTab,
      unassignedStops,
      isAgreementsLoading,
    } = this.props;
    const {
      routeAnalysisModalVisible,
      saveEnabled,
      createWorklistsModalVisible,
    } = this.state;

    const hasImportedRoutes = !!importedRoutes && importedRoutes.length > 0;

    return (
      <div className="route-optimalization-wizard-view">
        {isAgreementsLoading && <Spinner />}
        <PaneHeadline
          titleText={locale.routeOptimalizationWizard._title}
          titleIcon={faWrench}
          backTitle={locale.general._serviceList}
          backUrl={routes.serviceListsPath}
        >
          <TemplateDownloadButton />
        </PaneHeadline>
        <div className="steps-col">
          <StepsHeader
            onSelectFile={this.openSelectFile}
            analyseEnabled={hasImportedRoutes}
            onAnalyse={this.showRouteAnalysisModal}
            saveEnabled={saveEnabled}
            onSave={this.showCreateWorklistsModal}
          />
          <ImportManager
            ref={(ref) => (this.importManager = ref)}
            onRouteImport={this.handleRouteImported}
            onImportError={this.handleRouteImportError}
          />
        </div>
        <TabStrip selected={activeTab} onSelect={this.handleSelectTab}>
          <TabStripTab
            title={locale.routeOptimalizationWizard._importedRouteTabTitle}
          >
            {hasImportedRoutes ? (
              <ImportedRouteGrid />
            ) : (
              <UploadFileWarning onSelectFile={this.openSelectFile} />
            )}
          </TabStripTab>
          <TabStripTab
            title={locale.routeOptimalizationWizard._worklistsTabTitle}
            disabled={!worklists || worklists.length === 0}
          >
            <WorklistResults />
          </TabStripTab>
          <TabStripTab
            title={locale.routeOptimalizationWizard._unassignedStopsTabTitle}
            disabled={!unassignedStops || unassignedStops.length === 0}
          >
            <RouteGrid routes={unassignedStops} />
          </TabStripTab>
        </TabStrip>

        <RouteAnalysisModal
          show={routeAnalysisModalVisible}
          onClose={this.hideRouteAnalysisModal}
          onAnalysisResults={this.handleAnalysisResult}
        />
        <CreateWorklistsModal
          show={createWorklistsModalVisible}
          onClose={this.hideCreateWorklistsModal}
          onSave={this.handleWorklistsCreated}
          worklists={worklists}
        />
      </div>
    );
  }
}

export default RouteOptimalizationWizard;
