import React from 'react';
import PropTypes from 'prop-types';
import { locale } from '../../common/localization/localizationService';

export default function OrderDeviations({ orderDetails }) {
  if (
    !orderDetails ||
    !orderDetails.deviations ||
    orderDetails.deviations.length === 0
  ) {
    return null;
  }

  return (
    <div className="card mb-3">
      <div className="card-header">
        <h3>{locale.routeStopDetails._deviation}</h3>
      </div>
      <ul className="list-group list-group-flush">
        {orderDetails.deviations &&
          orderDetails.deviations.map((deviation) => (
            <li className="list-group-item" key={deviation.exceptionId}>
              {deviation.value}
            </li>
          ))}
      </ul>
    </div>
  );
}

OrderDeviations.propTypes = {
  orderDetails: PropTypes.object,
};
