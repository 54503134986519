import React, { Component, ReactNode } from 'react';
import {
  IAddNewDeviation,
  IDeviation,
  IDeviationCategory,
} from '../../models/Deviation';
import DeviationsService from '../../services/deviationsService';
import { Toastr } from '../../utils/Toastr';
import { Formik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import TextField from '../shared/formikFields/TextField';
import { debounceEventHandler } from '../shared/submitFormUtility';
import { locale } from '../../common/localization/localizationService';
import yup from '../../common/validation';
import DeviationCategorySelector, {
  ICategoryOption,
} from './DeviationCategorySelector';

export interface IDeviationDialogProps {
  customerId: number;
  deviation: IDeviation;
  deviations: Array<IDeviation>;
  deviationCategories: Array<IDeviationCategory>;
  show: boolean;
  onHide: (renderer: boolean) => void;
}

export interface IDeviationDialogState {
  selectedCategoryId: number;
}

export default class DeviationDialog extends Component<
  IDeviationDialogProps,
  IDeviationDialogState
> {
  public readonly state: IDeviationDialogState = {
    selectedCategoryId: null,
  };

  public componentDidMount(): void {
    if (this.props.deviation) {
      this.setState({
        ...this.state,
        selectedCategoryId: this.props.deviation.categoryId,
      });
    }
  }

  private async onSubmit(
    deviation: IDeviation | IAddNewDeviation,
    isNew: boolean
  ): Promise<void> {
    deviation.categoryId = this.state.selectedCategoryId;
    if (isNew) {
      try {
        await DeviationsService.addDeviaton(deviation);
        Toastr.success(locale.deviations._addSuccess);
      } catch (error) {
        Toastr.error(error);
      } finally {
        this.props.onHide(true);
      }
    } else {
      if ('exceptionId' in deviation) {
        try {
          await DeviationsService.updateDeviaton(deviation);
          Toastr.success(locale.deviations._editSuccess);
        } catch (error) {
          Toastr.error(error);
        } finally {
          this.props.onHide(true);
        }
      }
    }
  }

  private newDeviation(): IAddNewDeviation {
    const newDeviation: IAddNewDeviation = {
      customerId: this.props.customerId,
      value: '',
      categoryId: null,
      enabled: true,
      externalExId: undefined,
    };
    return newDeviation;
  }

  private getSchema() {
    return yup.object({
      value: yup.string().required().label(locale.deviations._deviation),
      externalExId: yup
        .number()
        .nullable()
        .label(locale.deviations._deviatonExtId),
    });
  }

  private categoryChange(category: ICategoryOption): void {
    this.setState({ ...this.state, selectedCategoryId: category.value });
  }

  public render(): ReactNode {
    const isNew = !this.props.deviation;
    const devaition = this.props.deviation || this.newDeviation();
    const { show, deviationCategories } = this.props;
    const onHide = this.props.onHide;

    return (
      <Formik
        onSubmit={(devaition: IDeviation | IAddNewDeviation) =>
          this.onSubmit(devaition, isNew)
        }
        initialValues={{ ...devaition }}
        validationSchema={this.getSchema()}
      >
        {({ handleSubmit }) => (
          <Modal
            show={show}
            onHide={() => onHide(false)}
            className="user-edit-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {devaition.value
                  ? locale.vehicles._edit + ' ' + devaition.value
                  : locale.deviations._addNew}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form noValidate onSubmit={handleSubmit}>
                <TextField
                  name="value"
                  label={locale.deviations._deviation}
                  placeholder={locale.deviations._deviation}
                />
                <TextField
                  name="externalExId"
                  label={locale.deviations._deviatonExtId}
                  placeholder={locale.deviations._deviatonExtId}
                />
                <div className="form-group row">
                  <div className="col-sm-12">
                    <label className="form-label">
                      {locale.deviations._category}
                    </label>
                  </div>
                  <DeviationCategorySelector
                    categories={deviationCategories}
                    handleChange={this.categoryChange.bind(this)}
                    selectedCategory={this.state.selectedCategoryId}
                    className="col-sm-12"
                  />
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => onHide(false)}>
                {locale.vehicles._cancel}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={debounceEventHandler(handleSubmit, 250)}
                disabled={!this.state.selectedCategoryId}
              >
                {isNew ? locale.vehicles._submit : locale.vehicles._update}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    );
  }
}
