import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../shared/spinner/Spinner';
import { locale } from '../../common/localization/localizationService';
import ContainerOrdersGrid from './ContainerOrdersGrid';
import moment from 'moment';
import './container-orders.scss';
import AddVehicleDialog from './addVehicleDialog';
import RefreshButton from '../shared/refreshButton';

class ContainerOrders extends Component {
  constructor(props) {
    super(props);

    this.handleDateFilterChange = this.handleDateFilterChange.bind(this);
    this.setSelectedContainerOrder = this.setSelectedContainerOrder.bind(this);
  }

  state = {
    addVehicleDialog: {
      vehicles: null,
      containerOrderId: null,
      show: false,
      selectedCustomerId: null,
      selectedContractorId: null,
      selectedVehicleId: null,
    },
  };

  componentDidMount() {
    this.getContainerOrders();
    this.loadVehicles();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dateFilterValue !== this.props.dateFilterValue) {
      this.getContainerOrders();
    }
  }

  async loadVehicles() {
    await this.props.fetchVehicles(false);
  }

  handleDateFilterChange(event) {
    this.props.containerOrderActions.setDateRangeFilter(event.target.value);
  }

  getContainerOrders(ignoreCache = false) {
    const { dateFilterValue, containerOrderActions } = this.props;

    let fromDate;
    let toDate;
    switch (dateFilterValue) {
      default:
      case 'thisWeek':
        fromDate = moment().subtract(1, 'week').format('YYYY-MM-DD');
        break;
      case 'lastWeek':
        fromDate = moment().subtract(2, 'week').format('YYYY-MM-DD');
        toDate = moment().subtract(1, 'week').format('YYYY-MM-DD');
        break;
      case 'thisMonth':
        fromDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
        break;
    }

    containerOrderActions.getContainerOrders(fromDate, toDate, ignoreCache);
  }

  setSelectedContainerOrder(row) {
    if (row.dataItem.orderId === this.props.selectedOrderId) {
      this.props.containerOrderActions.setSelectedContainerOrderId(null);
    } else {
      this.props.containerOrderActions.setSelectedContainerOrderId(
        row.dataItem.orderId
      );
    }
  }

  openAddVehicle(containerOrder) {
    this.setState({
      ...this.state,
      addVehicleDialog: {
        vehicles: this.props.vehicles,
        containerOrderId: containerOrder.orderId,
        show: true,
        selectedCustomerId: this.props.authentication.customerId,
        selectedContractorId: this.props.authentication.contractorId
          ? this.props.authentication.contractorId
          : -1,
        selectedVehicleId: containerOrder.vehicleId,
      },
    });
  }

  hideAddVehicleDialog(rerender = false) {
    this.setState({
      ...this.state,
      addVehicleDialog: {
        vehicles: null,
        containerOrderId: null,
        show: false,
        selectedCustomerId: null,
        selectedContractorId: null,
        selectedVehicleId: null,
      },
    });
    if (rerender) {
      this.getContainerOrders(true);
    }
  }

  refreshItems() {
    this.getContainerOrders(true);
  }

  render() {
    const { containerOrders, isLoading, selectedOrderId, vehicles } =
      this.props;

    return (
      <div className="container-orders-view">
        <div className="row header-row">
          <div className="col">
            <h2>
              <FontAwesomeIcon icon={faPhone} />
              {locale.general._callOrder}
            </h2>
          </div>
          <div className="col-3">
            <select
              className="form-control"
              value={this.props.dateFilterValue}
              onChange={this.handleDateFilterChange}
            >
              <option value="thisWeek">
                {locale.dateRangePicker._thisWeek}
              </option>
              <option value="lastWeek">
                {locale.dateRangePicker._lastWeek}
              </option>
              <option value="thisMonth">
                {locale.dateRangePicker._thisMonth}
              </option>
            </select>
          </div>
          <div className="refresh-button-wrapper">
            <RefreshButton onClick={this.refreshItems.bind(this)} />
          </div>
        </div>
        <div className="container-orders-grid-wrapper">
          {isLoading && <Spinner />}
          {containerOrders && (
            <ContainerOrdersGrid
              containerOrders={containerOrders}
              onRowClick={this.setSelectedContainerOrder}
              addVehicle={this.openAddVehicle.bind(this)}
              selectedOrderId={selectedOrderId}
            />
          )}
          {!containerOrders && !isLoading && (
            <div className="d-flex flex-row justify-content-center align-items-center h-75">
              <div className="alert alert-warning" role="alert">
                {locale.containerOrders._noOrders}{' '}
                <FontAwesomeIcon icon="exclamation-triangle" />
              </div>
            </div>
          )}
        </div>
        {this.state.addVehicleDialog.show && (
          <AddVehicleDialog
            user={this.props.authentication}
            vehicles={vehicles.vehiclesList}
            containerOrderId={this.state.addVehicleDialog.containerOrderId}
            selectedVehicleId={this.state.addVehicleDialog.selectedVehicleId}
            show={this.state.addVehicleDialog.show}
            onHide={this.hideAddVehicleDialog.bind(this)}
          />
        )}
      </div>
    );
  }
}

ContainerOrders.propTypes = {
  containerOrderActions: PropTypes.object.isRequired,
  containerOrders: PropTypes.array,
  selectedOrderId: PropTypes.number,
  isLoading: PropTypes.bool,
  dateFilterValue: PropTypes.string,
  vehicles: PropTypes.object,
  fetchVehicles: PropTypes.func,
  authentication: PropTypes.object,
};

export default ContainerOrders;
