import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../shared/spinner/Spinner';
import { locale } from '../../common/localization/localizationService';
import ServiceListsGrid from './ServiceListsGrid';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import routes from '../../common/routes';
import './service-lists.scss';
import RefreshButton from '../shared/refreshButton';
import PaneHeadline from '../shared/paneHeadline';

function isServiceListNotStarted(serviceList) {
  return (
    !!serviceList &&
    serviceList.started === null &&
    serviceList.updated === null &&
    !!serviceList.orders &&
    serviceList.orders.every((o) => !o.executed)
  );
}

class ServiceLists extends Component {
  constructor(props) {
    super(props);

    this.handleDateFilterChange = this.handleDateFilterChange.bind(this);
    this.setSelectedServiceList = this.setSelectedServiceList.bind(this);
    this.refreshItems = this.refreshItems.bind(this);
    this.deleteServiceList = this.deleteServiceList.bind(this);
  }

  componentDidMount() {
    this.getServiceLists();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dateFilterValue !== this.props.dateFilterValue) {
      this.getServiceLists();
    }
  }

  handleDateFilterChange(event) {
    this.props.serviceOrderActions.setDateRangeFilter(event.target.value);
  }

  getServiceLists(ignoreCache = true) {
    const { dateFilterValue, serviceOrderActions } = this.props;

    let fromDate;
    let toDate;
    switch (dateFilterValue) {
      default:
      case 'thisWeek':
        fromDate = moment().subtract(1, 'week').format('YYYY-MM-DD');
        break;
      case 'lastWeek':
        fromDate = moment().subtract(2, 'week').format('YYYY-MM-DD');
        toDate = moment().subtract(1, 'week').format('YYYY-MM-DD');
        break;
      case 'thisMonth':
        fromDate = moment().subtract(2, 'year').format('YYYY-MM-DD');
        break;
    }

    serviceOrderActions.getServiceLists(fromDate, toDate, ignoreCache);
  }

  setSelectedServiceList(row) {
    const { serviceOrderActions } = this.props;
    if (row.dataItem.listId === this.props.selectedListId) {
      serviceOrderActions.setSelectedServiceListId(null);
    } else {
      serviceOrderActions.setSelectedServiceListId(row.dataItem.listId);
    }
  }

  refreshItems() {
    this.getServiceLists(true);
  }

  deleteDisabled() {
    const { serviceLists, selectedListId } = this.props;
    const listToDelete = serviceLists.find((l) => l.listId === selectedListId);

    return !isServiceListNotStarted(listToDelete);
  }

  deleteServiceList() {
    const { serviceLists, selectedListId, serviceOrderActions } = this.props;

    const listToDelete = serviceLists.find((l) => l.listId === selectedListId);

    if (
      isServiceListNotStarted(listToDelete) &&
      confirm(locale.serviceLists._deleteConfirm + listToDelete.listName)
    ) {
      serviceOrderActions.deleteServiceList(selectedListId);
    }
  }

  render() {
    const { serviceLists, isLoading, selectedListId } = this.props;

    return (
      <div className="service-lists-view">
        <PaneHeadline
          titleText={locale.general._serviceList}
          titleIcon={faWrench}
        >
          <select
            className="form-control mr-2"
            value={this.props.dateFilterValue}
            onChange={this.handleDateFilterChange}
          >
            <option value="thisWeek">{locale.dateRangePicker._thisWeek}</option>
            <option value="lastWeek">{locale.dateRangePicker._lastWeek}</option>
            <option value="thisMonth">
              {locale.dateRangePicker._thisMonth}
            </option>
          </select>
          <RefreshButton onClick={this.refreshItems} />
        </PaneHeadline>
        <div className="service-lists-grid-wrapper">
          {isLoading && <Spinner />}
          {serviceLists && (
            <ServiceListsGrid
              serviceLists={serviceLists}
              onRowClick={this.setSelectedServiceList}
              selectedListId={selectedListId}
              isLoading={isLoading}
            />
          )}
          {!serviceLists && !isLoading && (
            <div className="d-flex flex-row justify-content-center align-items-center h-75">
              <div className="alert alert-warning" role="alert">
                {locale.serviceLists._noOrders}{' '}
                <FontAwesomeIcon icon="exclamation-triangle" />
              </div>
            </div>
          )}
        </div>
        <div className="row button-row">
          <button
            className="btn btn-outline-dark"
            disabled={this.deleteDisabled()}
            onClick={this.deleteServiceList}
          >
            {locale.serviceLists._delete}
          </button>
          <NavLink
            to={routes.routeOptimalizationWizardPath}
            className="btn btn-outline-dark"
          >
            {locale.serviceLists._add}
          </NavLink>
        </div>
      </div>
    );
  }
}

ServiceLists.propTypes = {
  serviceOrderActions: PropTypes.object.isRequired,
  serviceLists: PropTypes.array,
  selectedListId: PropTypes.number,
  isLoading: PropTypes.bool,
  dateFilterValue: PropTypes.string,
};

export default ServiceLists;
