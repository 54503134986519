import { bindActionCreators, Dispatch } from 'redux';
import directMessagesActions from '../../redux/modules/directMessages/directMessagesActions';
import { IRootState } from '../../redux/state';
import { IUser } from '../../models';
import { connect } from 'react-redux';
import Messages from './DirectMessages';

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getMessages: directMessagesActions.getMessages,
    },
    dispatch
  );
}

function mapStateToProps(state: IRootState) {
  console.log(state);
  return {
    authentication: state.authentication as unknown as IUser,
    messageList: state.message.messages,
    isLoading: state.message.isLoading,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Messages);
