import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuFilter,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { locale } from '../../common/localization/localizationService';
import ColumnMenu from '../shared/ColumnMenu';
import InfoButtonCell from '../shared/infoButtonCell/InfoButtonCell';
import routes from '../../common/routes';
import StatusBar from '../shared/StatusBar';
import { groupBy, selectDate } from '../../services/listService';
import { getVehicleIds } from '../shared/serviceOrderUtility';

class ServiceListsGrid extends Component {
  constructor(props) {
    super(props);

    this.dataStateChange = this.dataStateChange.bind(this);
    this.InfoButtonCell = this.InfoButtonCell.bind(this);
  }

  static propTypes = {
    onRowClick: PropTypes.func.isRequired,
    serviceLists: PropTypes.array,
    selectedListId: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
  };

  state = {
    dataState: {
      sort: [
        {
          dir: 'desc',
          field: 'updated',
        },
      ],
    },
  };

  static getDate(list, selector, minMax, fallback) {
    if (!list.orders || list.orders.length === 0) {
      if (fallback(list)) {
        return new Date(fallback(list));
      }
      return null;
    }

    return selectDate(list.orders.map(selector), minMax);
  }

  static getServiceListItemsCount(orders) {
    if (!orders || orders.length === 0) {
      return 0;
    }

    return new Set(orders.map((order) => order.listItemId)).size;
  }

  static getExecutedServiceListItemsCount(orders) {
    if (!orders || orders.length === 0) {
      return 0;
    }

    const groupedOrders = groupBy(orders, (order) => order.listItemId);
    return [...groupedOrders].reduce((executedCount, [, listItems]) => {
      if (
        listItems.length === listItems.filter((item) => item.executed).length
      ) {
        return executedCount + 1;
      }

      return executedCount;
    }, 0);
  }

  static processLists(serviceLists, selectedListId, dataState) {
    const extendedLists = serviceLists.map((list) => ({
      listName: list.listName,
      listId: list.listId,
      vehicleId: getVehicleIds(list.orders) || list.vehicleId,
      updated: this.getDate(
        list,
        (order) => order.updated,
        Math.max,
        (list) => list.updated
      ),
      started: this.getDate(
        list,
        (order) => order.inserted,
        Math.min,
        (list) => list.started
      ),
      totalOrders: this.getServiceListItemsCount(list.orders),
      executedOrders: this.getExecutedServiceListItemsCount(list.orders),
      selected: list.listId === selectedListId,
      listDate: new Date(list.listDate),
    }));

    return process(extendedLists, dataState);
  }

  dataStateChange(event) {
    this.setState({ dataState: event.data });
  }

  columnProps(field) {
    return {
      field: field,
      columnMenu: ColumnMenu,
      headerClassName: this.isColumnActive(field, this.state.dataState)
        ? 'active'
        : '',
      title: locale.serviceListsGrid['_' + field],
    };
  }

  isColumnActive(field, dataState) {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  InfoButtonCell(gridProps) {
    return (
      <InfoButtonCell
        linkToUrl={`${routes.serviceListsPath}/${gridProps.dataItem.listId}`}
        {...gridProps}
      />
    );
  }

  render() {
    const { serviceLists, selectedListId, onRowClick, isLoading } = this.props;
    const result = ServiceListsGrid.processLists(
      serviceLists,
      selectedListId,
      this.state.dataState
    );

    return (
      <Grid
        {...this.state.dataState}
        className="mb-3"
        data={result}
        selectedField="selected"
        scrollable={'none'}
        onRowClick={onRowClick}
        sortable
        onDataStateChange={this.dataStateChange}
      >
        <GridNoRecords>{locale.serviceLists._noOrders}</GridNoRecords>
        <Column
          {...this.columnProps('listDate')}
          filter="date"
          format="{0:dd.MM.yyyy}"
          width={95}
        />
        <Column {...this.columnProps('listName')} />
        <Column {...this.columnProps('vehicleId')} width={80} />
        <Column
          {...this.columnProps('updated')}
          filter="date"
          format="{0:dd.MM HH:mm}"
          width={95}
        />
        <Column
          {...this.columnProps('started')}
          filter="date"
          format="{0:dd.MM HH:mm}"
          width={95}
        />
        <Column
          width={130}
          sortable={false}
          cell={(gridProps) => (
            <td onClick={() => onRowClick(gridProps)} id="status-bar">
              <StatusBar
                value={gridProps.dataItem.executedOrders}
                target={gridProps.dataItem.totalOrders}
                isLoading={isLoading}
              />
            </td>
          )}
        />
        <Column width={40} cell={this.InfoButtonCell} />
      </Grid>
    );
  }
}

export default ServiceListsGrid;
