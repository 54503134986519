import React from 'react';
import DetailsTooltip from '../shared/customMapLayer/DetailsTooltip';
import PropTypes from 'prop-types';
import moment from 'moment';
import { faHome, faClock } from '@fortawesome/free-solid-svg-icons';

class ServiceOrderTooltip extends React.PureComponent {
  static formatTime(time) {
    if (!time) {
      return '';
    }

    return moment(time, 'YYYY-MM-DDThh:mm:ss.SSS').format('HH:mm:ss');
  }

  render() {
    const { address, time } = this.props;
    const formattedTime = ServiceOrderTooltip.formatTime(time);

    return (
      <DetailsTooltip
        details={[
          { icon: faHome, value: address },
          { icon: faClock, value: formattedTime },
        ]}
      />
    );
  }
}

ServiceOrderTooltip.propTypes = {
  address: PropTypes.string,
  time: PropTypes.instanceOf(Date),
};

export default ServiceOrderTooltip;
