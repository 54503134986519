import React, { Component, ReactNode } from 'react';
import { orderDetailsGridPageSize } from '../../appConfig';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { locale } from '../../common/localization/localizationService';
import { Button } from 'react-bootstrap';

export interface ICustomerSearchGridProps {
  data: Array<any>;
  openMessageDialog: (customer: any) => void;
}

export interface ICustomerSearchGridState {
  dataState: Record<string, any>;
}

export class CustomerSearchGrid extends Component<
  ICustomerSearchGridProps,
  ICustomerSearchGridState
> {
  public readonly state: ICustomerSearchGridState = {
    dataState: {
      sort: [
        {
          dir: 'asc',
          field: 'agreementId',
        },
      ],
      take: orderDetailsGridPageSize,
      skip: 0,
    },
  };

  public dataStateChange(event: Record<string, any>): void {
    this.setState({ ...this.state, dataState: event.data });
  }
  private MessageToCustomer(props: any) {
    const { dataItem } = props;

    return (
      <td colSpan={1} className="auto-resize-column ">
        <Button
          variant="secondary"
          className="send-message-button"
          onClick={() => this.props.openMessageDialog(dataItem)}
        >
          {locale.customerSearchGrid._sendMessage}
        </Button>
      </td>
    );
  }

  public render(): ReactNode {
    const result = process(this.props.data, this.state.dataState);
    return (
      <>
        <Grid
          {...this.state.dataState}
          className={'customer-search-grid'}
          data={result}
          selectedField="selected"
          pageable
          resizable
          pageSize={orderDetailsGridPageSize}
          onDataStateChange={this.dataStateChange.bind(this)}
        >
          <GridNoRecords>{locale.general._noRecords}</GridNoRecords>

          <GridColumn field="agreementId" title={'ID'} width={60} />
          <GridColumn
            field="commune"
            title={locale.customerSearchGrid._municipality}
            width={74}
          />
          <GridColumn
            field="name"
            title={locale.customerSearchGrid._name}
            className="auto-resize-column"
          />
          <GridColumn
            field="estate"
            title={locale.customerSearchGrid._address}
            className="auto-resize-column"
          />
          <GridColumn
            field="estatePostalCode"
            title={locale.customerSearchGrid._zipCode}
            width={98}
          />
          <GridColumn
            field="unitDescription"
            title={locale.customerSearchGrid._unitDesrciption}
            className="auto-resize-column"
          />
          <GridColumn
            cell={this.MessageToCustomer.bind(this)}
            width={145}
            className={'auto-resize-column'}
            sortable={false}
          />
        </Grid>
      </>
    );
  }
}
