import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { locale } from '../../../common/localization/localizationService';
import classNames from 'classnames';
import './routeAnalysisModal.scss';
import RouteAnalysisForm from './RouteAnalysisForm';
import StatusBarWithTime from '../../shared/statusBarWithTime/StatusBarWithTime';
import axios from 'axios';
import { runRouteAnalysis } from '../../../services/AnalysisService';
import { processAnalysisResult } from './AnalysisParsing';
import yup from '../../../common/validation';
import { Formik } from 'formik';
import memoize from 'memoize-one';

const schema = yup.object().shape({
  startStop: yup
    .object()
    .nullable()
    .required()
    .label(locale.routeOptimalizationWizard.routeAnalysisModal._startStopLabel),
  workHours: yup
    .number()
    .required()
    .min(0)
    .max(24)
    .label(locale.routeOptimalizationWizard.routeAnalysisModal._workHoursLabel),
  workMinutes: yup
    .number()
    .required()
    .min(0)
    .max(59)
    .label(
      locale.routeOptimalizationWizard.routeAnalysisModal._workMinutesLabel
    ),
  timePerStop: yup
    .number()
    .required()
    .min(1)
    .label(
      locale.routeOptimalizationWizard.routeAnalysisModal._timePerStopLabel
    ),
  maximumCars: yup
    .number()
    .required()
    .min(1)
    .label(
      locale.routeOptimalizationWizard.routeAnalysisModal._maximumCarsLabel
    ),
});

const initialValues = {
  workHours: 7,
  workMinutes: 0,
  timePerStop: 20,
  maximumCars: 2,
};

function getDepotOptionsFromDepots(depots) {
  return depots.map((depot) => ({
    label: depot.description,
    value: depot.depotId,
  }));
}
const getDepotOptionsFromDepotsMemoized = memoize(getDepotOptionsFromDepots);

class RouteAnalysisModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
    isDepotsLoading: PropTypes.bool.isRequired,
    depots: PropTypes.array.isRequired,
    depotActions: PropTypes.object.isRequired,
    importedRoutes: PropTypes.array.isRequired,
    onAnalysisResults: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onClose: () => {},
    onAnalysisResults: () => {},
    isDepotsLoading: false,
    depots: [],
    depotActions: {},
  };

  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.startAnalysis = this.startAnalysis.bind(this);
    this.handleAnalysisSuccess = this.handleAnalysisSuccess.bind(this);
    this.handleAnalysisFailure = this.handleAnalysisFailure.bind(this);
    this.cancelAnalysis = this.cancelAnalysis.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  state = {
    analysisInProgress: false,
    analysisCancel: null,
  };

  componentDidMount() {
    const { depotActions } = this.props;
    depotActions.getDepots();
  }

  startAnalysis(analysisOptions) {
    const { depots, importedRoutes } = this.props;

    this.setState({ analysisFailed: false });

    const startDepot =
      depots &&
      depots.find((depot) => depot.depotId === analysisOptions.startStop.value);

    const stops = importedRoutes
      .filter((route) => route.latitude && route.longitude)
      .map((route) => ({
        stopId: route.gln,
        latitude: route.latitude,
        longitude: route.longitude,
      }));

    const analysisParams = {
      maxTravelTime:
        analysisOptions.workHours * 60 + analysisOptions.workMinutes,
      timeSpentAtStop: analysisOptions.timePerStop,
      routeCount: analysisOptions.maximumCars,
      startDepot,
      stops,
    };

    const source = axios.CancelToken.source();
    runRouteAnalysis(analysisParams, source.token).then(
      this.handleAnalysisSuccess,
      this.handleAnalysisFailure
    );

    this.setState({
      analysisInProgress: true,
      analysisCancel: source.cancel,
    });
  }

  handleAnalysisFailure(_results) {
    this.setState({
      analysisInProgress: false,
      analysisCancel: null,
      analysisFailed: true,
    });
  }

  handleAnalysisSuccess(results) {
    const { importedRoutes, onAnalysisResults, depots } = this.props;
    const result = processAnalysisResult(results, importedRoutes, depots);

    this.setState({
      analysisInProgress: false,
      analysisCancel: null,
    });

    onAnalysisResults(result);
  }

  cancelAnalysis() {
    const { analysisCancel } = this.state;
    if (analysisCancel) {
      analysisCancel();
    }
    this.setState({
      analysisInProgress: false,
      analysisCancel: null,
    });
  }

  handleClose() {
    const { analysisInProgress } = this.state;
    const { onClose } = this.props;
    if (!analysisInProgress) {
      this.setState({ analysisFailed: false });
      this.formRef.setTouched({});

      onClose();
    }
  }

  render() {
    const { show, isDepotsLoading, depots } = this.props;

    const { analysisInProgress, analysisFailed } = this.state;

    const modalClasses = classNames('route-analysis-modal', {
      'in-progress': analysisInProgress,
    });

    const depotOptions = getDepotOptionsFromDepotsMemoized(depots);
    const initialStartStop = depotOptions.length === 1 ? depotOptions[0] : null;

    return (
      <Formik
        validationSchema={schema}
        onSubmit={this.startAnalysis}
        initialValues={{
          ...initialValues,
          startStop: initialStartStop,
        }}
        innerRef={(ref) => (this.formRef = ref)}
      >
        {({ handleSubmit }) => (
          <Modal
            onHide={this.handleClose}
            show={show}
            centered
            dialogClassName={modalClasses}
          >
            <Modal.Header closeButton={!analysisInProgress}>
              <Modal.Title>
                {locale.routeOptimalizationWizard.routeAnalysisModal._title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <RouteAnalysisForm
                analysisInProgress={analysisInProgress}
                isDepotsLoading={isDepotsLoading}
                depotOptions={depotOptions}
                handleSubmit={handleSubmit}
              />
              {analysisFailed && (
                <p className="analysis-error">
                  {
                    locale.routeOptimalizationWizard.routeAnalysisModal
                      ._analysationError
                  }
                </p>
              )}
              <StatusBarWithTime active={analysisInProgress} />
            </Modal.Body>
            <Modal.Footer>
              {!analysisInProgress && (
                <Button variant="light" onClick={this.handleClose}>
                  {
                    locale.routeOptimalizationWizard.routeAnalysisModal
                      ._closeButton
                  }
                </Button>
              )}
              {analysisInProgress && (
                <Button variant="light" onClick={this.cancelAnalysis}>
                  {
                    locale.routeOptimalizationWizard.routeAnalysisModal
                      ._cancelButton
                  }
                </Button>
              )}
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={analysisInProgress}
              >
                {
                  locale.routeOptimalizationWizard.routeAnalysisModal
                    ._analyseButton
                }
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    );
  }
}

export default RouteAnalysisModal;
