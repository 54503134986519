import memoizeOne from 'memoize-one';
import React from 'react';
import Select from 'react-select';
import { IOrderSatus } from './OrderStatus';

export interface IOrderStatusSelectorProps {
  statuses: Array<IOrderSatus>;
  handleChange: (...args: any) => any;
  selectedStatus: string;
}

export interface IOrderSatusOption {
  label: string;
  value: IOrderSatus;
}

export const getOrderStatusOptions = (
  statuses: Array<IOrderSatus>
): Array<IOrderSatusOption> => {
  return (
    (statuses &&
      statuses.map((status) => ({
        label: status.status,
        value: status,
      }))) ||
    []
  );
};

export const getCustomOrderStatusesOptionsMemoized = memoizeOne(
  getOrderStatusOptions
);

const OrdersStatusSelector = (
  props: IOrderStatusSelectorProps
): React.ReactElement => {
  const { statuses, handleChange, selectedStatus } = props;
  const options = getOrderStatusOptions(statuses);
  return (
    <Select
      options={options}
      onChange={handleChange}
      placeholder={selectedStatus}
      className="order-status-select"
    />
  );
};

export default OrdersStatusSelector;
