import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  faUser,
  faHashtag,
  faCalendar,
  faClock,
  faRoute,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import DetailsTooltip from '../shared/customMapLayer/DetailsTooltip';

class VehicleTooltip extends PureComponent {
  static getDateAndTime(updatedAt) {
    if (!updatedAt) {
      return { date: '', time: '' };
    }

    const dateTime = moment(updatedAt);
    return {
      date: dateTime.format('YYYY-MM-DD'),
      time: dateTime.format('HH:mm:ss'),
    };
  }

  render() {
    const { username, orderId, updatedAt, routeName } = this.props;
    const { date, time } = VehicleTooltip.getDateAndTime(updatedAt);
    return (
      <DetailsTooltip
        details={[
          { icon: faUser, value: username },
          { icon: faHashtag, value: orderId },
          { icon: faRoute, value: routeName },
          { icon: faCalendar, value: date },
          { icon: faClock, value: time },
        ]}
      />
    );
  }
}

VehicleTooltip.propTypes = {
  username: PropTypes.string.isRequired,
  orderId: PropTypes.any,
  routeName: PropTypes.string,
  updatedAt: PropTypes.string.isRequired,
};

export default VehicleTooltip;
