import React, { Component, ReactNode } from 'react';
import { IUser } from '../../models';
import PaneHeadline from '../shared/paneHeadline';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { CustomerSearchBar } from './CustomersSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './customers.scss';
import { CustomerSearchGrid } from './CustomersGrid';
import { locale } from '../../common/localization/localizationService';
import { Spinner2 } from '../shared/spinner2';
import NewMessageDialog from './NewMessageDialog';
import { IVehiclesState as IVehiclesListState } from '../../redux/modules/vehicles/vehiclesState';

export interface ICustomersProps {
  searchCustomers: (searchWord: string) => void;
  filteredCustomers: Array<any>;
  impersonatedUser: any;
  authentication: IUser;
  isLoading: boolean;
  vehicles: IVehiclesListState;
  fetchVehicles: (ignoreCache?: boolean) => Promise<void>;
}

export interface ICustomerState {
  messageDialog: {
    show: boolean;
    customer: any;
  };
}

export class Customers extends Component<ICustomersProps, ICustomerState> {
  public readonly state: ICustomerState = {
    messageDialog: {
      show: false,
      customer: null,
    },
  };
  private searchCustomers(searchWord: string): void {
    this.props.searchCustomers(searchWord);
  }

  private openMessageDialog(customer: any): void {
    this.props.fetchVehicles();
    this.setState({
      ...this.state,
      messageDialog: { customer: customer, show: true },
    });
  }

  private hideMessageDialog(): void {
    this.setState({
      ...this.state,
      messageDialog: { customer: null, show: false },
    });
  }

  public render(): ReactNode {
    const { filteredCustomers, isLoading, vehicles } = this.props;
    return (
      <div>
        <PaneHeadline
          titleText={locale.general._customerRegister}
          titleIcon={faUserFriends}
        />
        <div>
          <div className="d-inline-block mr-2 mb-1">
            <FontAwesomeIcon icon="info-circle" />
          </div>
          <span>{locale.customerRegister.grid._infoText}</span>
        </div>
        <div className="row">
          <div className="col-md-12">
            <CustomerSearchBar
              searchCustomer={this.searchCustomers.bind(this)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 customer-search-grid-wrapper">
            <Spinner2 show={isLoading} />
            {filteredCustomers && (
              <CustomerSearchGrid
                data={filteredCustomers}
                openMessageDialog={this.openMessageDialog.bind(this)}
              />
            )}
          </div>
        </div>
        {this.state.messageDialog.show && (
          <NewMessageDialog
            customer={this.state.messageDialog.customer}
            show={this.state.messageDialog.show}
            onHide={this.hideMessageDialog.bind(this)}
            vehicles={vehicles.vehiclesList}
            user={this.props.authentication}
          />
        )}
      </div>
    );
  }
}

export default Customers;
