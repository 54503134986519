import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuFilter,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { locale } from '../../common/localization/localizationService';
import ColumnMenu from '../shared/ColumnMenu';
import OrderItemTypeCell from '../serviceOrders/OrderItemTypeCell';
import './serviceOrderDetails.scss';
import '../../styles/detailsView.scss';

class ServiceOrderDetailsGrid extends Component {
  constructor(props) {
    super(props);

    this.dataStateChange = this.dataStateChange.bind(this);
  }

  static propTypes = {
    serviceOrders: PropTypes.array,
  };

  static processOrders(serviceOrders, dataState) {
    const processedOrders = serviceOrders.map((order) => ({
      ...order,
      time: order.updated && new Date(order.updated),
    }));

    return process(processedOrders, dataState);
  }

  state = {
    dataState: {
      sort: [{ field: 'time', dir: 'desc' }],
    },
  };

  dataStateChange(event) {
    this.setState({ dataState: event.data });
  }

  columnProps(field) {
    return {
      field: field,
      columnMenu: ColumnMenu,
      headerClassName: this.isColumnActive(field, this.state.dataState)
        ? 'active'
        : '',
      title: locale.serviceOrderDetailsGrid['_' + field],
    };
  }

  isColumnActive(field, dataState) {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  render() {
    const { serviceOrders } = this.props;
    const unitDetails = ServiceOrderDetailsGrid.processOrders(
      serviceOrders,
      this.state.dataState
    );

    return (
      <Grid
        {...this.state.dataState}
        className="mb-3"
        data={unitDetails}
        scrollable={'none'}
        sortable
        onDataStateChange={this.dataStateChange}
      >
        <GridNoRecords>{locale.serviceOrders._noOrders}</GridNoRecords>
        <Column
          {...this.columnProps('time')}
          width={85}
          filter="date"
          format="{0:dd.MM HH:mm}"
        />
        <Column {...this.columnProps('unitName')} />
        <Column {...this.columnProps('description')} />
        <Column
          {...this.columnProps('orderItemType')}
          width={70}
          cell={OrderItemTypeCell}
        />
      </Grid>
    );
  }
}

export default ServiceOrderDetailsGrid;
