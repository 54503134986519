import React, { Component, ReactNode } from 'react';
import CommandCell from '../userAdmin/CommandCell';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { orderDetailsGridPageSize } from '../../appConfig';
import { IDeviation, IDeviationCategory } from '../../models/Deviation';
import { locale } from '../../common/localization/localizationService';

export interface IDeviationGridProps {
  data: Array<IDeviation>;
  className?: string;
  deviationCategoryList: Array<IDeviationCategory>;
  editDeviation: (dataItem: any) => void;
  deleteDeviation: (dataItem: any) => void;
}

export interface IDeviationGridState {
  dataState: Record<string, any>;
}

export class DeviationGrid extends Component<
  IDeviationGridProps,
  IDeviationGridState
> {
  public readonly state: IDeviationGridState = {
    dataState: {
      sort: [
        {
          dir: 'asc',
          field: 'exceptionId',
        },
      ],
      take: orderDetailsGridPageSize,
      skip: 0,
    },
  };

  public dataStateChange(event: Record<string, any>): void {
    this.setState({ ...this.state, dataState: event.data });
  }

  private EditCell(props: any) {
    return (
      <CommandCell {...props} edit={this.props.editDeviation} icon={faEdit} />
    );
  }

  private DeleteCell(props: any) {
    return (
      <CommandCell
        {...props}
        edit={this.props.deleteDeviation}
        icon={faTrash}
      />
    );
  }

  private getCategory(props: any) {
    if (this.props.deviationCategoryList.length) {
      const categoryText = this.props.deviationCategoryList.find(
        (category) => category.categoryId === props.dataItem.categoryId
      ).categoryText;
      if (categoryText) {
        return (
          <>
            <td className="auto-resize-column ">{categoryText}</td>
          </>
        );
      } else {
        return (
          <>
            <td className="auto-resize-column "></td>
          </>
        );
      }
    } else {
      return (
        <>
          <td className="auto-resize-column "></td>
        </>
      );
    }
  }

  public render(): ReactNode {
    const result = process(this.props.data, this.state.dataState);
    const className = this.props.className || '';
    return (
      <Grid
        {...this.state.dataState}
        className={className + ' deviations-grid'}
        data={result}
        selectedField="selected"
        pageable
        resizable
        pageSize={orderDetailsGridPageSize}
        onDataStateChange={this.dataStateChange.bind(this)}
      >
        <GridNoRecords>{locale.general._noRecords}</GridNoRecords>

        <GridColumn field="exceptionId" title={'ID'} width={44} />
        <GridColumn
          field="value"
          title={locale.deviations._deviation}
          className="auto-resize-column"
        />
        <GridColumn
          field="externalExId"
          title={locale.deviations._deviatonExtId}
          className="auto-resize-column"
        />
        <GridColumn
          field="categoryId"
          cell={this.getCategory.bind(this)}
          title={locale.deviations._category}
          className="auto-resize-column"
        />
        <GridColumn
          cell={this.EditCell.bind(this)}
          width={26}
          sortable={false}
        />
        <GridColumn
          cell={this.DeleteCell.bind(this)}
          width={26}
          sortable={false}
        />
      </Grid>
    );
  }
}
