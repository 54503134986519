import React from 'react';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { locale } from '../../../common/localization/localizationService';
import routerConfig from '../../../common/routes';
import BreadcrumbItem from './BreadcrumbItem';
interface ILocationBreadcrumb {
  breadcrumb: {
    props: {
      children: string;
    };
    key: string;
  };
  key: string;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  location: {
    hash: string;
    key: string;
    pathname: string;
    search: string;
    state: Record<string, any>;
  };
}

const routes = [
  { path: '/', breadcrumb: '' },
  {
    path: routerConfig.administration.base,
    breadcrumb: locale.general._administration,
  },
  {
    path: routerConfig.administration.vehicles.base,
    breadcrumb: locale.vehicles._vehicles,
  },
];

export const Breadcrumbs = withBreadcrumbs(routes)((props) => {
  const { breadcrumbs, ...restProps } = props;
  const blackList = ['/'];
  const filteredBreadcrumbs = breadcrumbs.filter(
    (breadcrumb: ILocationBreadcrumb) => {
      return !blackList.includes(breadcrumb.match.url);
    }
  );
  return (
    <div {...restProps}>
      {filteredBreadcrumbs.map(
        (breadcrumb: ILocationBreadcrumb, index, arr) => {
          return (
            <BreadcrumbItem
              key={index}
              breadcrumb={{
                name: breadcrumb.breadcrumb.props.children,
                link: breadcrumb.match.url,
              }}
              isLast={index === arr.length - 1}
            />
          );
        }
      )}
    </div>
  );
});
