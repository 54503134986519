import React from 'react';
import { locale } from '../../common/localization/localizationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

function ExportReportButton({ onClick, disabled }) {
  const title = disabled
    ? locale.reports._nothingToExportTitle
    : locale.reports._exportTitle;

  return (
    <button
      title={title}
      className="btn btn-outline-dark"
      onClick={onClick}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={faFileExcel} />
      &nbsp;
      {locale.reports._export}
    </button>
  );
}

ExportReportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ExportReportButton;
