import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './orderDetails.scss';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { locale } from '../../common/localization/localizationService';
import PaneHeadline from '../shared/paneHeadline';
import Spinner from '../shared/spinner/Spinner';
import OrderDetailsGrid from './OrderDetailsGrid';
import OrderDetailsDiagram from '../orderDetailsDiagram/OrderDetailsDiagram';
import routes from '../../common/routes';
import getChartData from '../orderDetailsDiagram/getChartData';

class OrderDetails extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    order: PropTypes.object,
    orderActions: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    lastOverviewType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    lastOverviewType: routes.orders.base,
  };

  componentDidMount() {
    const { order } = this.props;
    this.setSelectedOrder(order);
    this.getOrder();
  }

  componentDidUpdate(prevProps) {
    const { order } = this.props;
    if (!prevProps || !prevProps.order) {
      this.setSelectedOrder(order);
    }
  }

  componentWillUnmount() {
    this.props.orderActions.setSelectedOrder(null);
  }

  setSelectedOrder(order) {
    if (order) {
      this.props.orderActions.setSelectedOrder(order);
    }
  }

  getOrder() {
    const { orderActions, match } = this.props;

    const orderId = parseInt(match.params.orderId, 10);
    orderActions.getOrderDetails(
      orderId,
      true
    ); /** @todo remove true param, when orders/date endpoint is corrected, https://norsknavigasjon.visualstudio.com/GPSRealtime/_sprints/backlog/Allwin/GPSRealtime/2022/Sprint%2012.12%20-%20Sprint%2012.23?workitem=7473 */
  }

  getBackUrl() {
    const { match, lastOverviewType } = this.props;

    return match.url
      .substring(0, match.url.lastIndexOf('/'))
      .replace(routes.orders.base, lastOverviewType);
  }

  render() {
    const { order, match, isLoading } = this.props;
    const routeName = (order && order.routeData && order.routeData.name) || '';
    const backUrl = this.getBackUrl();
    const orderHeightClass = getChartData(order) ? '' : 'height-without-chart';
    return (
      <div className="order-details-view">
        <PaneHeadline
          titleText={routeName}
          titleIcon={faMapMarkerAlt}
          backUrl={backUrl}
          backTitle={locale.general._orderOverview}
          className="header"
        />
        <div className="order-details-grid">
          {isLoading && <Spinner />}
          <OrderDetailsDiagram orders={order} isLoading={isLoading} />
          <OrderDetailsGrid
            order={order}
            currentUrl={match.url}
            className={orderHeightClass}
          />
        </div>
      </div>
    );
  }
}

export default OrderDetails;
