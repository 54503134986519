import React, { Component, ReactNode } from 'react';
import { IDeviationCategory } from '../../models/Deviation';
import { orderDetailsGridPageSize } from '../../appConfig';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import CommandCell from '../userAdmin/CommandCell';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { locale } from '../../common/localization/localizationService';

export interface IDeviationCategoriesGridProps {
  data: Array<IDeviationCategory>;
  className?: string;
  editDeviationCategory: (dataItem: any) => void;
  deleteDeviationCategory: (dataItem: any) => void;
}

export interface IDeviationCategoriesGridState {
  dataState: Record<string, any>;
}

export class DeviationCategoriesGrid extends Component<
  IDeviationCategoriesGridProps,
  IDeviationCategoriesGridState
> {
  public readonly state: IDeviationCategoriesGridState = {
    dataState: {
      sort: [
        {
          dir: 'asc',
          field: 'exceptionId',
        },
      ],
      take: orderDetailsGridPageSize,
      skip: 0,
    },
  };

  public dataStateChange(event: Record<string, any>): void {
    this.setState({ ...this.state, dataState: event.data });
  }

  private EditCell(props: any) {
    return (
      <CommandCell
        {...props}
        edit={this.props.editDeviationCategory}
        icon={faEdit}
      />
    );
  }

  private DeleteCell(props: any) {
    return (
      <CommandCell
        {...props}
        edit={this.props.deleteDeviationCategory}
        icon={faTrash}
      />
    );
  }

  public render(): ReactNode {
    const result = process(this.props.data, this.state.dataState);
    const className = this.props.className || '';
    return (
      <Grid
        {...this.state.dataState}
        className={className + ' deviations-grid'}
        data={result}
        selectedField="selected"
        pageable
        resizable
        pageSize={orderDetailsGridPageSize}
        onDataStateChange={this.dataStateChange.bind(this)}
      >
        <GridNoRecords>{locale.general._noRecords}</GridNoRecords>

        <GridColumn field="categoryId" title={'ID'} width={44} />
        <GridColumn
          field="categoryText"
          title={locale.deviations._categoryName}
          className="auto-resize-column"
        />
        <GridColumn
          cell={this.EditCell.bind(this)}
          width={26}
          sortable={false}
        />
        <GridColumn
          cell={this.DeleteCell.bind(this)}
          width={26}
          sortable={false}
        />
      </Grid>
    );
  }
}
