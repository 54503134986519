import React, { Component, ReactNode } from 'react';
import yup from '../../common/validation';
import { Formik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { locale } from '../../common/localization/localizationService';
import TextField from '../shared/formikFields/TextField';
import moment from 'moment';
import { getDirectMessageStatus } from '../../utils/GeneralUtils';

export interface IInfoMessageModalProps {
  onHide: () => void;
  selectedMessage?: any;
  show: boolean;
}

export interface IInfoMessageModalState {
  selectedVehicleId: string;
  message: string;
}

export default class InfoMessageModal extends Component<
  IInfoMessageModalProps,
  IInfoMessageModalState
> {
  public readonly state: IInfoMessageModalState = {
    selectedVehicleId: null,
    message: '',
  };

  private getSchema() {
    return yup.object({
      expires: yup
        .date()
        .required()
        .min(moment().startOf('day').toDate())
        .label(locale.customerRegister.message._expires),
    });
  }

  private getDateString(date: string, type: string): string {
    if (date) {
      if (type === 'withTime') {
        return moment(date).format(locale.general._fullMomentWithTime);
      } else if (type === 'fullDate') {
        return moment(date).format(locale.general._fullMomentFormat);
      }
    } else {
      return '';
    }
  }

  private getNewMessage(selectedMessage: any): any {
    const message = {
      agreementId: selectedMessage.agreementId,
      customerId: selectedMessage.customerId,
      date: this.getDateString(selectedMessage.date, 'withTime'),
      expires: this.getDateString(selectedMessage.expires, 'fullDate'),
      received: this.getDateString(selectedMessage.received, 'withTime'),
      paSystem: selectedMessage.paSystem,
      sender: selectedMessage.sender,
      text: selectedMessage.text,
      title: selectedMessage.title,
      vehicleId: selectedMessage.vehicleId,
      address: selectedMessage.address,
      statusText: getDirectMessageStatus(selectedMessage.status),
    };
    return message;
  }

  private handleMessageChange(event: any) {
    this.setState({ ...this.state, message: event.target.value });
  }

  private onSubmit() {
    console.log('clicked');
  }

  public render(): ReactNode {
    const { onHide, selectedMessage, show } = this.props;

    const message = this.getNewMessage(selectedMessage);

    return (
      <>
        <Formik
          onSubmit={() => this.onSubmit()}
          initialValues={{ ...message }}
          validationSchema={this.getSchema()}
        >
          {({ handleSubmit }) => (
            <Modal
              show={show}
              onHide={() => onHide()}
              className="customer-message-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {locale.customerRegister.message._directMessageToCustomer}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form noValidate onSubmit={handleSubmit}>
                  <TextField
                    label={locale.customerRegister.message._sender}
                    name="sender"
                    placeholder={locale.customerRegister.message._sender}
                    disabled={true}
                  />
                  <TextField
                    label={locale.directMessages._date}
                    name="date"
                    placeholder={locale.directMessages._date}
                    disabled={true}
                  />
                  <TextField
                    label={locale.directMessages._expires}
                    name="expires"
                    placeholder={locale.directMessages._expires}
                    disabled={true}
                  />
                  <TextField
                    label={locale.directMessages._read}
                    name="received"
                    placeholder={locale.directMessages._read}
                    disabled={true}
                  />
                  <TextField
                    label={locale.directMessages._vehicle}
                    name="vehicleId"
                    placeholder={locale.directMessages._vehicle}
                    disabled={true}
                  />
                  <TextField
                    label={locale.directMessages._address}
                    name="address"
                    placeholder={locale.directMessages._address}
                    disabled={true}
                  />
                  <TextField
                    label={locale.directMessages._status}
                    name="statusText"
                    placeholder={locale.directMessages._status}
                    disabled={true}
                  />
                  <TextField
                    label={locale.directMessages._title}
                    name="title"
                    placeholder={locale.directMessages._title}
                    disabled={true}
                  />
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <label className="form-label">
                        {locale.customerRegister.message._message}
                      </label>
                    </div>
                    <textarea
                      value={
                        selectedMessage
                          ? selectedMessage.text
                          : this.state.message
                      }
                      onChange={this.handleMessageChange.bind(this)}
                      rows={5}
                      className="customer-message-box"
                      disabled={true}
                    />
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => onHide()}>
                  {locale.directMessages._closeButton}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}
