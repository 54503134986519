import { orderConstants } from './orderConstants';
import { groupBy } from '../../../services/listService';
import { cloneDeep } from 'lodash';
import { locale } from '../../../common/localization/localizationService';

const initialState = {
  data: [],
  selected: null,
  isLoading: false,
  fractions: [],
  allFractions: [],
};

function getSelected(orders, previousSelected) {
  if (!(previousSelected && orders)) {
    return null;
  }

  return orders.find((o) => o.orderId === previousSelected.orderId);
}

function omitEmpty(obj) {
  const result = {};
  for (const key in obj) {
    const value = obj[key];
    if (value && (!Array.isArray(value) || value.length > 0)) {
      result[key] = value;
    }
  }
  return result;
}

function mergeOrders(orderHead, orderDetails) {
  return {
    ...orderHead,
    ...omitEmpty(orderDetails),
  };
}

function mergeOrderHeadsAndDetails(orderHeads, orderDetails) {
  if (!orderHeads || orderHeads.length === 0) {
    return orderDetails;
  }
  if (!orderDetails || orderDetails.length === 0) {
    return orderHeads;
  }

  const mergedOrders = orderHeads.slice();
  for (const order of orderDetails) {
    const index = mergedOrders.findIndex((o) => o.orderId === order.orderId);
    if (index !== -1) {
      mergedOrders[index] = mergeOrders(mergedOrders[index], order);
    } else {
      mergedOrders.push(order);
    }
  }

  const newOrders = mergedOrders.map((order) => {
    const numberOfStops = groupBy(
      order.generatedRoutes,
      (gr) => gr.routeLineId
    ).size;
    const numberOfVisited = groupBy(
      order.generatedRoutes.filter(
        (gr) => gr.reportDetailsView && gr.routeLine
      ),
      (gr) => gr.routeLineId
    ).size;
    const completedStatusId = 1; //Order completed status
    const startedStatusId = 3; //Order started status
    const statusId =
      (order.orderStatus && order.orderStatus.orderStatusId) || startedStatusId;

    if (statusId === completedStatusId && numberOfStops !== numberOfVisited) {
      const orderCopy = cloneDeep(order);
      orderCopy.orderStatus.orderStatusId = startedStatusId; //Order started status
      orderCopy.orderStatus.status = locale.orderStatuses._started;
      return orderCopy;
    }

    return order;
  });

  return newOrders;
}

function getNewOrderDataForDetailsSuccess(state, timestamp, orderDetails) {
  if (state.timestamp < timestamp || !state.timestamp) {
    return orderDetails;
  }
  if (state.timestamp === timestamp) {
    return mergeOrderHeadsAndDetails(state.data, orderDetails);
  }

  return state.data;
}

function getNewOrderDataForHeadsSuccess(state, timestamp, orderHeads) {
  if (state.timestamp < timestamp || !state.timestamp) {
    return orderHeads;
  }
  if (state.timestamp === timestamp) {
    return mergeOrderHeadsAndDetails(orderHeads, state.data);
  }

  return state.data;
}

function getFractions(orders, fractions) {
  if (fractions.length === 0) {
    let filteredFractions = [];
    orders.forEach((order) => {
      order.fractions.forEach((fraction) => {
        filteredFractions.push(fraction);
      });
    });
    filteredFractions = _.uniqBy(filteredFractions, 'fractionId');
    return filteredFractions;
  } else {
    return fractions;
  }
}

export function order(state = initialState, action) {
  switch (action.type) {
    case orderConstants.GET_ORDERS_ORDERHEADS_LOADED: {
      const newData = getNewOrderDataForHeadsSuccess(
        state,
        action.timestamp,
        action.orderHeads
      );
      const fractions = getFractions(newData, state.fractions);

      return {
        ...state,
        data: newData,
        timestamp: Math.max(action.timestamp, state.timestamp || 0),
        selected: getSelected(action.orderHeads, state.selected),
        error: null,
        fractions: fractions,
      };
    }
    case orderConstants.GET_ORDERS_DETAILS_LOADED: {
      const newData = getNewOrderDataForDetailsSuccess(
        state,
        action.timestamp,
        action.orderDetails
      );

      return {
        ...state,
        data: newData,
        timestamp: Math.max(action.timestamp, state.timestamp || 0),
        selected: getSelected(newData, state.selected),
        error: null,
      };
    }
    case orderConstants.GET_ORDERS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case orderConstants.SET_SELECTED_ORDER:
      return {
        ...state,
        selected: action.order,
      };
    case orderConstants.GET_ORDER_DETAILS_SUCCESS: {
      const newData = getNewOrderDataForDetailsSuccess(
        state,
        action.timestamp,
        [action.order]
      );

      return {
        ...state,
        data: newData,
        timestamp: Math.max(action.timestamp, state.timestamp || 0),
        selected: getSelected(newData, state.selected),
        error: null,
      };
    }
    case orderConstants.GET_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case orderConstants.GET_ALL_FRACTIONS_SUCCESS:
      return {
        ...state,
        allFractions: action.fractions,
      };
    case orderConstants.GET_ALL_FRACTIONS_FAILED:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
