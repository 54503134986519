import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { locale } from '../../../common/localization/localizationService';

export default function UnassignedStopsWarning({ unassignedStops }) {
  if (!unassignedStops || unassignedStops.length === 0) {
    return null;
  }

  return (
    <div className="unassigned-stops-warning">
      <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning" />
      <span>{`${unassignedStops.length} ${locale.routeOptimalizationWizard.worklistResults._unassignedWarning}`}</span>
    </div>
  );
}

UnassignedStopsWarning.propTypes = {
  unassignedStops: PropTypes.array,
};

UnassignedStopsWarning.defaultProps = {
  unassignedStops: [],
};
