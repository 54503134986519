import React, { Component, ReactNode } from 'react';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { locale } from '../../common/localization/localizationService';
import CommandCell from '../userAdmin/CommandCell';
import { faEdit, faCog } from '@fortawesome/free-solid-svg-icons';
import routes from '../../common/routes';
import LinkCell from '../shared/linkCell/linkCell';

export interface IVehiclesGridProps {
  data: any;
  isEditEnabled: boolean;
  editVehicle: (dataItem: any) => void;
  className?: string;
}

export interface IVehiclesGridState {
  dataState: Record<string, any>;
}

export class VehiclesGrid extends Component<
  IVehiclesGridProps,
  IVehiclesGridState
> {
  public readonly state: IVehiclesGridState = {
    dataState: {
      sort: [
        {
          dir: 'asc',
          field: 'vehicleId',
        },
      ],
    },
  };

  public dataStateChange(event: Record<string, any>): void {
    this.setState({ ...this.state, dataState: event.data });
  }

  private EditCell(props: any) {
    return (
      <CommandCell {...props} edit={this.props.editVehicle} icon={faEdit} />
    );
  }

  private SettingsCell(props: any) {
    const linkToUrl = `${routes.administration.vehicles.base}/${props.dataItem.vehicleId}`;
    return (
      <LinkCell
        {...props}
        linkToUrl={linkToUrl}
        icon={faCog}
        className="setting"
      />
    );
  }

  public render(): ReactNode {
    const result = process(this.props.data, this.state.dataState);
    const className = this.props.className || '';
    return (
      <Grid
        {...this.state.dataState}
        className={className + ' vehicles-grid'}
        data={result}
        selectedField="selected"
        scrollable={'none'}
        sortable
        onDataStateChange={this.dataStateChange.bind(this)}
      >
        <GridNoRecords>{locale.vehicles._noVehicles}</GridNoRecords>
        <GridColumn
          field="vehicleId"
          title={locale.vehicles._vehicleId}
          width={22}
        />
        <GridColumn
          field="extVehicleId"
          title={locale.vehicles._externalId}
          width={22}
        />
        <GridColumn
          field="description"
          title={locale.vehicles._description}
          className="auto-resize-column"
        />
        <GridColumn
          field="cellular"
          title={locale.vehicles._phoneNr}
          className="auto-resize-column"
        />
        <GridColumn
          field="driver"
          title={locale.vehicles._driver}
          className="auto-resize-column"
        />
        {this.props.isEditEnabled && (
          <GridColumn
            cell={this.EditCell.bind(this)}
            width={26}
            sortable={false}
          />
        )}
        {this.props.isEditEnabled && (
          <GridColumn
            cell={this.SettingsCell.bind(this)}
            width={26}
            sortable={false}
          />
        )}
      </Grid>
    );
  }
}

export default VehiclesGrid;
