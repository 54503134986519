import React, { Component, ReactElement, ReactNode, RefObject } from 'react';
import { isArray as _isArray, isEqual, uniq as _uniq } from 'lodash';
import { Action } from 'redux';
import {
  Grid,
  GridCellProps,
  GridDataStateChangeEvent,
  GridEvent,
  GridExpandChangeEvent,
  GridColumn as Column,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import {
  DataResult,
  State as DataState,
  process,
} from '@progress/kendo-data-query';
import {
  IRoutePoi,
  IRowData,
  IZoomableObject,
  RouteEditMode,
} from '../../models';
import { PoiTheme } from '../../utils/PoiTheme';
import { ZoomableUtility } from '../../utils/ZoomableUtility';
import { defaultConfig, columns, IColumnDefinition } from './RouteAdminConfig';
import { RouteAdminCell as Cell } from './RouteAdminCell';
import { getPois } from './RouteAdminUtils';
import { RouteAdminMenu } from './RouteAdminMenu';
import { MapUtility } from '../map/MapUtility';
import { locale } from '../../common/localization/localizationService';
import { selectedRegion } from '../../appConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

export interface IRouteAdminGridProps {
  pois: Array<IRoutePoi>;
  markedRouteSequenceIds: Array<string>;
  rowData: Record<string, IRowData>;
  gridState: DataState;
  mode: RouteEditMode;
  isRegionFilteringActive: boolean;
  markRouteSequences: (ids: Array<string>) => Action;
  toggleRouteCollapse: (ids: Array<IRowData>) => Action;
  setGridState: (state: DataState) => Action;
  setZoomableObjects: (obj: IZoomableObject) => Action;
  changeRegionFilteringState: () => void;
}

export function createNotInRegionIcon(notInRegion: boolean): ReactElement {
  if (notInRegion) {
    return (
      <div style={{ textAlign: 'center' }}>
        <FontAwesomeIcon icon={faGlobe} className="fa-icon-red" />
      </div>
    );
  } else {
    return <></>;
  }
}

export class RouteAdminGrid extends Component<IRouteAdminGridProps> {
  private grid: RefObject<Grid> = React.createRef();

  public componentDidMount(): void {
    this.selectPois(this.props.pois);
    this.groupAgreementsSelect();
  }

  public componentDidUpdate(prevProps: IRouteAdminGridProps): void {
    if (
      this.props.mode === RouteEditMode.Move &&
      !isEqual(this.props.pois, prevProps.pois) &&
      this.props.isRegionFilteringActive
    ) {
      if (!this.arePoisInRegion(prevProps.pois)) {
        this.props.changeRegionFilteringState();
      }
    }

    const nextNames = this.getRouteNames(this.props.pois);
    const prevNames = this.getRouteNames(prevProps.pois);

    this.removeGroupAgreementsSelect();
    this.groupAgreementsSelect();

    if (nextNames.join(', ') === prevNames.join(', ')) return;

    PoiTheme.resetState(nextNames);
  }

  public componentWillUnmount(): void {
    this.removeGroupAgreementsSelect();
  }

  private groupAgreementsSelect(): void {
    /** @workaround */
    document
      .querySelectorAll(
        `tr.k-grouping-row td:not([colspan="${columns.length + 2}"]) p.k-reset`
      )
      .forEach((el) => {
        el.addEventListener('click', this.headerClickCallback);
      });
    document
      .querySelectorAll(`tr.k-grouping-row td[colspan="${columns.length + 2}"]`)
      .forEach((el) => {
        el.addEventListener('click', this.routeLineClickCallback);
      });
  }

  private removeGroupAgreementsSelect(): void {
    document
      .querySelectorAll(
        `tr.k-grouping-row td:not([colspan="${columns.length + 2}"]) p.k-reset`
      )
      .forEach((el) => {
        el.removeEventListener('click', this.headerClickCallback);
      });
    document
      .querySelectorAll(`tr.k-grouping-row td[colspan="${columns.length + 2}"]`)
      .forEach((el) => {
        el.removeEventListener('click', this.routeLineClickCallback);
      });
  }

  public headerClickCallback = (e: Event): void => {
    const target = e.target as HTMLElement;
    const parent = target.parentElement;
    if (target.nodeName.toLowerCase() !== 'a') {
      parent.click();
    }
  };

  public routeLineClickCallback = (e: Event): void => {
    const target = e.target as HTMLElement;
    if (target.nodeName.toLowerCase() !== 'a') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  public renderHeader(td: ReactElement, props: GridCellProps): ReactElement {
    if (!td) return td;
    const rowType = props.rowType;
    const { field, value } = props.dataItem;
    const pois: Array<IRoutePoi> = getPois(props.dataItem);
    const style: CSSStyleDeclaration = {
      ...(td.props.style || {}),
      cursor: 'pointer',
    };
    const isSelected = pois.some((poi) => this.isSelected(poi));
    const className = `${td.props.className || ''} ${
      isSelected ? 'active' : ''
    }`;

    if (rowType === 'groupHeader' && field === 'routeName') {
      const { color, font } = PoiTheme.get(value);
      style.background = color;
      style.color = font;
    }

    return React.cloneElement(td, {
      ...td.props,
      style,
      className,
      onClick: () => this.onHeaderClick(pois),
      onDoubleClick: () => this.onHeaderDoubleClick(field, pois),
    });
  }

  public renderCell(
    props: GridCellProps,
    groups: Array<{ field: string }>,
    pipe?: (prop: any) => any
  ): ReactElement {
    return (
      <Cell
        onClick={(pois) => this.onClick(pois)}
        isSelected={(poi) => this.isSelected(poi)}
        pipe={pipe}
        {...props}
        groups={groups}
      />
    );
  }

  public getRouteNames(pois: Array<IRoutePoi>): Array<string> {
    return _uniq(pois.map((poi) => poi.routeName)).sort((a, b) =>
      a.localeCompare(b)
    );
  }

  public isSelected(poi: IRoutePoi): boolean {
    return this.props.markedRouteSequenceIds.includes(poi.id);
  }

  public onDataStateChange(event: GridDataStateChangeEvent): void {
    this.props.setGridState(event.data);
  }

  public onExpandChange(event: GridExpandChangeEvent): void {
    const { field, value } = event.dataItem;
    const id = { field, value } as IRowData;
    const rowData = Object.values(this.props.rowData).find((value) =>
      IRowData.equals(value, id)
    );
    const isExpanded = rowData ? !rowData.isExpanded : false;
    this.props.toggleRouteCollapse([{ ...id, isExpanded }]);
  }

  public onHeaderClick(pois: Array<IRoutePoi>): void {
    this.selectPois(pois);
  }

  public onHeaderDoubleClick(field: string, pois: Array<IRoutePoi>): void {
    const fieldIndex = this.props.gridState.group.findIndex(
      (group) => group.field === field
    );
    const children = pois.reduce((memo, poi: any) => {
      return [
        ...memo,
        ...this.props.gridState.group.reduce((memo, group, i) => {
          if (i <= fieldIndex) return memo;
          const field = group.field;
          const value = poi[field];
          const hash = IRowData.hash({ field, value } as IRowData);
          const data = this.props.rowData[hash];
          const isExpanded = data ? data.isExpanded : false;
          return [...memo, { field, value, isExpanded }];
        }, []),
      ];
    }, []);
    const isExpanded = children.some((child) => child.isExpanded === false);
    this.props.toggleRouteCollapse(
      children.map((child) => ({ ...child, isExpanded }))
    );
  }

  public onClick(pois: Array<IRoutePoi>): void {
    this.selectPois(pois);
    this.props.markRouteSequences(pois.map((poi) => poi.id));
  }

  public getZoomable(pois: Array<IRoutePoi>): IZoomableObject {
    return ZoomableUtility.mapToZoomable(pois.map(IRoutePoi.toCoords), {
      priority: 10,
      name: 'SELECTED_POI',
    });
  }

  public arePoisInRegion(pois: IRoutePoi[]): boolean {
    return pois.some((poi) =>
      MapUtility.isInRegion(poi.degLat, poi.degLong, selectedRegion)
    );
  }

  public selectPois(pois: Array<IRoutePoi>): void {
    if (!pois?.length || !this.arePoisInRegion(pois)) return;
    this.props.setZoomableObjects(this.getZoomable(pois));
  }

  public collapseRows(items: Array<IRowData>): Array<IRowData> {
    const { rowData } = this.props;
    for (const id of items) {
      const item: any = id;
      const data = rowData[IRowData.hash(id)];
      item[defaultConfig.expandField] = data ? data.isExpanded : true;
      if (_isArray(item.items)) this.collapseRows(item.items);
    }
    return items;
  }

  public process(dataResult: DataResult): DataResult {
    const data = this.collapseRows(dataResult.data);
    const total = dataResult.total;
    return { data, total };
  }

  public onScroll(event: GridEvent): void {
    const header = this.grid.current['_header'];
    const amount = event.nativeEvent.target.scrollLeft;
    header.setScrollLeft(amount);
  }

  public getWidth(fieldName: string, defaultWidth: string = undefined): string {
    const groups = this.props.gridState.group.map((group) => group.field) || [];
    const isVisible = !groups.includes(fieldName);
    return isVisible ? defaultWidth : '0';
  }

  public render(): ReactNode {
    const { gridState, pois } = this.props;
    const poisWithPositionCheck: IRoutePoi[] = pois.map((poi: IRoutePoi) => {
      return {
        ...poi,
        notInRegion: !MapUtility.isInRegion(
          poi.degLat,
          poi.degLong,
          selectedRegion
        )
          ? 1
          : 0 /**@todo filter not working, but sort is working*/,
      };
    });

    return (
      <Grid
        ref={this.grid}
        data={this.process(process(poisWithPositionCheck, gridState))}
        onDataStateChange={(e) => this.onDataStateChange(e)}
        onExpandChange={(e) => this.onExpandChange(e)}
        onRowClick={(e) => this.onClick(getPois(e.dataItem))}
        onScroll={(e) => this.onScroll(e)}
        cellRender={(td, props) => this.renderHeader(td, props)}
        {...defaultConfig}
        {...gridState}
      >
        <GridNoRecords>{locale.routeAdminPage._noRecords}</GridNoRecords>

        {columns.map((column: IColumnDefinition) => {
          return (
            <Column
              key={column.field}
              field={column.field}
              title={column.title}
              width={this.getWidth(column.field, column.width)}
              filter={column.filter}
              columnMenu={RouteAdminMenu}
              cell={(props) =>
                this.renderCell(props, gridState.group, column.pipe)
              }
            />
          );
        })}
      </Grid>
    );
  }
}
