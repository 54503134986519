import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { locale } from '../../common/localization/localizationService';
import fielDataLogo from '../../images/fieldata_logo.png';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="login-form login-button-wrapper">
      <div className="row justify-content-center login-card">
        <div className="header-text">
          <h3>GPS REALTIME</h3>
          <span>Waste Management System</span>
        </div>
        <div className="my-auto login-content">
          <div className="login-wrapper">
            <div className="welcome-message">
              <span>{locale.login._welcome}</span>
              <span>{locale.login._pressLogin}</span>
            </div>
            <form className="panel-login">
              <div className="panel-body">
                <button
                  className="btn btn-default btn-block login-button"
                  onClick={() =>
                    loginWithRedirect({
                      appState: { returnTo: '/' },
                    })
                  }
                >
                  {locale.login._submit}
                </button>
              </div>
            </form>
            <div className="info-message">
              <div>{locale.login._ifAssistance}</div>
              <div>{locale.login._pleaseContact}</div>
            </div>
            <div className="placeholder-top">
              <div className="logo-wrapper">
                <img className="fieldata-logo" src={fielDataLogo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginButton;
