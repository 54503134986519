import { extractRouteStopDetailsForStatistics } from '../shared/routeStopUtility';
import { average, sum } from '../shared/mathUtility';
import { autoWeightUnitSettings } from '../../appConfig';
import { locale } from '../../common/localization/localizationService';

const weightUnits = {
  INDETERMINABLE: 0,
  KG: 1,
  TON: 2,
};

function addDeviationToMap(deviation, deviationsMap) {
  const count = deviationsMap.get(deviation) || 0;
  deviationsMap.set(deviation, count + 1);
}

function getStatisticsInitialValue() {
  return {
    deviations: new Map(),
    executionStatus: {
      yes: 0,
      no: 0,
    },
    dataButtons: new Map(),
  };
}

function processDeviations(unitDeviations, aggregatedDeviations) {
  if (unitDeviations) {
    unitDeviations.forEach((deviation) =>
      addDeviationToMap(deviation, aggregatedDeviations)
    );
  }
}

function processExecutesStatus(executed, aggregatedExecutionStatus) {
  if (executed === 'Ja') {
    aggregatedExecutionStatus.yes += 1;
  } else if (executed === 'Nei') {
    aggregatedExecutionStatus.no += 1;
  }
}

function processDataButtons(dataButtonValues, aggregatedDataButtons) {
  if (!dataButtonValues) {
    return;
  }

  dataButtonValues.map((dataButton) => {
    const { tag, text, value } = dataButton;
    const entry = aggregatedDataButtons.get(tag);
    if (entry) {
      entry.values.push(value);
    } else {
      aggregatedDataButtons.set(tag, { text, values: [value] });
    }
  });
}

function routeStopReducer(userDataButtons, statistics, routeStop) {
  const details = extractRouteStopDetailsForStatistics(
    routeStop,
    userDataButtons
  );
  processDeviations(details.unitDeviations, statistics.deviations);
  processExecutesStatus(details.executed, statistics.executionStatus);
  processDataButtons(details.dataButtonValues, statistics.dataButtons);

  return statistics;
}

function getAggregateFunction(dataButtonName) {
  if (dataButtonName.includes('%')) {
    return average;
  }

  return sum;
}

function getUnit(values) {
  const valuesLowerThanBound = values.filter(
    (x) => x < autoWeightUnitSettings.bound
  ).length;

  if (valuesLowerThanBound / values.length >= autoWeightUnitSettings.treshold) {
    return weightUnits.TON;
  }
  if (
    1 - valuesLowerThanBound / values.length >=
    autoWeightUnitSettings.treshold
  ) {
    return weightUnits.KG;
  }

  return weightUnits.INDETERMINABLE;
}

function aggregateDataButtonValues(dataButtons) {
  const result = [];
  if (!dataButtons) {
    return result;
  }

  for (const [dataButtonTag, entry] of dataButtons) {
    const aggregateFunction = getAggregateFunction(entry.text);
    const aggregate = {
      text: entry.text,
      value: aggregateFunction(entry.values),
    };
    if (
      dataButtonTag === 'Weight' &&
      getUnit(entry.values) !== weightUnits.INDETERMINABLE
    ) {
      if (aggregate.value > 1000) {
        aggregate.unit = locale.general._ton;
        aggregate.value /= 1000;
      } else {
        aggregate.unit = locale.general._kg;
      }
    }
    result.push(aggregate);
  }

  return result;
}

function hasExecutedRouteStop(orders) {
  return (
    orders &&
    orders.some(
      (order) =>
        order.generatedRoutes &&
        order.generatedRoutes.some((routeStop) => routeStop.reportDetailsView)
    )
  );
}

function mergeDeviations(orderStatistics) {
  return orderStatistics.map((s) => s.deviations).reduce(deviationsReducer);
}

function deviationsReducer(destination, source) {
  for (const [key, value] of source) {
    destination.set(key, value + (destination.get(key) || 0));
  }
  return destination;
}

function convertToKg(values, keepWeightWithoutUnits) {
  const unit = getUnit(values);
  if (unit === weightUnits.INDETERMINABLE) {
    if (keepWeightWithoutUnits) {
      return values;
    }
    return [];
  }

  if (unit === weightUnits.TON) {
    return values.map((w) => w * 1000);
  }

  return values;
}

function normalizeWeight(dataButtons, keepWeightWithoutUnits = false) {
  const result = new Map();
  for (const [key, entry] of dataButtons) {
    let valuesToAdd = entry.values;
    if (key === 'Weight') {
      valuesToAdd = convertToKg(entry.values, keepWeightWithoutUnits);
    }
    result.set(key, { text: entry.text, values: valuesToAdd });
  }

  return result;
}

function mergeDataButtons(orderStatistics) {
  return orderStatistics
    .map((s) => normalizeWeight(s.dataButtons))
    .reduce(dataButtonsReducer);
}

function dataButtonsReducer(destination, source) {
  for (const [key, entry] of source) {
    const destinationValue = destination.get(key);
    if (destinationValue) {
      destinationValue.values = destinationValue.values.concat(entry.values);
    } else {
      destination.set(key, { ...entry });
    }
  }

  return destination;
}

function mergeExecutionStatuses(orderStatistics) {
  return orderStatistics
    .map((s) => s.executionStatus)
    .reduce(executionStatusReducer);
}

function executionStatusReducer(destination, source) {
  destination.yes += source.yes;
  destination.no += source.no;
  return destination;
}

export function getStatisticsForOrders(orders, userDataButtons) {
  if (!hasExecutedRouteStop(orders)) {
    return null;
  }

  const reducer = routeStopReducer.bind(this, userDataButtons);
  const orderStatistics = orders.map((order) =>
    order.generatedRoutes.reduce(reducer, getStatisticsInitialValue())
  );

  return {
    deviations: mergeDeviations(orderStatistics),
    executionStatus: mergeExecutionStatuses(orderStatistics),
    dataButtons: aggregateDataButtonValues(mergeDataButtons(orderStatistics)),
  };
}
