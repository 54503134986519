import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import React, { Component, ReactNode } from 'react';
// import { locale } from '../../common/localization/localizationService';
import CommandCell from '../userAdmin/CommandCell';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { locale } from '../../common/localization/localizationService';

export interface IContractorsGridProps {
  data: any;
  className?: string;
  editContractor: (dataItem: any) => void;
  deleteContractor: (dataItem: any) => void;
}

export interface IContractorsGridState {
  dataState: Record<string, any>;
}

export class ContractorsGrid extends Component<
  IContractorsGridProps,
  IContractorsGridState
> {
  public readonly state: IContractorsGridState = {
    dataState: {
      sort: [
        {
          dir: 'asc',
          field: 'contractorId',
        },
      ],
    },
  };

  public dataStateChange(event: Record<string, any>): void {
    this.setState({ ...this.state, dataState: event.data });
  }

  private EditCell(props: any) {
    return (
      <CommandCell {...props} edit={this.props.editContractor} icon={faEdit} />
    );
  }

  private DeleteCell(props: any) {
    return (
      <CommandCell
        {...props}
        edit={this.props.deleteContractor}
        icon={faTrash}
      />
    );
  }

  public render(): ReactNode {
    const result = process(this.props.data, this.state.dataState);
    const className = this.props.className || '';
    return (
      <Grid
        {...this.state.dataState}
        className={className + ' contractors-grid'}
        data={result}
        selectedField="selected"
        scrollable={'none'}
        sortable
        onDataStateChange={this.dataStateChange.bind(this)}
      >
        <GridNoRecords>{locale.general._noRecords}</GridNoRecords>

        <GridColumn field="contractorId" title={'ID'} width={22} />
        <GridColumn
          field="contractorName"
          title={locale.contractors._name}
          className="auto-resize-column"
        />
        <GridColumn
          field="extContractorId"
          title={locale.contractors._externalId}
          className="auto-resize-column"
        />
        <GridColumn
          cell={this.EditCell.bind(this)}
          width={26}
          sortable={false}
        />
        <GridColumn
          cell={this.DeleteCell.bind(this)}
          width={26}
          sortable={false}
        />
      </Grid>
    );
  }
}

export default ContractorsGrid;
