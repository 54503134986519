import React from 'react';
import PropTypes from 'prop-types';
import { locale } from '../../common/localization/localizationService';

export default function OrderPictures({ orderDetails }) {
  if (!orderDetails || !orderDetails.pictures) {
    return null;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h3>{locale.routeStopDetails._pictures}</h3>
      </div>
      <div className="card-body">
        <div className="row pictures-row">
          {orderDetails.pictures.map((url) => (
            <div key={url} className="col-md-2 picture">
              <a href={url} target="_blank" rel="noopener noreferrer">
                <img src={url} />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

OrderPictures.propTypes = {
  orderDetails: PropTypes.object,
};
