import { GridProps } from '@progress/kendo-react-grid';
import { locale } from '../../common/localization/localizationService';
import { createNotInRegionIcon } from './RouteAdminGrid';

export const defaultConfig: GridProps = {
  columnVirtualization: false,
  resizable: true,
  reorderable: true,
  filterable: false,
  sortable: true,
  pageable: { pageSizes: [10, 20, 50, 100, 500] },
  groupable: true,
  expandField: 'expanded',
  scrollable: 'scrollable',
};

export interface IColumnDefinition {
  field: string;
  title: string;
  filter: 'boolean' | 'text' | 'numeric' | 'date';
  width?: string;
  pipe?: (prop: any) => any;
}

export const columns: Array<IColumnDefinition> = [
  {
    field: 'routeName',
    title: locale.routeAdminPage._route,
    filter: 'text',
  },
  {
    field: 'description',
    title: locale.routeAdminPage._description,
    filter: 'text',
  },
  {
    field: 'name',
    title: locale.routeAdminPage._name,
    filter: 'text',
  },
  {
    field: 'routeLineId',
    title: locale.routeAdminPage._routeLineId,
    filter: 'text',
  },
  {
    field: 'estate',
    title: locale.routeAdminPage._estate,
    filter: 'text',
  },
  {
    field: 'physicalAmount',
    title: locale.routeAdminPage._physicalAmount,
    filter: 'numeric',
    width: '75px',
  },
  {
    field: 'unitName',
    title: locale.routeAdminPage._unitName,
    filter: 'text',
  },
  {
    field: 'notInRegion',
    title: '\u00A0',
    filter: 'boolean',
    pipe: createNotInRegionIcon,
    width: '40px',
  },
];
