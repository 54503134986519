import wasteManagementClient from './wasteManagementClient';
import { wasteManagementUrls } from '../appConfig';

export default class AgreementsService {
  static getAgreements(idList) {
    return wasteManagementClient.post(wasteManagementUrls.agreements, idList).then(response => {
      if (response.data && response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    }).catch(error => {
      console.error(error);
      throw error;
    });
  }
}
