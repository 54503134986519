import React, { Component, ReactNode } from 'react';
import {
  IAddNewDeviationCategory,
  IDeviationCategory,
} from '../../models/Deviation';
import { Formik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import TextField from '../shared/formikFields/TextField';
import { locale } from '../../common/localization/localizationService';
import { debounceEventHandler } from '../shared/submitFormUtility';
import yup from '../../common/validation';
import DeviationsService from '../../services/deviationsService';
import { Toastr } from '../../utils/Toastr';

export interface IDeviationCategoryDialogProps {
  customerId: number;
  deviationCategory: IDeviationCategory;
  deviationCategories: Array<IDeviationCategory>;
  show: boolean;
  onHide: (renderer: boolean) => void;
}

export default class DeviationCategoryDialog extends Component<IDeviationCategoryDialogProps> {
  private async onSubmit(
    category: IDeviationCategory | IAddNewDeviationCategory,
    isNew: boolean
  ): Promise<void> {
    if (isNew) {
      try {
        DeviationsService.addNewCategory(category);
        Toastr.success(locale.deviations._addCategorySuccess);
      } catch (error) {
        Toastr.error(error);
      } finally {
        this.props.onHide(true);
      }
    } else {
      if ('categoryId' in category) {
        try {
          await DeviationsService.updateCategory(category);
          Toastr.success(locale.deviations._editCategorySuccess);
        } catch (error) {
          Toastr.error(error);
        } finally {
          this.props.onHide(true);
        }
      }
    }
  }

  private newDeviationCategory(): IAddNewDeviationCategory {
    const newCategory: IAddNewDeviationCategory = {
      categoryText: '',
      customerId: this.props.customerId,
    };
    return newCategory;
  }

  private getSchema() {
    const categoryNames = this.props.deviationCategories.map((category) => {
      return category.categoryText;
    });
    return yup.object({
      categoryText: yup
        .string()
        .max(50)
        .required()
        .notOneOf(categoryNames)
        .label(locale.deviations._categoryName),
    });
  }

  public render(): ReactNode {
    const isNew = !this.props.deviationCategory;
    const category =
      this.props.deviationCategory || this.newDeviationCategory();
    const { show } = this.props;
    const onHide = this.props.onHide;

    return (
      <Formik
        onSubmit={(category: IDeviationCategory | IAddNewDeviationCategory) =>
          this.onSubmit(category, isNew)
        }
        initialValues={{ ...category }}
        validationSchema={this.getSchema()}
      >
        {({ handleSubmit }) => (
          <Modal
            show={show}
            onHide={() => onHide(false)}
            className="user-edit-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {category.categoryText
                  ? locale.vehicles._edit + ' ' + category.categoryText
                  : locale.deviations._addNewCategory}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form noValidate onSubmit={handleSubmit}>
                <TextField
                  name="categoryText"
                  label={locale.deviations._categoryName}
                  placeholder={locale.deviations._categoryName}
                />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => onHide(false)}>
                {locale.vehicles._cancel}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={debounceEventHandler(handleSubmit, 250)}
              >
                {isNew ? locale.vehicles._submit : locale.vehicles._update}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    );
  }
}
