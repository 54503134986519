import React from 'react';
import PropTypes from 'prop-types';
import { locale } from '../../common/localization/localizationService';

function ReportSelector({ selectedReport, handleChange, reports }) {
  return (
    <div className="form-group row">
      <label className="col col-form-label" htmlFor="report-select">
        {locale.reports._selectReport}:
      </label>
      <select
        value={selectedReport}
        onChange={handleChange}
        className="col form-control report-select"
        id="report-select"
      >
        {reports.map((report) => (
          <option key={report.reportId} value={report.reportId}>
            {report.reportName}
          </option>
        ))}
      </select>
    </div>
  );
}

ReportSelector.propTypes = {
  selectedReport: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  reports: PropTypes.array,
};

ReportSelector.defaultProps = {
  reports: [],
};

export default ReportSelector;
