import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuFilter,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import StatusBar from '../shared/StatusBar';
import { locale } from '../../common/localization/localizationService';
import ColumnMenu from '../shared/ColumnMenu';
import InfoButtonCell from '../shared/infoButtonCell/InfoButtonCell';
import routes from '../../common/routes';
import { average, clamp } from '../shared/mathUtility';
import moment from 'moment';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import CommandCell from '../userAdmin/CommandCell';
import { getOrderStatus } from '../../utils/GeneralUtils';

const minFractionsWidth = 75;
const maxFractionsWidth = 150;

class OrdersGrid extends Component {
  constructor(props) {
    super(props);

    this.dataStateChange = this.dataStateChange.bind(this);
    this.InfoButtonCell = this.InfoButtonCell.bind(this);
    this.UpdateOrderCell = this.UpdateOrderCell.bind(this);
  }

  state = {
    dataState: {
      sort: [
        {
          dir: 'desc',
          field: 'orderStatus.status',
        },
      ],
    },
  };

  static getVehicleIds(order) {
    if (!order || !order.logHeadVehicles) {
      return '';
    }

    const vehicleIds = [
      ...new Set(order.logHeadVehicles.map((lhv) => lhv.vehicleId)),
    ];
    return vehicleIds.join(' ');
  }

  static getOrderName(order) {
    return order.message || (order.routeData && order.routeData.name);
  }

  static processData(data, selected, dataState) {
    const extendedData = data.map((order) => ({
      ...order,
      orderDisplayName: this.getOrderName(order),
      selected: selected ? order.orderId === selected.orderId : false,
      vehicleId: OrdersGrid.getVehicleIds(order),
      date: new Date(order.date),
      updated: new Date(order.updated),
      allRouteStops: order.orderCompletionStatistics.generatedRoutesCount,
      visitedRouteStops: order.orderCompletionStatistics.reportsCount,
    }));

    return process(extendedData, dataState);
  }

  static getFractionsWidth(orders) {
    if (!orders || orders.length === 0) {
      return minFractionsWidth;
    }

    const fractionLengths = orders.map((order) =>
      order.fractions.reduce((sum, frac) => sum + frac.name.length, 0)
    );

    return clamp(
      average(fractionLengths) * 11,
      minFractionsWidth,
      maxFractionsWidth
    );
  }

  dataStateChange(event) {
    this.setState({ dataState: event.data });
  }

  columnProps(field) {
    return {
      field: field,
      columnMenu: ColumnMenu,
      headerClassName: this.isColumnActive(field, this.state.dataState)
        ? 'active'
        : '',
    };
  }

  isColumnActive(field, dataState) {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  getDatePathParam(dateString) {
    const parsedDate = moment(dateString, 'YYYY-MM-DD', true);
    const date = parsedDate.isValid() ? parsedDate : moment();

    return date.format('YYYY-MM-DD');
  }

  InfoButtonCell(gridProps) {
    const { match } = this.props;
    const date = this.getDatePathParam(match.params.date);

    return (
      <InfoButtonCell
        linkToUrl={`${routes.orders.base}/${date}/${gridProps.dataItem.orderId}`}
        {...gridProps}
      />
    );
  }

  UpdateOrderCell(gridProps) {
    return (
      <>
        <CommandCell
          {...gridProps}
          edit={this.props.updateOrder}
          icon={faEdit}
        />
      </>
    );
  }

  checkStatusbarVisibility(rows, date) {
    const currentDate = new Date();
    const urlDate = new Date(date);

    if (currentDate < urlDate) {
      rows.forEach((row) => {
        row.isStatusbarHidden = true;
      });
    } else {
      rows.forEach((row) => {
        if (row && row.orderStatus && row.orderStatus.orderStatusId === 0) {
          row.isStatusbarHidden = true;
        }
      });
    }

    return rows;
  }

  formatStatus(props) {
    let statusString = '';
    if (props.dataItem.orderStatus) {
      statusString = getOrderStatus(props.dataItem.orderStatus.orderStatusId);
    }
    return (
      <>
        <td className="auto-resize-column ">{statusString}</td>
      </>
    );
  }

  render() {
    const { data, selected, onRowClick, isLoading, match } = this.props;
    const { date } = match.params;
    const result = OrdersGrid.processData(data, selected, this.state.dataState);
    const fractionsWidth = OrdersGrid.getFractionsWidth(result.data);
    result.data = this.checkStatusbarVisibility(result.data, date);

    const isColumnsHidden = new Date() < new Date(date) ? true : false;
    return (
      <Grid
        className="orders-grid mb-3"
        data={result}
        {...this.state.dataState}
        selectedField="selected"
        scrollable={'none'}
        onRowClick={onRowClick}
        sortable
        resizable
        onDataStateChange={this.dataStateChange}
      >
        <GridNoRecords>{locale.general._noRecords}</GridNoRecords>
        <Column
          {...this.columnProps('orderDisplayName')}
          title={locale.orders._route}
          width="auto"
        />
        <Column
          title={locale.orders._fraction}
          width={fractionsWidth}
          cell={(gridProps) => (
            <td onClick={() => onRowClick(gridProps)}>
              {gridProps.dataItem.fractions &&
                gridProps.dataItem.fractions
                  .filter(
                    (frac, index, self) =>
                      index === self.findIndex((t) => t.name === frac.name)
                  ) // This ensures each name appears only once
                  .map((frac) => <div key={frac.name}>{frac.name}</div>)}
            </td>
          )}
        />
        {!isColumnsHidden && (
          <Column
            {...this.columnProps('vehicleId')}
            title={locale.orders._vehicleId}
            width="80px"
          />
        )}
        <Column
          {...this.columnProps('date')}
          title={locale.orders._start}
          width="95px"
          filter="date"
          format="{0:dd.MM HH:mm}"
        />
        <Column
          {...this.columnProps('updated')}
          title={locale.orders._update}
          width="95px"
          filter="date"
          format="{0:dd.MM HH:mm}"
        />
        <Column
          {...this.columnProps('orderStatus.status')}
          title={locale.orders._status}
          width="100px"
          cell={this.formatStatus.bind(this)}
        />
        {!isColumnsHidden && (
          <Column
            width="130px"
            sortable={false}
            cell={(gridProps) => {
              const numberOfStops = gridProps.dataItem.allRouteStops;
              const numberOfVisited = gridProps.dataItem.visitedRouteStops;

              return (
                <td onClick={() => onRowClick(gridProps)} id="status-bar">
                  <StatusBar
                    value={numberOfVisited}
                    target={numberOfStops}
                    isLoading={isLoading}
                    isHidden={gridProps.dataItem.isStatusbarHidden}
                  />
                </td>
              );
            }}
          />
        )}
        <Column width="40px" cell={this.UpdateOrderCell} />
        <Column width="40px" cell={this.InfoButtonCell} />
      </Grid>
    );
  }
}

OrdersGrid.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  data: PropTypes.array,
  selected: PropTypes.object,
  match: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateOrder: PropTypes.func,
};

export default OrdersGrid;
