import React, { Component, ReactNode } from 'react';
import PopupTooltip from '../popupTooltip/PopupTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './customTooltip.scss';
import { IRouteStop } from '../../../models';
import {
  faBuilding,
  faClock,
  faExclamationCircle,
  faHome,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { extractRouteStopDetails } from '../routeStopUtility';
import orderDetailsActions from '../../../redux/modules/orderDetails/orderDetailsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

export interface IDetailsTooltipProps {
  // details: Array<any>;
  stops: Array<IRouteStop>;
  setSelectedRouteStop: (routeStop: any) => void;
}

class DetailsTooltip extends Component<IDetailsTooltipProps> {
  public formatTime(time: Date): string {
    if (!time) return '';
    return moment(time, 'YYYY-MM-DDThh:mm:ss.SSS').format('HH:mm:ss');
  }

  private onRowClick(item: any): void {
    this.props.setSelectedRouteStop(item.stop);
  }

  public render(): ReactNode {
    const { stops } = this.props;

    if (!stops) {
      return null;
    }

    const allDetails = stops.reduce((acc, stop) => {
      const details = extractRouteStopDetails(stop);
      const time = this.formatTime(details.time);
      const deviationValue = details.unitDeviations
        ? details.unitDeviations
            .map((deviation: { someProperty: any }) => deviation.someProperty)
            .join(', ')
        : null;
      acc.push(
        { stop: stop, icon: faBuilding, value: details.companyName },
        { stop: stop, icon: faHome, value: details.address },
        { stop: stop, icon: faClock, value: time },
        {
          stop: stop,
          icon: faExclamationCircle,
          value: deviationValue,
        },
        { stop: stop, icon: faTrash, value: stop.agreementLine?.type }
      );
      acc.push({ icon: null, value: 'placeholder' });
      return acc;
    }, []);

    allDetails.pop();

    const nonEmptyDetails = allDetails.filter((detail) => !!detail.value);

    if (nonEmptyDetails.length === 0) {
      return null;
    }

    const routeLineIds = nonEmptyDetails
      .map((e) => {
        if (e.stop) {
          return e.stop.routeLineId;
        }
      })
      .filter((e) => e !== undefined);

    const bodyKey = routeLineIds
      .filter((rl, index) => routeLineIds.indexOf(rl) === index)
      .join('');

    return (
      <PopupTooltip className="custom-tooltip">
        <table>
          <tbody key={bodyKey}>
            {nonEmptyDetails.map((detail, index) => (
              <React.Fragment key={`${bodyKey}-${index}`}>
                {detail.icon ? (
                  <tr
                    key={`${detail.stop.routeLineId}-${index}`}
                    onClick={() => this.onRowClick(detail)}
                    className="tooltip-row"
                  >
                    <td>
                      <FontAwesomeIcon icon={detail.icon} fixedWidth />
                    </td>
                    <td>{detail.value}</td>
                  </tr>
                ) : (
                  <tr
                    className="tooltip-row-separator"
                    key={`${bodyKey}-${index}-separator`}
                  ></tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </PopupTooltip>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    setSelectedRouteStop: bindActionCreators(
      orderDetailsActions.setSelectedRouteStop,
      dispatch
    ),
  };
}

// Connect the component to the Redux store
export default connect(null, mapDispatchToProps)(DetailsTooltip);
