/* eslint-disable react/no-deprecated */
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner2 } from '../shared/spinner2';
import './login.scss';
import { Dispatch } from 'redux';
import LoginButton from './logInButton';
import { WithAuth0Props, useAuth0, withAuth0 } from '@auth0/auth0-react';

export interface ILoginProps extends WithAuth0Props {
  login: (authResult: any) => (dispatch: Dispatch) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
  token: string;
}

function Login(props: ILoginProps) {
  const { isLoading } = props;
  const { isAuthenticated } = useAuth0();
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    const t = setInterval(() => {
      setShowComponent(!showComponent);
    }, 1000);

    return () => clearInterval(t);
  });

  if (isAuthenticated) {
    props.login(props.auth0);
    return <>{showComponent && <Redirect to="/" />}</>;
  }

  return (
    <>
      <div className="login-page">
        <Spinner2 show={isLoading} />
        {!props.auth0.isLoading && !props.auth0.isAuthenticated && (
          <LoginButton />
        )}
      </div>
    </>
  );
}

export default withAuth0(Login);
