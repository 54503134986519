import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { locale } from '../../../common/localization/localizationService';
import { getWorklistGeneratedName } from '../WorklistUtility';
import WorklistRow from './WorklistRow';
import './createWorklistsModal.scss';
import ServiceOrderService from '../../../services/ServiceOrderService';
import StatusBarWithTime from '../../shared/statusBarWithTime/StatusBarWithTime';

class CreateWorklistsModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    show: PropTypes.bool,
    worklists: PropTypes.array.isRequired,
  };

  static defaultProps = {
    onClose: () => {},
    onSave: () => {},
    worklists: [],
  };

  constructor(props) {
    super(props);

    this.updateListNames = this.updateListNames.bind(this);
    this.handleNameChanged = this.handleNameChanged.bind(this);
    this.saveWorklists = this.saveWorklists.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  state = {
    listNames: [],
    saveInProgress: false,
    savingFailed: false,
  };

  componentDidMount() {
    this.updateListNames();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const { worklists } = this.props;

    if (
      worklists !== prevProps.worklist ||
      worklists.length !== prevProps.worklists.length
    ) {
      this.updateListNames();
    }
  }

  updateListNames() {
    const { worklists } = this.props;
    const { listNames } = this.state;

    if (worklists.length < listNames.length) {
      this.setState({ listNames: listNames.slice(0, worklists.length) });
    } else if (worklists.length > listNames.length) {
      const newItemCount = worklists.length - listNames.length;
      const newNames = Array.from({ length: newItemCount }, (_, i) =>
        getWorklistGeneratedName(listNames.length + i)
      );

      this.setState({ listNames: [...listNames, ...newNames] });
    }
  }

  handleNameChanged(event) {
    const { listNames } = this.state;

    const index = Number(event.target.name);
    const newName = event.target.value;

    const newListNames = listNames.slice();
    newListNames[index] = newName;

    this.setState({ listNames: newListNames });
  }

  saveWorklists() {
    const { worklists, onSave } = this.props;
    const { listNames } = this.state;

    const serviceListDtos = worklists.map((worklist, i) => ({
      name: listNames[i],
      listItems: worklist,
    }));

    this.setState({
      saveInProgress: true,
      savingFailed: false,
    });
    ServiceOrderService.saveServiceLists(serviceListDtos)
      .then(onSave)
      .catch(() => {
        if (this._isMounted) {
          this.setState({ savingFailed: true });
        }
      })
      .finally(() => {
        if (this._isMounted) {
          this.setState({ saveInProgress: false });
        }
      });
  }

  handleClose() {
    const { saveInProgress } = this.state;
    const { onClose } = this.props;

    if (!saveInProgress) {
      onClose();
    }
  }

  render() {
    const { show, worklists } = this.props;
    const { listNames, saveInProgress, savingFailed } = this.state;

    return (
      <Modal
        onHide={this.handleClose}
        show={show}
        centered
        dialogClassName="create-work-lists-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {locale.routeOptimalizationWizard.createWorklistsModal._title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table">
            <thead>
              <tr>
                <th>
                  {
                    locale.routeOptimalizationWizard.createWorklistsModal
                      ._listName
                  }
                </th>
                <th>
                  {
                    locale.routeOptimalizationWizard.createWorklistsModal
                      ._itemCount
                  }
                </th>
              </tr>
            </thead>
            <tbody>
              {worklists.map((worklist, i) => (
                <WorklistRow
                  key={i}
                  index={i}
                  listName={listNames[i]}
                  worklist={worklist}
                  onNameChanged={this.handleNameChanged}
                  disabled={saveInProgress}
                />
              ))}
            </tbody>
          </table>
          {savingFailed && (
            <p className="save-error">
              {locale.routeOptimalizationWizard.createWorklistsModal._saveError}
            </p>
          )}
          <StatusBarWithTime active={saveInProgress} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={this.handleClose}
            disabled={saveInProgress}
          >
            {locale.routeOptimalizationWizard.createWorklistsModal._closeButton}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={this.saveWorklists}
            disabled={saveInProgress}
          >
            {locale.routeOptimalizationWizard.createWorklistsModal._saveButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CreateWorklistsModal;
